import { Component, Input, OnInit } from '@angular/core';
import { DadosReservaV2Model } from 'src/app/reservas/models/dados-reserva-v2.model';

@Component({
  selector: 'app-reserva-confirmada',
  templateUrl: './reserva-confirmada.component.html',
  styleUrls: ['./reserva-confirmada.component.scss']
})
export class ReservaConfirmadaComponent implements OnInit{

  @Input() dadosReserva!: DadosReservaV2Model;
  @Input() idReserva!: number;

  ngOnInit(): void {
  }

}
