<section class="margin-top-area">
  <div class="container">
    <div class="row my-2">
      <div class="col">
        <!-- Tab de informações do hotel -->
        <div>
          <tabset>
            <tab>
              <ng-template tabHeading>
                <button type="button" class="btn btn-light  btn-sm">Atrativos</button>
              </ng-template>
              <ng-template [ngTemplateOutlet]="TabAtrativos"></ng-template>
            </tab>
            <tab>
              <ng-template tabHeading>
                <button type="button" class="btn btn-light  btn-sm">Destaques</button>
              </ng-template>
              <ng-template [ngTemplateOutlet]="TabDestaques"></ng-template>
            </tab>
            <tab class="pt-3">
              <ng-template tabHeading>
                <button type="button" class="btn btn-light  btn-sm">
                  Galeria
                </button>
              </ng-template>
              <ng-template [ngTemplateOutlet]="TabGaleria"></ng-template>
            </tab>
            <tab>
              <ng-template tabHeading>
                <button type="button" class="btn btn-light  btn-sm">Fale conosco</button>
              </ng-template>
              <ng-template [ngTemplateOutlet]="TabFaleCosnoco"></ng-template>
            </tab>
          </tabset>
        </div>

        <ng-template #TabAtrativos>
          <app-atrativos></app-atrativos>
        </ng-template>

        <ng-template #TabDestaques>
          <app-destaques></app-destaques>
        </ng-template>

        <ng-template #TabGaleria>
          <app-gallery-quartos [isTabGalery]="true"></app-gallery-quartos>
          <app-gallery-hotel></app-gallery-hotel>
        </ng-template>

        <ng-template #TabFaleCosnoco>
          <app-contato-page [isFaleConoscoTabElement]="true"></app-contato-page>
        </ng-template>
      </div>
    </div>
  </div>
</section>