import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-instalacoes-lazer',
  templateUrl: './instalacoes-lazer.component.html',
  styleUrls: ['./instalacoes-lazer.component.scss'],
})
export class InstalacoesLazerComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  pageTitleArea: pageTitle[] = [
    {
      title: 'Instalações',
      subTitle: 'Lazer *Estruturas exclusivas para hóspedes e sócios remidos',
    },
  ];

  caseStudiesDetailsDesc: DetailsDesc[] = [
    {
      title: 'Chafariz',
      paragraph: 'Cartão postal do CTC, funciona das 6h às 19h',
      img: 'assets/img/metropolitan/lazer-piscina-chafaris.png',
    },
    // {
    //   title: 'Infantil',
    //   paragraph: '',
    //   img: ''
    // },
    {
      title: 'Paredão',
      paragraph:
        'Sequência de quedas d’agua para massagem relaxante e terapêutica.',
      img: 'assets/img/instalacoes/paredao.png',
    },
    {
      title: 'Piscina da Lanchonete',
      paragraph:
        'Oferece cascata e águas sempre quentes, bem pertinho da lanchonete.',
      img: 'assets/img/metropolitan/lazer-piscina-da-lanchonete.png',
    },
    {
      title: 'Ofurôs',
      paragraph:
        '2 ofurôs quentes e borbulhantes para seu momento de relaxamento.',
      img: 'assets/img/instalacoes/ofuro.png',
    },
    // {
    //   title: 'Sauna',
    //   paragraph: '',
    //   img: ''
    // }
  ];

  caseStudiesDetailsDescImportantFacts: ImportantFacts[] = [
    {
      title: 'Piscinas',
    },
    {
      title: 'Saúna',
    },
    {
      title: 'Auditório',
    },
    {
      title: 'Ofurôs',
    },
    {
      title: 'Toboáguas',
    },
    {
      title: 'Home Office',
    },
  ];
  caseStudiesDetailsInfo: Info[] = [
    {
      icon: 'bx bx-user-pin',
      title: 'Reservas',
      subTitle: 'form reservas online',
    },
  ];
}

class pageTitle {
  title: string;
  subTitle: string;
}
class DetailsDesc {
  title: string;
  paragraph: string;
  img: string;
}
class ImportantFacts {
  title: string;
}
class Info {
  icon: string;
  title: string;
  subTitle: string;
}
