<section *ngIf="!isFaleConoscoTabElement" class="page-title-area">
  <div class="container">
    <div class="page-title-content" *ngFor="let pageTitle of pageTitleArea;">
      <h2>{{pageTitle.title}}</h2>
      <ul>
        <li><a routerLink="/">Home</a></li>
        <li>{{pageTitle.title}}</li>
      </ul>
    </div>
  </div>
  <div class="shape-img1"><img src="assets/img/shape/shape1.svg" alt="image"></div>
  <div class="shape-img2"><img src="assets/img/shape/shape2.png" alt="image"></div>
  <div class="shape-img3"><img src="assets/img/shape/shape3.png" alt="image"></div>
</section>

<section class="contact-info-area pt-70 pb-70">
  <div class="container">
    <div class="row">
      <div class="col-lg-4 col-md-6">
        <div class="contact-info-box" *ngFor="let InfoBox1 of contactInfoBox1;">
          <div class="back-icon">
            <i class='{{InfoBox1.icon}}'></i>
          </div>
          <div class="icon">
            <i class='{{InfoBox1.icon}}'></i>
          </div>
          <h3>{{InfoBox1.title}}</h3>
          <p>{{InfoBox1.location}}</p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6">
        <div class="contact-info-box" *ngFor="let InfoBox2 of contactInfoBox2;">
          <div class="back-icon">
            <i class='{{InfoBox2.icon}}'></i>
          </div>
          <div class="icon">
            <i class='{{InfoBox2.icon}}'></i>
          </div>
          <h3>{{InfoBox2.title}}</h3>
          <p>Telefone: <a href="tel:{{InfoBox2.number}}"><strong>{{InfoBox2.number}}</strong></a></p>
          <p>E-mail: <a href="mailto:{{InfoBox2.email}}"><strong>{{InfoBox2.email}}</strong></a></p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6">
        <div class="contact-info-box" *ngFor="let InfoBox3 of contactInfoBox3;">
          <div class="back-icon">
            <i class='{{InfoBox3.icon}}'></i>
          </div>
          <div class="icon">
            <i class='{{InfoBox3.icon}}'></i>
          </div>
          <h3>{{InfoBox3.title}}</h3>
          <p>Telefone: <a href="tel:{{InfoBox3.number}}"><strong>{{InfoBox3.number}}</strong></a></p>
          <p>E-mail: <a href="mailto:{{InfoBox3.email}}"><strong>{{InfoBox3.email}}</strong></a></p>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="contact-area pb-100">
  <div class="container">
    <div class="section-title" *ngFor="let sectionTitleContent of sectionTitle;">
      <span class="sub-title"><img src="assets/img/star-icon.png" alt="image"> {{sectionTitleContent.subTitle}}</span>
      <h2>{{sectionTitleContent.title}}</h2>
      <p>{{sectionTitleContent.paragraphText}}</p>
    </div>
    <div class="row">
      <div class="col-lg-6 col-md-12">
        <div class="contact-image" *ngFor="let Image of contactImage;">
          <img [src]="Image.img" alt="image">
        </div>
      </div>
      <div class="col-lg-6 col-md-12">
        <div class="contact-form">
          <form id="contactForm">
            <div class="row">
              <div class="col-lg-12 col-md-6">
                <div class="form-group mb-3">
                  <input type="text" name="name" class="form-control" id="name" placeholder="Seu nome">
                </div>
              </div>
              <div class="col-lg-12 col-md-6">
                <div class="form-group mb-3">
                  <input type="email" name="email" class="form-control" id="email" placeholder="Seu e-mail">
                </div>
              </div>
              <div class="col-lg-12 col-md-12">
                <div class="form-group mb-3">
                  <input type="text" name="phone_number" class="form-control" id="phone_number"
                    placeholder="Seu número de telefone com DDD">
                </div>
              </div>
              <div class="col-lg-12 col-md-12">
                <div class="form-group mb-3">
                  <textarea name="message" id="message" class="form-control" cols="30" rows="6"
                    placeholder="Sua dúvida ou sugestão..."></textarea>
                </div>
              </div>
              <div class="col-lg-12 col-md-12">
                <button type="submit" class="default-btn"><i class="flaticon-tick"></i>Enviar a
                  mensagem<span></span></button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>

<div id="map">
  <iframe
    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5374.109715211963!2d-48.62642898367868!3d-17.74136780886732!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94a736b24a447d11%3A0xe8f5b23b18583303!2sHotel%20CTC!5e0!3m2!1spt-BR!2sbr!4v1640886354510!5m2!1spt-BR!2sbr"></iframe>
</div>
