import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-metropolitan-atracoes',
  templateUrl: './metropolitan-atracoes.component.html',
})
export class MetropolitanAtracoesComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  pageTitleArea: pageTitle[] = [
    {
      title: 'Metropolitan Park',
      subTitle: 'Atrações',
    },
  ];

  caseStudiesDetailsImage: Image[] = [
    {
      img: 'assets/img/metropolitan/paris.png',
      // img: 'assets/img/projects/projects-details1.jpg'
    },
  ];
  caseStudiesDetailsDesc: DetailsDesc[] = [
    {
      title: 'Piscinas "Petit Paris"',
      paragraph:
        'Primeira etapa do projeto de expansão do Metropolitan Park. Oferece para a garotada um espaço com diversas atividades e muita diversão, além de lanchonete e sorveteria nestlé.',
      img: 'assets/img/metropolitan/peti-paris.jpg',
    },
    {
      title: 'Gourmet',
      paragraph: '',
      img: 'assets/img/metropolitan/atracoes-gourmet.png',
    },
    {
      title: 'Piscina das cascatas',
      paragraph: '',
      img: 'assets/img/metropolitan/atracoes-piscina-de-cascata.png',
    },
    {
      title: 'Espelho d´água',
      paragraph: '',
      img: 'assets/img/metropolitan/espelho-dagua.png',
    },
    {
      title: 'piscina-infantil Caldinha',
      paragraph: '',
      img: 'assets/img/metropolitan/piscina-infantil-caldinha.png',
    },
    // {
    //   title: 'Piscina de pedra',
    //   paragraph: '',
    //   img: ''
    // },
    {
      title: 'Piscina das rampas',
      paragraph: '',
      img: 'assets/img/metropolitan/atracoes-piscinas-das-rampas.png',
    },
    // {
    //   title: 'Saúna',
    //   paragraph: '',
    //   img: ''
    // },
    {
      title: 'Academia ao ar livre',
      paragraph: '',
      img: 'assets/img/metropolitan/academia-ao-ar-livre.png',
    },
  ];
  caseStudiesDetailsDescImportantFacts: ImportantFacts[] = [
    {
      title: 'Piscinas',
    },
    {
      title: 'Saúna',
    },
    {
      title: 'Auditório',
    },
    {
      title: 'Ofurôs',
    },
    {
      title: 'Toboáguas',
    },
    {
      title: 'Home Office',
    },
  ];
  caseStudiesDetailsInfo: Info[] = [
    {
      icon: 'bx bx-user-pin',
      title: 'Reservas',
      subTitle: 'form reservas online',
    },
  ];
}
class pageTitle {
  title: string;
  subTitle: string;
}
class Image {
  img: string;
}
class DetailsDesc {
  title: string;
  paragraph: string;
  img: string;
}
class ImportantFacts {
  title: string;
}
class Info {
  icon: string;
  title: string;
  subTitle: string;
}
