<section class="project-start-area {{sectionClass}} ptb-100">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-12 project-start-content">
        <div id="map" class="d-none d-sm-block">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d30400.508664312598!2d-48.626239!3d-17.741643!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xe8f5b23b18583303!2sHotel%20CTC!5e0!3m2!1spt-BR!2sbr!4v1642694724324!5m2!1spt-BR!2sbr"
            width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy">
          </iframe>
        </div>
        <div id="map" class="d-block d-sm-none">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d30400.508664312598!2d-48.626239!3d-17.741643!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xe8f5b23b18583303!2sHotel%20CTC!5e0!3m2!1spt-BR!2sbr!4v1642694724324!5m2!1spt-BR!2sbr"
            width="300" height="255" style="border:0;" allowfullscreen="" loading="lazy">
          </iframe>
        </div>
      </div>

      <div class="col-lg-6 col-md-12">
        <div class="project-start-content" *ngFor="let Content of projectStartContent;">
          <h2>{{Content.title}}</h2>
          <p>{{Content.paragraphText}}</p>
          <a routerLink="/{{Content.defaultBtnLink}}" class="default-btn"><i
              class="{{Content.defaultBtnIcon}}"></i>{{Content.defaultBtnText}}<span></span></a>
        </div>
      </div>
    </div>
  </div>

  <div class="{{circleShape1Class}}"><img src="assets/img/shape/circle-shape1.png" alt="image"></div>
  <div class="{{vectorShape3Class}}"><img src="assets/img/shape/vector-shape3.png" alt="image"></div>
  <div class="{{vectorShape9Class}}"><img src="assets/img/shape/vector-shape9.png" alt="image"></div>
  <div class="{{vectorShape10Class}}"><img src="assets/img/shape/vector-shape10.png" alt="image"></div>
</section>
