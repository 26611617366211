import { DadosReservaV2Model } from "src/app/reservas/models/dados-reserva-v2.model";
import { AsaasUnicoExtRefV2Model } from "./asaas-unico-ext-ref-v2.model";
import { CreditCardAsaasV2Model } from "./credit-card-asaas-v2-model";
import { ExternalReferenceV2Model } from "./external-referece-v2.model";
import { HospedeV2Model } from "./hospede-v2.model";
import { NewCustomersAsaasV2Model } from "./new-customers-asaas-v2.model";
import { ParcelamentoAsaasV2Model } from "./parcelamento-asaas-v2.model";
import { ReservaV2Model } from "./reserva-v2.model";

export class NewPaymentOnlineTemaAsaasV2Model {
  constructor(
    public hospede: HospedeV2Model,
    public reserva: ReservaV2Model,
    public asaasUnico: AsaasUnicoExtRefV2Model,
    public externalReference: ExternalReferenceV2Model,
    public newCustomer: NewCustomersAsaasV2Model,
    public parcelamento: ParcelamentoAsaasV2Model,
    public dadosCartao: CreditCardAsaasV2Model,
    public dadosReserva: DadosReservaV2Model,
    public ipAddress: string
  ) { }
}

export class NewReservaOnlineTemaV2Model {
  constructor(
    public hospede: HospedeV2Model,
    public reserva: ReservaV2Model,
    public newCustomer: NewCustomersAsaasV2Model,
  ) { }
}
