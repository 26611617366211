import { Component } from '@angular/core';
import {
  faCar,
  faCoffee,
  faGlassMartini,
  faSnowflake,
  faSpa,
  faWater,
  faWifi,
} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-destaques',
  templateUrl: './destaques.component.html',
  styleUrls: ['./destaques.component.scss'],
})
export class DestaquesComponent {
  /**
   * icons
   */
  faCoffee = faCoffee;
  faWifi = faWifi;
  faSnowflake = faSnowflake;
  faWater = faWater;
  faGlassMartini = faGlassMartini;
  faCar = faCar;
  faSpa = faSpa;
}
