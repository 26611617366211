<h5>Dados da Reserva: <strong class="fs-4">{{ temaReserva.idreserva }}</strong></h5>
<div class="card mt-2">
  <div class="card-body">
    <div class="row mb-3">
      <div class="col-lg-12">
        <div class="fw-bold text-primary fs-5 mb-0 pb-0 border-bottom border-warning">{{
          temaReserva.pessoa }}</div>
        <div class="form-text mt-0 pt-0">Responsável pela reserva.</div>
      </div>
      <div class="col-lg-12" *ngIf="temaReserva.email != ''">
        <div class="fw-bold fs-5 mb-0 pb-0 border-bottom border-warning">{{ temaReserva.email }}</div>
        <div class="form-text mt-0 pt-0">e-mail de contato.</div>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-lg-6">
        <div class="fw-bold text-danger fs-5 mb-0 pb-0 border-bottom border-warning">{{
          temaReserva.entrada | date:'dd/MM/yyyy' }}
        </div>
        <div class="form-text mt-0 pt-0">Entrada.</div>
      </div>
      <div class="col-lg-6">
        <div class="fw-bold text-danger fs-5 mb-0 pb-0 border-bottom border-warning">{{ temaReserva.saida
          | date:'dd/MM/yyyy' }}</div>
        <div class="form-text mt-0 pt-0">Saída.</div>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-lg-3">
        <div class="fw-bold fs-5 mb-0 pb-0 border-bottom border-warning">{{ diarias(temaReserva) }}</div>
        <div class="form-text mt-0 pt-0">Diária(s).</div>
      </div>
      <div class="col-lg-3">
        <div class="fw-bold fs-5 mb-0 pb-0 border-bottom border-warning">{{ temaReserva.aptos }}</div>
        <div class="form-text mt-0 pt-0">Quartos.</div>
      </div>
      <div class="col-lg-6">
        <div class="fw-bold fs-5 mb-0 pb-0 border-bottom border-warning">{{ regime(temaReserva.regime) }}
        </div>
        <div class="form-text mt-0 pt-0">Regime.</div>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-lg-6">
        <div class="fw-bold text-success fs-5 mb-0 pb-0 border-bottom border-warning">{{
          formatValue(temaReserva.diaria) }}
        </div>
        <div class="form-text mt-0 pt-0">Valor Diaria.</div>
      </div>
      <div class="col-lg-6">
        <div class="fw-bold text-success fs-5 mb-0 pb-0 border-bottom border-warning">{{ formatValue(temaReserva.sinal) }}</div>
        <div class="form-text mt-0 pt-0">Valor total.</div>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-lg-3">
        <div class="fw-bold fs-5 mb-0 pb-0 border-bottom border-warning">{{ temaReserva.paxpg }}</div>
        <div class="form-text mt-0 pt-0">Adultos</div>
      </div>
      <div class="col-lg-3">
        <div class="fw-bold fs-5 mb-0 pb-0 border-bottom border-warning">{{ temaReserva.paxcn }}</div>
        <div class="form-text mt-0 pt-0">Jovem(ns)</div>
      </div>
      <div class="col-lg-3">
        <div class="fw-bold fs-5 mb-0 pb-0 border-bottom border-warning">{{ temaReserva.paxcp }}</div>
        <div class="form-text mt-0 pt-0">Criança(s) entre 9 e 12 anos.</div>
      </div>
      <div class="col-lg-3">
        <div class="fw-bold fs-5 mb-0 pb-0 border-bottom border-warning">{{ temaReserva.paxnp }}</div>
        <div class="form-text mt-0 pt-0">Criança(s) menos de 8 anos</div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="alert alert-info m-0" role="alert">
          <p class="m-0">Os <strong>"Dados do responsável pelo pagamento"</strong> devem estar
          <strong>idênticos</strong> ao do <strong>proprietário do cartão</strong>!
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

