<div class="row">

  <div class="col-lg-12">
    <h5 class="m-0">Dados do responsável pelo pagamento</h5>
  </div>

  <!-- Coletando para criação do customers responsável pelo pagamento -->
  <div class="col-lg-12 mb-3">
    <form novalidate="" role="formPagador" [formGroup]="formPagador" name="dadosPagador"
      (ngSubmit)="submitNotIdemPagador()">
      <div class="card mt-2">
        <div class="card-body">

          <div class="row mb-3">
            <div class="col-lg-6">
              <div class="mb-3">
                <input type="text" formControlName="nome" class="form-control" id="nome" aria-describedby="nome"
                  required [ngClass]="{ 'is-invalid': fpd['nome'].touched && fpd['nome'].errors,
                'is-valid': fpd['nome'].touched && !fpd['nome'].errors }">
                <div id="nome" class="form-text"><span class="text-danger">*</span>Digite o nome do responsável pelo
                  cartão.</div>
              </div>
            </div>

            <div class="col-lg-3">
              <input type="text" formControlName="cpf" class="form-control" id="cpf" mask="000.000.000-00"
                aria-describedby="cpf" required [ngClass]="{ 'is-invalid': fpd['cpf'].touched && fpd['cpf'].errors,
              'is-valid': fpd['cpf'].touched && !fpd['cpf'].errors }">
              <div id="cpf" class="form-text"><span class="text-danger">*</span>Digite o CPF.</div>
            </div>

            <div class="col-lg-3">
              <div class="mb-3">
                <input type="text" formControlName="fonemovel" class="form-control" id="fonemovel"
                  aria-describedby="fonemovel" mask="(00) 000-000-000" required [ngClass]="{ 'is-invalid': fpd['fonemovel'].touched && fpd['fonemovel'].errors,
                'is-valid': fpd['fonemovel'].touched && !fpd['fonemovel'].errors }">
                <div id="fonemovel" class="form-text"><span class="text-danger">*</span>Celular: (00) 000-000-000</div>
              </div>
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-lg-6">
              <input type="email" formControlName="email" class="form-control" id="email" aria-describedby="email"
                required [ngClass]="{ 'is-invalid': fpd['email'].touched && fpd['email'].errors,
            'is-valid': fpd['email'].touched && !fpd['email'].errors }">
              <div id="email" class="form-text"><span class="text-danger">*</span>E-mail.</div>
            </div>

            <div class="col-lg-3">
              <div class="mb-3">
                <input type="text" formControlName="fonefixo" class="form-control" id="fonefixo"
                  aria-describedby="fonefixo" mask="(00) 0000-0000">
                <div id="fonefixo" class="form-text">Fixo: (00) 0000-0000</div>
              </div>
            </div>

            <div class="col-lg-3">
              <input type="text" formControlName="cep" class="form-control" id="cep" aria-describedby="cep" required
                mask="00000000"
                [ngClass]="{ 'is-invalid': fpd['cep'].touched && fpd['cep'].errors, 'is-valid': fpd['cep'].touched && !fpd['cep'].errors }">
              <div id="cep" class="form-text"><span class="text-danger">*</span>CEP.
                <small>
                  <a class="link-success link-underline-darklink-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover"
                    target="_blank" href="https://buscacepinter.correios.com.br/app/endereco/index.php">(não
                    sabe o cep?)</a>
                </small>
              </div>
            </div>
          </div>

          <div class="row mb-3" *ngIf="isCep">
            <div class="col-lg-12">
              <div class="alert m-0 alert-danger" role="alert">
                <label class="form-check-label" for="flexCheckDefault">
                  <span class="text-danger">*</span> Não poderá prosseguir sem informar o CEP correto. Se não souber,
                  clique aqui em <a
                    class="link-success link-underline-darklink-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover"
                    target="_blank" href="https://buscacepinter.correios.com.br/app/endereco/index.php">(não
                    sabe o cep?)</a>
                </label>
              </div>
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-lg-5">
              <input type="text" formControlName="logradouro" class="form-control" id="logradouro"
                aria-describedby="logradouro" required [ngClass]="{ 'is-invalid': fpd['logradouro'].touched && fpd['logradouro'].errors,
              'is-valid': fpd['logradouro'].touched && !fpd['logradouro'].errors }">
              <div id="logradouro" class="form-text">Digite sua rua, alameda, avenida, etc.</div>
            </div>

            <div class="col-lg-2">
              <input type="text" formControlName="numero" class="form-control" id="numero" aria-describedby="numero">
              <div id="numero" class="form-text">nº.</div>
            </div>

            <div class="col-lg-5">
              <input type="text" formControlName="complemento" class="form-control" id="complemento"
                aria-describedby="complemento">
              <div id="complemento" class="form-text">Complemento.</div>
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-lg-4">
              <input type="text" formControlName="bairro" class="form-control" id="bairro" aria-describedby="bairro"
                required [ngClass]="{ 'is-invalid': fpd['bairro'].touched && fpd['bairro'].errors,
            'is-valid': fpd['bairro'].touched && !fpd['bairro'].errors }">
              <div id="bairro" class="form-text"><span class="text-danger">*</span>Bairro.</div>
            </div>

            <div class="col-lg-3">
              <input type="text" formControlName="cidade" class="form-control" id="cidade" aria-describedby="cidade"
                required [ngClass]="{ 'is-invalid': fpd['cidade'].touched && fpd['cidade'].errors,
            'is-valid': fpd['cidade'].touched && !fpd['cidade'].errors }">
              <div id="cidade" class="form-text"><span class="text-danger">*</span>Cidade.</div>
            </div>

            <div class="col-lg-2">
              <input type="text" formControlName="estado" class="form-control" id="estado" aria-describedby="estado"
                required [ngClass]="{ 'is-invalid': fpd['estado'].touched && fpd['estado'].errors,
            'is-valid': fpd['estado'].touched && !fpd['estado'].errors }">
              <div id="estado" class="form-text"><span class="text-danger">*</span>Estado.</div>
            </div>

            <div class="col-lg-3">
              <div class="float-end">
                <div class="btn-group" role="group" aria-label="Basic example">
                  <button type="submit" class="btn" [ngClass]="isCustomer ? ' btn-warning' : ' btn-primary'"
                    [disabled]="formPagador.pristine || formPagador.invalid">Salvar</button>
                  <button *ngIf="isCustomer" type="submit" class="btn btn-primary"
                    [disabled]="!formPagador.pristine && !formPagador.invalid">Avançar</button>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </form>
  </div>

  <div class="col-lg-12">
    <div class="card mt-2">
      <div class="card-body">
        <div class="alert m-0" [ngClass]="alertDeclaro ? 'alert-light' : 'alert-danger'" role="alert"
          (click)="isDeclaro()">
          <label class="form-check-label" for="flexCheckDefault">
            <input (click)="isDeclaro()" [(ngModel)]="declaro" class="form-check-input" type="checkbox" id="regras"
              required> <span class="text-danger">*</span> Declaro estar
            ciente de que a
            reserva realizada por este canal pode ser cancelada sem custo até 7 (sete) dias antes da data de
            entrada. Após esse período, somente haverá reembolso via cartão de crédito.
          </label>
        </div>
      </div>
    </div>
  </div>

</div>
