<app-home-banner></app-home-banner>
<app-seguimentos></app-seguimentos>
<app-home-muita-diversao></app-home-muita-diversao>
<app-home-mais-diversao></app-home-mais-diversao>
<app-project-start></app-project-start>

<!-- 
    
-->

<!-- inicio::Não mexa não apague -->
<!-- <app-hotel></app-hotel> -->

<!-- <app-promocoes></app-promocoes> -->
<!-- fim::Não mexa não apague -->

<!--
<app-nav-reservas></app-nav-reservas> -->
