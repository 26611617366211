<div class="card" style="width: 100%; border:0;">
  <div class="card-body">
    <h5 class="card-title">Atrativos aos hópedes do Clube/Hotel CTC - Caldas Novas</h5>
    <div class="row mb-3">
      <div class="col-12 d-flex align-items-center">
        <div class="servicos">
          <fa-icon [icon]="faFilm" class="px-1"></fa-icon>Cinema
        </div>
        <div class="servicos">
          <fa-icon [icon]="faGamepad" class="px-1"></fa-icon>Parque de Diversão
        </div>
        <div class="servicos">
          <fa-icon [icon]="faShoppingBag" class="px-1"></fa-icon>Shoppings
        </div>
        <div class="servicos">
          <fa-icon [icon]="faBowlingBall" class="px-1"></fa-icon>Boliche
        </div>
        <div class="servicos">
          <fa-icon [icon]="faGlassMartini" class="px-1"></fa-icon>Bares
        </div>
        <div class="servicos">
          <fa-icon [icon]="faCar" class="px-1"></fa-icon>Pontos de Táxi
        </div>
        <div class="servicos">
          <fa-icon [icon]="faUtensils" class="px-1"></fa-icon>Restaurantes
        </div>
      </div>
    </div>
  </div>
  <ul class="list-group">
    <li class="list-group-item">
      <a href="javascript:void(0)" class="d-flex justify-content-between align-items-center">
        <span class="courses-name">Avenida Orcalino Santos [gastronomia/compras/parque]</span>
        <div class="courses-meta">
          <span class="duration">5 metros</span>
          <fa-icon [icon]="faWalking" class="px-2"></fa-icon>
        </div>
      </a>

    </li>
    <li class="list-group-item">
      <a href="javascript:void(0)" class="d-flex justify-content-between align-items-center">
        <span class="courses-name">Trenzinho para passeios [diversão/entretenimento]</span>
        <div class="courses-meta">
          <span class="duration">5 metros</span>
          <fa-icon [icon]="faWalking" class="px-2"></fa-icon>
        </div>
      </a>
    </li>
    <li class="list-group-item">
      <a href="javascript:void(0)" class="d-flex justify-content-between align-items-center">
        <span class="courses-name">Praça Mestre Orlando [gastronomia/bar/arte]</span>
        <div class="courses-meta">
          <span class="duration">115 metros</span>
          <fa-icon [icon]="faWalking" class="px-2"></fa-icon>
        </div>
      </a>
    </li>
    <li class="list-group-item">
      <a href="javascript:void(0)" class="d-flex justify-content-between align-items-center">
        <span class="courses-name">Igreja Matriz Paróquia Nossa Senhora das Dores [missas/capelas/centros comunitários]</span>
        <div class="courses-meta">
          <span class="duration">115 metros</span>
          <fa-icon [icon]="faWalking" class="px-2"></fa-icon>
        </div>
      </a>
    </li>
    <li class="list-group-item">
      <a href="javascript:void(0)" class="d-flex justify-content-between align-items-center">
        <span class="courses-name">Parque de diversões [diversão/entretenimento]</span>
        <div class="courses-meta">
          <span class="duration">178 metros</span>
          <fa-icon [icon]="faWalking" class="px-2"></fa-icon>
        </div>
      </a>
    </li>
    <li class="list-group-item">
      <a href="javascript:void(0)" class="d-flex justify-content-between align-items-center">
        <span class="courses-name">Feira do luar [gastronomia/compras]</span>
        <div class="courses-meta">
          <span class="duration">250 metros</span>
          <fa-icon [icon]="faWalking" class="px-2"></fa-icon>
        </div>
      </a>
    </li>
    <li class="list-group-item">
      <a href="javascript:void(0)" class="d-flex justify-content-between align-items-center">
        <span class="courses-name">Balneário municipal [banhos thermais/saúde/tratamento]</span>
        <div class="courses-meta">
          <span class="duration">310 metros</span>
          <fa-icon [icon]="faWalking" class="px-2"></fa-icon>
        </div>
      </a>
    </li>
    <li class="list-group-item">
      <a href="javascript:void(0)" class="d-flex justify-content-between align-items-center">
        <span class="courses-name">Casarão dos Gonzaga [museu/história]</span>
        <div class="courses-meta">
          <span class="duration">800 metros</span>
          <fa-icon [icon]="faWalking" class="px-2"></fa-icon>
        </div>
      </a>
    </li>
    <li class="list-group-item">
      <a href="javascript:void(0)" class="d-flex justify-content-between align-items-center">
        <span class="courses-name">Feira pública [gastronomia/produtos regionais]</span>
        <div class="courses-meta">
          <span class="duration">1,3 km</span>
          <fa-icon [icon]="faCar" class="px-2"></fa-icon>
        </div>
      </a>
    </li>
    <li class="list-group-item">
      <a href="javascript:void(0)" class="d-flex justify-content-between align-items-center">
        <span class="courses-name">Jardim Japonês [natureza/passeio/entreterimento]</span>
        <div class="courses-meta">
          <span class="duration">2,7 km</span>
          <fa-icon [icon]="faCar" class="px-2"></fa-icon>
        </div>
      </a>
    </li>
    <li class="list-group-item">
      <a href="javascript:void(0)" class="d-flex justify-content-between align-items-center">
        <span class="courses-name">Santuário Salete [missas/devoção/mirante para a cidade]</span>
        <div class="courses-meta">
          <span class="duration">5,5 km</span>
          <fa-icon [icon]="faCar" class="px-2"></fa-icon>
        </div>
      </a>
    </li>
    <li class="list-group-item">
      <a href="javascript:void(0)" class="d-flex justify-content-between align-items-center">
        <span class="courses-name">Castelo de gelo (Setland Parque Temático)
          [entretenimento/gastronomia/diversão]</span>
        <div class="courses-meta">
          <span class="duration">5,6 km</span>
          <fa-icon [icon]="faCar" class="px-2"></fa-icon>
        </div>
      </a>
    </li>
    <li class="list-group-item">
      <a href="javascript:void(0)" class="d-flex justify-content-between align-items-center">
        <span class="courses-name">Parque Estadual Serra de Caldas
          [ecoturismo/natureza/cachoeira/trilhas]</span>
        <div class="courses-meta">
          <span class="duration">5,9 km</span>
          <fa-icon [icon]="faCar" class="px-2"></fa-icon>
        </div>
      </a>
    </li>
    <li class="list-group-item">
      <a href="javascript:void(0)" class="d-flex justify-content-between align-items-center">
        <span class="courses-name">Lago Corumbá [ecoturismo/natureza/pescaria/passeios no lago]</span>
        <div class="courses-meta">
          <span class="duration">7,9 km</span>
          <fa-icon [icon]="faCar" class="px-2"></fa-icon>
        </div>
      </a>
    </li>
  </ul>
</div>
