<section class="margin-top-area">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="features-list p-2">
          <form novalidate="" role="form" [formGroup]="form" name="reservaForm" (ngSubmit)="onSubmit()">

            <!-- <alert type="success  pt-0 pb-2 px-2">1 -->
            <div class="row">

              <!-- input Data de entrada -->
              <div class="col-lg-2 col-xl-2 col-md-4 col-sm-12">
                <div>
                  <label class="form-label mb-1">
                    <small><span class="text-danger">*</span> Checkin</small>
                  </label>
                  <div class="clockpicker">
                    <input type="text" formControlName="dtentrada" [placeholder]="iniDate" class="form-control"
                    [disabled]="disabled">
                  </div>
                </div>
              </div>

              <!-- input Data de saída -->
              <div class="col-lg-2 col-xl-2 col-md-4 col-sm-12">
                <div>
                  <label class="form-label mb-1">
                    <small><span class="text-danger">*</span> Checkout</small>
                  </label>
                  <div class="clockpicker">
                    <input type="text" formControlName="dtsaida" [placeholder]="minDate" class="form-control"
                    [disabled]="disabled">
                  </div>
                </div>
              </div>

              <!-- input quantidade de adultos -->
              <div class="col-lg-2 col-xl-2 col-md-4 col-sm-12">
                <div>
                  <label class="form-label mb-1">
                    <small><span class="text-danger">*</span> Acima de 12 anos</small>
                  </label>
                  <div class="d-grid gap-2">
                    <button id="button-adultos" type="button" [disabled]="disabled"
                      class="form-control btn btn-light">
                      {{ quantAdultoT }}
                    </button>
                  </div>
                </div>
              </div>

              <!-- input quantidade de jovens -->
              <div class="col-lg-2 col-xl-2 col-md-4 col-sm-12">
                <div>
                  <label class="form-label mb-1">
                    <small>Entre 08 e 12 anos</small>
                  </label>
                  <div class="d-grid gap-2">
                    <button id="button-jovens" type="button" [disabled]="disabled"
                      class="form-control btn btn-light">
                      {{ quantJovensT }}
                    </button>
                  </div>
                </div>
              </div>

              <!-- input quantidade de criancas -->
              <div class="col-lg-2 col-xl-2 col-md-4 col-sm-12">
                <div>
                  <label class="form-label mb-1">
                    <small>Abaixo de 08 anos</small>
                  </label>
                  <div class="d-grid gap-2">
                    <button id="button-criancas" type="button" [disabled]="disabled"
                      class="form-control btn btn-light">
                      {{ quantCriancaT }}
                    </button>
                  </div>
                </div>
              </div>

              <!-- button submit -->
              <div class="col-lg-2 col-xl-2 col-md-4 col-sm-12">
                <div>
                  <label class="form-label mb-1">
                    <small>Dados Informados</small>
                  </label>
                  <div class="d-grid gap-2">
                    <button type="submit" class="btn btn-light default-btn"
                      [disabled]="disabled">Pesquisar...</button>
                  </div>
                </div>
              </div>
            </div>
            <!-- </alert> -->

          </form>
        </div>
      </div>
    </div>
  </div>
</section>
