import { EventEmitter, Injectable } from "@angular/core";
import * as moment from 'moment';
import { ResultV1Model } from "src/app/share/models/result-v1.model";
import { ConsultaReservaModel } from "../models/consulta-reserva.model";
import { TarifasV1Model } from "../models/tarifas-v1.model";
import { DadosOrganizaQuartosService } from "./dados-organiza-quartos.service";

@Injectable({
  providedIn: 'root'
})
export class CalculaReservaV1Service {

  /** Variavel de ambiente */
  public reload: boolean = false;

  public tfLocalizadas_CM: TarifasV1Model[] = [];
  public tfLocalizadas_MP: TarifasV1Model[] = [];
  public tfLocalizadas_PC: TarifasV1Model[] = [];

  /**
   * variaveis somente cafe da manha
   */
  diariaquarto01_CM: number = 0;
  totaldiariaquarto01_CM: number = 0;
  diariaquarto02_CM: number = 0;
  totaldiariaquarto02_CM: number = 0;
  diariaquarto03_CM: number = 0;
  totaldiariaquarto03_CM: number = 0;

  tarifasLocalizadas_CM: TarifasV1Model[] = [];
  valorVALP1_CM: number = 0;
  valorVALP2_CM: number = 0;
  valorVALP3_CM: number = 0;
  valorVALP4_CM: number = 0;
  valorVALP5_CM: number = 0;
  valorVALP6_CM: number = 0;
  valorADC01_CM: number = 0;
  valorADC02_CM: number = 0;
  valorADC03_CM: number = 0;
  valortotalVALP1_CM: number = 0;
  valortotalVALP2_CM: number = 0;
  valortotalVALP3_CM: number = 0;
  valortotalVALP4_CM: number = 0;
  valortotalVALP5_CM: number = 0;
  valortotalVALP6_CM: number = 0;
  valortotalADC01_CM: number = 0;
  valortotalADC02_CM: number = 0;
  valortotalADC03_CM: number = 0;
  valorReservaCM: number = 0;

  /**
   * variveis Meia Pensão
   */
  diariaquarto01_MP: number = 0;
  totaldiariaquarto01_MP: number = 0;
  diariaquarto02_MP: number = 0;
  totaldiariaquarto02_MP: number = 0;
  diariaquarto03_MP: number = 0;
  totaldiariaquarto03_MP: number = 0;

  tarifasLocalizadas_MP: TarifasV1Model[] = [];
  valorVALP1_MP: number = 0;
  valorVALP2_MP: number = 0;
  valorVALP3_MP: number = 0;
  valorVALP4_MP: number = 0;
  valorVALP5_MP: number = 0;
  valorVALP6_MP: number = 0;
  valorADC01_MP: number = 0;
  valorADC02_MP: number = 0;
  valorADC03_MP: number = 0;
  valortotalVALP1_MP: number = 0;
  valortotalVALP2_MP: number = 0;
  valortotalVALP3_MP: number = 0;
  valortotalVALP4_MP: number = 0;
  valortotalVALP5_MP: number = 0;
  valortotalVALP6_MP: number = 0;
  valortotalADC01_MP: number = 0;
  valortotalADC02_MP: number = 0;
  valortotalADC03_MP: number = 0;
  valorReservaMP: number = 0;

  /**
   * Variaveis Pensão Completa
   */
  diariaquarto01_PC: number = 0;
  totaldiariaquarto01_PC: number = 0;
  diariaquarto02_PC: number = 0;
  totaldiariaquarto02_PC: number = 0;
  diariaquarto03_PC: number = 0;
  totaldiariaquarto03_PC: number = 0;

  tarifasLocalizadas_PC: TarifasV1Model[] = [];
  valorVALP1_PC: number = 0;
  valorVALP2_PC: number = 0;
  valorVALP3_PC: number = 0;
  valorVALP4_PC: number = 0;
  valorVALP5_PC: number = 0;
  valorVALP6_PC: number = 0;
  valorADC01_PC: number = 0;
  valorADC02_PC: number = 0;
  valorADC03_PC: number = 0;
  valortotalVALP1_PC: number = 0;
  valortotalVALP2_PC: number = 0;
  valortotalVALP3_PC: number = 0;
  valortotalVALP4_PC: number = 0;
  valortotalVALP5_PC: number = 0;
  valortotalVALP6_PC: number = 0;
  valortotalADC01_PC: number = 0;
  valortotalADC02_PC: number = 0;
  valortotalADC03_PC: number = 0;
  valorReservaPC: number = 0;

  adultosquarto01: number = 0;
  jovensquarto01: number = 0;
  criancasquarto01: number = 0;
  adultosquarto02: number = 0;
  jovensquarto02: number = 0;
  criancasquarto02: number = 0;
  adultosquarto03: number = 0;
  jovensquarto03: number = 0;
  criancasquarto03: number = 0;

  valordiaria: number = 0;
  totalreserva: number = 0;
  totaldiaria_CM: number = 0;
  totaldiaria_MP: number = 0;
  totaldiaria_PC: number = 0;
  totalpensaocompleta: number = 0;
  totalmeiapensao: number = 0;
  totalsempensao: number = 0;
  mediaDiariaCM: number = 0;
  mediaDiariaMP: number = 0;
  mediaDiariaPC: number = 0;
  quantParcela_CM: number = 0;
  parcela_CM!: number;
  quantParcela_MP: number = 0;
  parcela_MP!: number;
  quantParcela_PC: number = 0;
  parcela_PC!: number;
  quartos: number = 1;

  adultos: number = 0;
  jovens: number = 0;
  criancas: number = 0;
  dataentrada?: string;
  datasaida?: string;
  quantasdiarias: number = 0;
  newCalculo!: boolean;

  constructor(
    private readonly dadosOrganizaQuartosService: DadosOrganizaQuartosService,
  ) { }

  /** Ação de emitir o eventos */
  public calculaReservadisponivel = new EventEmitter<any>();

  /** Função pública de acionamento do eventos */
  public isCalculaReserva(
    novoperiodo: boolean,
    consultaReserva: ConsultaReservaModel,
    resultado?: ResultV1Model
  ) {
    // novoperiodo ? this.resetVariaveisHospedes() : null;
    this.resetVariaveisHospedes();
    this.newCalculo = novoperiodo;
    this.dataentrada = moment(consultaReserva.dataentrada).format('DD/MM/YYYY');
    this.datasaida = moment(consultaReserva.datasaida).format('DD/MM/YYYY');
    this.adultos = consultaReserva.adultos;
    this.jovens = consultaReserva.jovens;
    this.criancas = consultaReserva.criancas;

    resultado ? this.calculaReservasPosPesquisa(resultado) : this.calculaReserva();
  }

  async calculaReservasPosPesquisa(resultado: ResultV1Model) {

    await this.zeraCalculoPosPesquisa();

    this.quantasdiarias = resultado.data.quantDiarias;

    resultado.data.tarifa_CM.forEach(
      (element: TarifasV1Model) => {
        this.valortotalVALP1_CM = this.valortotalVALP1_CM + element.VALP1;
        this.valortotalVALP2_CM = this.valortotalVALP2_CM + element.VALP2;
        this.valortotalVALP3_CM = this.valortotalVALP3_CM + element.VALP3;
        this.valortotalVALP4_CM = this.valortotalVALP4_CM + element.VALP4;
        this.valortotalVALP5_CM = this.valortotalVALP5_CM + element.VALP5;
        this.valortotalVALP6_CM = this.valortotalVALP6_CM + element.VALP6;
        this.valortotalADC01_CM = this.valortotalADC01_CM + element.ADC01;
        this.valortotalADC02_CM = this.valortotalADC02_CM + element.ADC02;
        this.valortotalADC03_CM = this.valortotalADC03_CM + element.ADC03;
      }
    );

    this.valorVALP1_CM = parseFloat((this.valortotalVALP1_CM / this.quantasdiarias).toFixed(2));
    this.valorVALP2_CM = parseFloat((this.valortotalVALP2_CM / this.quantasdiarias).toFixed(2));
    this.valorVALP3_CM = parseFloat((this.valortotalVALP3_CM / this.quantasdiarias).toFixed(2));
    this.valorVALP4_CM = parseFloat((this.valortotalVALP4_CM / this.quantasdiarias).toFixed(2));
    this.valorVALP5_CM = parseFloat((this.valortotalVALP5_CM / this.quantasdiarias).toFixed(2));
    this.valorVALP6_CM = parseFloat((this.valortotalVALP6_CM / this.quantasdiarias).toFixed(2));
    this.valorADC01_CM = parseFloat((this.valortotalADC01_CM / this.quantasdiarias).toFixed(2));
    this.valorADC02_CM = parseFloat((this.valortotalADC02_CM / this.quantasdiarias).toFixed(2));
    this.valorADC03_CM = parseFloat((this.valortotalADC03_CM / this.quantasdiarias).toFixed(2));

    resultado.data.tarifa_MP.forEach(
      (element: TarifasV1Model) => {
        this.valortotalVALP1_MP = this.valortotalVALP1_MP + element.VALP1;
        this.valortotalVALP2_MP = this.valortotalVALP2_MP + element.VALP2;
        this.valortotalVALP3_MP = this.valortotalVALP3_MP + element.VALP3;
        this.valortotalVALP4_MP = this.valortotalVALP4_MP + element.VALP4;
        this.valortotalVALP5_MP = this.valortotalVALP5_MP + element.VALP5;
        this.valortotalVALP6_MP = this.valortotalVALP6_MP + element.VALP6;
        this.valortotalADC01_MP = this.valortotalADC01_MP + element.ADC01;
        this.valortotalADC02_MP = this.valortotalADC02_MP + element.ADC02;
        this.valortotalADC03_MP = this.valortotalADC03_MP + element.ADC03;
      }
    );
    this.valorVALP1_MP = parseFloat((this.valortotalVALP1_MP / this.quantasdiarias).toFixed(2));
    this.valorVALP2_MP = parseFloat((this.valortotalVALP2_MP / this.quantasdiarias).toFixed(2));
    this.valorVALP3_MP = parseFloat((this.valortotalVALP3_MP / this.quantasdiarias).toFixed(2));
    this.valorVALP4_MP = parseFloat((this.valortotalVALP4_MP / this.quantasdiarias).toFixed(2));
    this.valorVALP5_MP = parseFloat((this.valortotalVALP5_MP / this.quantasdiarias).toFixed(2));
    this.valorVALP6_MP = parseFloat((this.valortotalVALP6_MP / this.quantasdiarias).toFixed(2));
    this.valorADC01_MP = parseFloat((this.valortotalADC01_MP / this.quantasdiarias).toFixed(2));
    this.valorADC02_MP = parseFloat((this.valortotalADC02_MP / this.quantasdiarias).toFixed(2));
    this.valorADC03_MP = parseFloat((this.valortotalADC03_MP / this.quantasdiarias).toFixed(2));

    resultado.data.tarifa_PC.forEach(
      (element: TarifasV1Model) => {
        this.valortotalVALP1_PC = this.valortotalVALP1_PC + element.VALP1;
        this.valortotalVALP2_PC = this.valortotalVALP2_PC + element.VALP2;
        this.valortotalVALP3_PC = this.valortotalVALP3_PC + element.VALP3;
        this.valortotalVALP4_PC = this.valortotalVALP4_PC + element.VALP4;
        this.valortotalVALP5_PC = this.valortotalVALP5_PC + element.VALP5;
        this.valortotalVALP6_PC = this.valortotalVALP6_PC + element.VALP6;
        this.valortotalADC01_PC = this.valortotalADC01_PC + element.ADC01;
        this.valortotalADC02_PC = this.valortotalADC02_PC + element.ADC02;
        this.valortotalADC03_PC = this.valortotalADC03_PC + element.ADC03;
      }
    )
    this.valorVALP1_PC = parseFloat((this.valortotalVALP1_PC / this.quantasdiarias).toFixed(2));
    this.valorVALP2_PC = parseFloat((this.valortotalVALP2_PC / this.quantasdiarias).toFixed(2));
    this.valorVALP3_PC = parseFloat((this.valortotalVALP3_PC / this.quantasdiarias).toFixed(2));
    this.valorVALP4_PC = parseFloat((this.valortotalVALP4_PC / this.quantasdiarias).toFixed(2));
    this.valorVALP5_PC = parseFloat((this.valortotalVALP5_PC / this.quantasdiarias).toFixed(2));
    this.valorVALP6_PC = parseFloat((this.valortotalVALP6_PC / this.quantasdiarias).toFixed(2));
    this.valorADC01_PC = parseFloat((this.valortotalADC01_PC / this.quantasdiarias).toFixed(2));
    this.valorADC02_PC = parseFloat((this.valortotalADC02_PC / this.quantasdiarias).toFixed(2));
    this.valorADC03_PC = parseFloat((this.valortotalADC03_PC / this.quantasdiarias).toFixed(2));

    await this.calculaReserva();
  }

  async calculaReserva() {
    /** reseta variaveis para os novos calculos */
    await this.resetVariaveisHospedes();

    /** organiza os quartos para efetuar os cálculos */
    await this.organizaQuartos();

    /**
     * organizando as crianças nos quartos
     */
    if (this.criancas) {
      if (this.criancas == 1) {
        this.criancasquarto01 = 1;
      }
      if (this.criancas == 2) {
        this.criancasquarto01 = 2;
      }
      if (this.criancas == 3) {
        this.criancasquarto01 = 2;
        this.criancasquarto02 = 1;
      }
      if (this.criancas == 4) {
        this.criancasquarto01 = 2;
        this.criancasquarto02 = 2;
      }
    }

    await this.calculaQuarto01();
    await this.calculaQuarto02();
    await this.calculaQuarto03();
    await this.calculaQuartos();

    this.totaldiaria_CM = this.diariaquarto01_CM + this.diariaquarto02_CM + this.diariaquarto03_CM;
    this.totalsempensao = this.totaldiaria_CM * this.quantasdiarias;
    this.mediaDiariaCM = (this.totalsempensao / this.quartos) / this.quantasdiarias;

    this.totaldiaria_MP = this.diariaquarto01_MP + this.diariaquarto02_MP + this.diariaquarto03_MP;
    this.totalmeiapensao = this.totaldiaria_MP * this.quantasdiarias;
    this.mediaDiariaMP = (this.totalmeiapensao / this.quartos) / this.quantasdiarias;

    this.totaldiaria_PC = this.diariaquarto01_PC + this.diariaquarto02_PC + this.diariaquarto03_PC;
    this.totalpensaocompleta = this.totaldiaria_PC * this.quantasdiarias;
    this.mediaDiariaPC = (this.totalpensaocompleta / this.quartos) / this.quantasdiarias;

    let valorPorQuarto = {
      totaldiariaquarto01_CM: this.totaldiariaquarto01_CM,
      totaldiariaquarto01_MP: this.totaldiariaquarto01_MP,
      totaldiariaquarto01_PC: this.totaldiariaquarto01_PC,
      totaldiariaquarto02_CM: this.totaldiariaquarto02_CM,
      totaldiariaquarto02_MP: this.totaldiariaquarto02_MP,
      totaldiariaquarto02_PC: this.totaldiariaquarto02_PC,
      totaldiariaquarto03_CM: this.totaldiariaquarto03_CM,
      totaldiariaquarto03_MP: this.totaldiariaquarto03_MP,
      totaldiariaquarto03_PC: this.totaldiariaquarto03_PC,
    }
    this.dadosOrganizaQuartosService.isQuartosAvancado(valorPorQuarto);

    let organizaquartos = {
      quartos: this.quartos,
      adultos: this.adultos,
      jovens: this.jovens,
      criancas: this.criancas,
      adultosquarto01: this.adultosquarto01,
      adultosquarto02: this.adultosquarto02,
      adultosquarto03: this.adultosquarto03,
      jovensquarto01: this.jovensquarto01,
      jovensquarto02: this.jovensquarto02,
      jovensquarto03: this.jovensquarto03,
      criancasquarto01: this.criancasquarto01,
      criancasquarto02: this.criancasquarto02,
      criancasquarto03: this.criancasquarto03,
    }
    this.dadosOrganizaQuartosService.isQuartoSimples(organizaquartos);

    let valordiarias = {
      quantasdiarias: this.quantasdiarias,
      diariaquarto01_CM: this.diariaquarto01_CM,
      diariaquarto02_CM: this.diariaquarto02_CM,
      diariaquarto03_CM: this.diariaquarto03_CM,
      diariaquarto01_MP: this.diariaquarto01_MP,
      diariaquarto02_MP: this.diariaquarto02_MP,
      diariaquarto03_MP: this.diariaquarto03_MP,
      diariaquarto01_PC: this.diariaquarto01_PC,
      diariaquarto02_PC: this.diariaquarto02_PC,
      diariaquarto03_PC: this.diariaquarto03_PC,
    }
    this.setCalculaReservaNovoPeriodo(valordiarias);

  }

  async calculaQuarto01() {
    /**
     * referente ao quarto 01
     *
     * adulto quarto 01
     */
    if (this.adultosquarto01) {
      if (this.adultosquarto01 == 1) {
        this.diariaquarto01_CM = this.diariaquarto01_CM + this.valorVALP1_CM;
        this.diariaquarto01_MP = this.diariaquarto01_MP + this.valorVALP1_MP;
        this.diariaquarto01_PC = this.diariaquarto01_PC + this.valorVALP1_PC;
      }
      if (this.adultosquarto01 == 2) {
        this.diariaquarto01_CM = this.diariaquarto01_CM + this.valorVALP2_CM;
        this.diariaquarto01_MP = this.diariaquarto01_MP + this.valorVALP2_MP;
        this.diariaquarto01_PC = this.diariaquarto01_PC + this.valorVALP2_PC;
      }
      if (this.adultosquarto01 == 3) {
        this.diariaquarto01_CM = this.diariaquarto01_CM + this.valorVALP3_CM;
        this.diariaquarto01_MP = this.diariaquarto01_MP + this.valorVALP3_MP;
        this.diariaquarto01_PC = this.diariaquarto01_PC + this.valorVALP3_PC;
      }
      if (this.adultosquarto01 == 4) {
        this.diariaquarto01_CM = this.diariaquarto01_CM + this.valorVALP4_CM;
        this.diariaquarto01_MP = this.diariaquarto01_MP + this.valorVALP4_MP;
        this.diariaquarto01_PC = this.diariaquarto01_PC + this.valorVALP4_PC;
      }
    }

    /**
     * jovens quarto 01
     */
    if (this.jovensquarto01) {
      if (this.jovensquarto01 == 1) {
        this.diariaquarto01_CM = this.diariaquarto01_CM + this.valorADC01_CM;
        this.diariaquarto01_MP = this.diariaquarto01_MP + this.valorADC01_MP;
        this.diariaquarto01_PC = this.diariaquarto01_PC + this.valorADC01_PC;
      }
      if (this.jovensquarto01 == 2) {
        this.diariaquarto01_CM = this.diariaquarto01_CM + this.valorADC02_CM;
        this.diariaquarto01_MP = this.diariaquarto01_MP + this.valorADC02_MP;
        this.diariaquarto01_PC = this.diariaquarto01_PC + this.valorADC02_PC;
      }
      if (this.jovensquarto01 == 3) {
        this.diariaquarto01_CM = this.diariaquarto01_CM + this.valorADC03_CM;
        this.diariaquarto01_MP = this.diariaquarto01_MP + this.valorADC03_MP;
        this.diariaquarto01_PC = this.diariaquarto01_PC + this.valorADC03_PC;
      }
    }

    this.totaldiariaquarto01_CM = (this.diariaquarto01_CM * this.quantasdiarias) * this.quartos;
    this.totaldiariaquarto01_MP = (this.diariaquarto01_MP * this.quantasdiarias) * this.quartos;
    this.totaldiariaquarto01_PC = (this.diariaquarto01_PC * this.quantasdiarias) * this.quartos;
  }

  async calculaQuarto02() {
    /**
     * referente ao quarto 02
     */
    if (this.adultosquarto02) {
      if (this.adultosquarto02 == 1) {
        this.diariaquarto02_CM = this.diariaquarto02_CM + this.valorVALP1_CM;
        this.diariaquarto02_MP = this.diariaquarto02_MP + this.valorVALP1_MP;
        this.diariaquarto02_PC = this.diariaquarto02_PC + this.valorVALP1_PC;
      }
      if (this.adultosquarto02 == 2) {
        this.diariaquarto02_CM = this.diariaquarto02_CM + this.valorVALP2_CM;
        this.diariaquarto02_MP = this.diariaquarto02_MP + this.valorVALP2_MP;
        this.diariaquarto02_PC = this.diariaquarto02_PC + this.valorVALP2_PC;
      }
      if (this.adultosquarto02 == 3) {
        this.diariaquarto02_CM = this.diariaquarto02_CM + this.valorVALP3_CM;
        this.diariaquarto02_MP = this.diariaquarto02_MP + this.valorVALP3_MP;
        this.diariaquarto02_PC = this.diariaquarto02_PC + this.valorVALP3_PC;
      }
      if (this.adultosquarto02 == 4) {
        this.diariaquarto02_CM = this.diariaquarto02_CM + this.valorVALP4_CM;
        this.diariaquarto02_MP = this.diariaquarto02_MP + this.valorVALP4_MP;
        this.diariaquarto02_PC = this.diariaquarto02_PC + this.valorVALP4_PC;
      }
      if (this.adultosquarto02 == 5) {
        this.diariaquarto02_CM = this.diariaquarto02_CM + this.valorVALP5_CM;
        this.diariaquarto02_MP = this.diariaquarto02_MP + this.valorVALP5_MP;
        this.diariaquarto02_PC = this.diariaquarto02_PC + this.valorVALP5_PC;
      }
    }

    if (this.jovensquarto02) {
      if (this.jovensquarto02 == 1) {
        this.diariaquarto02_CM = this.diariaquarto02_CM + this.valorADC01_CM;
        this.diariaquarto02_MP = this.diariaquarto02_MP + this.valorADC01_MP;
        this.diariaquarto02_PC = this.diariaquarto02_PC + this.valorADC01_PC;
      }
      if (this.jovensquarto02 == 2) {
        this.diariaquarto02_CM = this.diariaquarto02_CM + this.valorADC02_CM;
        this.diariaquarto02_MP = this.diariaquarto02_MP + this.valorADC02_MP;
        this.diariaquarto02_PC = this.diariaquarto02_PC + this.valorADC02_PC;
      }
      if (this.jovensquarto02 == 3) {
        this.diariaquarto02_CM = this.diariaquarto02_CM + this.valorADC03_CM;
        this.diariaquarto02_MP = this.diariaquarto02_MP + this.valorADC03_MP;
        this.diariaquarto02_PC = this.diariaquarto02_PC + this.valorADC03_PC;
      }
    }

    this.totaldiariaquarto02_CM = (this.diariaquarto02_CM * this.quantasdiarias) * this.quartos;
    this.totaldiariaquarto02_MP = (this.diariaquarto02_MP * this.quantasdiarias) * this.quartos;
    this.totaldiariaquarto02_PC = (this.diariaquarto02_PC * this.quantasdiarias) * this.quartos;
  }

  async calculaQuarto03() {
    /**
     * referente ao quarto 03
     */
    if (this.adultosquarto03) {
      if (this.adultosquarto03 == 1) {
        this.diariaquarto03_CM = this.diariaquarto03_CM + this.valorVALP1_CM;
        this.diariaquarto03_MP = this.diariaquarto03_MP + this.valorVALP1_MP;
        this.diariaquarto03_PC = this.diariaquarto03_PC + this.valorVALP1_PC;
      }
      if (this.adultosquarto03 == 2) {
        this.diariaquarto03_CM = this.diariaquarto03_CM + this.valorVALP2_CM;
        this.diariaquarto03_MP = this.diariaquarto03_MP + this.valorVALP2_MP;
        this.diariaquarto03_PC = this.diariaquarto03_PC + this.valorVALP2_PC;
      }
      if (this.adultosquarto03 == 3) {
        this.diariaquarto03_CM = this.diariaquarto03_CM + this.valorVALP3_CM;
        this.diariaquarto03_MP = this.diariaquarto03_MP + this.valorVALP3_MP;
        this.diariaquarto03_PC = this.diariaquarto03_PC + this.valorVALP3_PC;
      }
      if (this.adultosquarto03 == 4) {
        this.diariaquarto03_CM = this.diariaquarto03_CM + this.valorVALP4_CM;
        this.diariaquarto03_MP = this.diariaquarto03_MP + this.valorVALP4_MP;
        this.diariaquarto03_PC = this.diariaquarto03_PC + this.valorVALP4_PC;
      }
      if (this.adultosquarto03 == 5) {
        this.diariaquarto03_CM = this.diariaquarto03_CM + this.valorVALP5_CM;
        this.diariaquarto03_MP = this.diariaquarto03_MP + this.valorVALP5_MP;
        this.diariaquarto03_PC = this.diariaquarto03_PC + this.valorVALP5_PC;
      }
    }

    if (this.jovensquarto03) {
      if (this.jovensquarto03 == 1) {
        this.diariaquarto03_CM = this.diariaquarto03_CM + this.valorADC01_CM;
        this.diariaquarto03_MP = this.diariaquarto03_MP + this.valorADC01_MP;
        this.diariaquarto03_PC = this.diariaquarto03_PC + this.valorADC01_PC;
      }
      if (this.jovensquarto03 == 2) {
        this.diariaquarto03_CM = this.diariaquarto03_CM + this.valorADC02_CM;
        this.diariaquarto03_MP = this.diariaquarto03_MP + this.valorADC02_MP;
        this.diariaquarto03_PC = this.diariaquarto03_PC + this.valorADC02_PC;
      }
      if (this.jovensquarto03 == 3) {
        this.diariaquarto03_CM = this.diariaquarto03_CM + this.valorADC03_CM;
        this.diariaquarto03_MP = this.diariaquarto03_MP + this.valorADC03_MP;
        this.diariaquarto03_PC = this.diariaquarto03_PC + this.valorADC03_PC;
      }
    }

    this.totaldiariaquarto03_CM = (this.diariaquarto03_CM * this.quantasdiarias) * this.quartos;
    this.totaldiariaquarto03_MP = (this.diariaquarto03_MP * this.quantasdiarias) * this.quartos;
    this.totaldiariaquarto03_PC = (this.diariaquarto03_PC * this.quantasdiarias) * this.quartos;
  }

  async organizaQuartos() {
    this.adultosquarto01 = 0;
    this.jovensquarto01 = 0;
    this.criancasquarto01 = 0;

    this.adultosquarto02 = 0;
    this.jovensquarto02 = 0;
    this.criancasquarto02 = 0;

    this.adultosquarto03 = 0;
    this.jovensquarto03 = 0;
    this.criancasquarto03 = 0;

    /**
     * em casos onde o total da soma do hospedes,
     * adultos e jovens serem menor ou igual a 4
     * */
    if ((this.adultos + this.jovens) <= 4) {
      this.adultosquarto01 = this.adultos;
      this.jovensquarto01 = this.jovens;
    }

    /**
     * em casos onde o total de hospedes,
     * adultos e jovens serem maior que 4 e menor ou igual a 8
     * */
    if ((this.adultos + this.jovens) > 4 && (this.adultos + this.jovens) <= 8) {

      /**
       * calculando hospedes adultos do 1 quarto
       * */
      this.adultosquarto01 = (this.adultos % 2) == 0 ?
        this.adultos / 2 :
        (Math.trunc((this.adultos / 2)) + this.adultos % 2);

      /** calculando hospedes adultos do 2 quarto */
      this.adultosquarto02 = this.adultosquarto02 + Math.trunc(this.adultos / 2);

      /**
       * calculando hospedes jovens do 1 quarto
       * */
      this.jovensquarto01 = Math.trunc(this.jovens / 2);

      /** calculando hospedes jovens do 2 quarto */
      this.jovensquarto02 = (this.jovens % 2) == 0 ?
        this.jovens / 2 :
        (Math.trunc((this.jovens / 2)) + this.jovens % 2);
    }
    /**
         * em casos onde o total de hospedes,
         * adultos e jovens serem maior que 8 e menor ou igual a 12
         * */
    if ((this.adultos + this.jovens) > 8 && (this.adultos + this.jovens) <= 12) {

      /**
       * calculando hospedes adultos do 1 quarto
       * */
      if ((this.adultos % 3) == 0) {
        this.adultosquarto01 = this.adultos / 3;
        this.adultosquarto02 = this.adultos / 3;
        this.adultosquarto03 = this.adultos / 3;

      } else if ((this.adultos % 3) == 1) {
        this.adultosquarto01 = Math.trunc(this.adultos / 3) + this.adultos % 3;
        this.adultosquarto02 = Math.trunc(this.adultos / 3);
        this.adultosquarto03 = Math.trunc(this.adultos / 3);

      } else if ((this.adultos % 3) == 2) {
        this.adultosquarto01 = Math.trunc(this.adultos / 3) + 1;
        this.adultosquarto02 = Math.trunc(this.adultos / 3) + 1;
        this.adultosquarto03 = Math.trunc(this.adultos / 3);
      }

      /**
       * calculando hospedes jovens do 1 quarto
       * */
      if ((this.jovens % 3) == 0) {
        this.jovensquarto01 = this.jovens / 3;
        this.jovensquarto02 = Math.trunc(this.jovens / 3);
        this.jovensquarto03 = Math.trunc(this.jovens / 3);

      } else if ((this.jovens % 3) == 1) {
        this.jovensquarto01 = Math.trunc(this.jovens / 3);
        this.jovensquarto02 = Math.trunc(this.jovens / 3);
        this.jovensquarto03 = Math.trunc(this.jovens / 3) + this.jovens % 3;

      } else if ((this.jovens % 3) == 2) {
        this.jovensquarto01 = Math.trunc(this.jovens / 3);
        this.jovensquarto02 = Math.trunc(this.jovens / 3) + 1;
        this.jovensquarto03 = Math.trunc(this.jovens / 3) + 1;
      }
    }
  }

  async calculaQuartos() {
    if ((this.adultos + this.jovens) <= 4) {
      this.quartos = 1;
    }
    if ((this.adultos + this.jovens) >= 5 && (this.adultos + this.jovens) <= 8) {
      this.quartos = 2;
    }
    if ((this.adultos + this.jovens) > 8) {
      this.quartos = 3;
    }
    this.dadosOrganizaQuartosService.isQuartos(this.quartos);
  }



  /** Setando o evento e acionando o EventEmitter() */
  setCalculaReservaNovoPeriodo(
    valordiarias: any
  ) {
    let newcalculo: any = {
      // newcalculo: this.newCalculo,
      quartos: this.quartos,
      valordiaria: this.valordiaria,
      adultos: this.adultos,
      jovens: this.jovens,
      criancas: this.criancas,
      dataentrada: this.dataentrada,
      datasaida: this.datasaida,
      totalsempensao: parseFloat((this.totalsempensao).toFixed(2)),
      totalmeiapensao: parseFloat((this.totalmeiapensao).toFixed(2)),
      totalpensaocompleta: parseFloat((this.totalpensaocompleta).toFixed(2)),
      totaldiaria_CM: parseFloat((this.totaldiaria_CM).toFixed(2)),
      totaldiaria_MP: parseFloat((this.totaldiaria_MP).toFixed(2)),
      totaldiaria_PC: parseFloat((this.totaldiaria_PC).toFixed(2)),
      valordiarias: valordiarias,
      mediaDiariaCM: parseFloat((this.mediaDiariaCM).toFixed(2)),
      mediaDiariaMP: parseFloat((this.mediaDiariaMP).toFixed(2)),
      mediaDiariaPC: parseFloat((this.mediaDiariaPC).toFixed(2))
    }
    this.calculaReservadisponivel.emit(newcalculo);
  }

  async zeraCalculoPosPesquisa() {

    this.diariaquarto01_CM = 0;
    this.diariaquarto01_MP = 0;
    this.diariaquarto01_PC = 0;

    this.diariaquarto02_CM = 0;
    this.diariaquarto02_MP = 0;
    this.diariaquarto02_PC = 0;

    this.diariaquarto03_CM = 0;
    this.diariaquarto03_MP = 0;
    this.diariaquarto03_PC = 0;

    this.valorVALP1_CM = 0;
    this.valorVALP2_CM = 0;
    this.valorVALP3_CM = 0;
    this.valorVALP4_CM = 0;
    this.valorVALP5_CM = 0;
    this.valorVALP6_CM = 0;
    this.valorADC01_CM = 0;
    this.valorADC02_CM = 0;
    this.valorADC03_CM = 0;

    this.valortotalVALP1_CM = 0;
    this.valortotalVALP2_CM = 0;
    this.valortotalVALP3_CM = 0;
    this.valortotalVALP4_CM = 0;
    this.valortotalVALP5_CM = 0;
    this.valortotalVALP6_CM = 0;
    this.valortotalADC01_CM = 0;
    this.valortotalADC02_CM = 0;
    this.valortotalADC03_CM = 0;

    this.valorVALP1_MP = 0;
    this.valorVALP2_MP = 0;
    this.valorVALP3_MP = 0;
    this.valorVALP4_MP = 0;
    this.valorVALP5_MP = 0;
    this.valorVALP6_MP = 0;
    this.valorADC01_MP = 0;
    this.valorADC02_MP = 0;
    this.valorADC03_MP = 0;

    this.valortotalVALP1_MP = 0;
    this.valortotalVALP2_MP = 0;
    this.valortotalVALP3_MP = 0;
    this.valortotalVALP4_MP = 0;
    this.valortotalVALP5_MP = 0;
    this.valortotalVALP6_MP = 0;
    this.valortotalADC01_MP = 0;
    this.valortotalADC02_MP = 0;
    this.valortotalADC03_MP = 0;

    this.valorVALP1_PC = 0;
    this.valorVALP2_PC = 0;
    this.valorVALP3_PC = 0;
    this.valorVALP4_PC = 0;
    this.valorVALP5_PC = 0;
    this.valorVALP6_PC = 0;
    this.valorADC01_PC = 0;
    this.valorADC02_PC = 0;
    this.valorADC03_PC = 0;

    this.valortotalVALP1_PC = 0;
    this.valortotalVALP2_PC = 0;
    this.valortotalVALP3_PC = 0;
    this.valortotalVALP4_PC = 0;
    this.valortotalVALP5_PC = 0;
    this.valortotalVALP6_PC = 0;
    this.valortotalADC01_PC = 0;
    this.valortotalADC02_PC = 0;
    this.valortotalADC03_PC = 0;

    this.tarifasLocalizadas_CM = [];
    this.tarifasLocalizadas_MP = [];
    this.tarifasLocalizadas_PC = [];

  }

  async resetVariaveisHospedes() {

    this.adultosquarto01 = 0;
    this.jovensquarto01 = 0;
    this.criancasquarto01 = 0;

    this.adultosquarto02 = 0;
    this.jovensquarto02 = 0;
    this.criancasquarto02 = 0;

    this.adultosquarto03 = 0;
    this.jovensquarto03 = 0;
    this.criancasquarto03 = 0;

    this.diariaquarto01_CM = 0;
    this.diariaquarto01_MP = 0;
    this.diariaquarto01_PC = 0;

    this.diariaquarto02_CM = 0;
    this.diariaquarto02_MP = 0;
    this.diariaquarto02_PC = 0;

    this.diariaquarto03_CM = 0;
    this.diariaquarto03_MP = 0;
    this.diariaquarto03_PC = 0;

    this.mediaDiariaCM = 0;
    this.mediaDiariaMP = 0;
    this.mediaDiariaPC = 0;

    this.valordiaria = 0;
    this.totalreserva = 0;
    this.totaldiaria_CM = 0;
    this.totalsempensao = 0;
    this.totaldiaria_MP = 0;
    this.totalmeiapensao = 0;
    this.totaldiaria_PC = 0;
    this.totalpensaocompleta = 0;

    this.totaldiariaquarto01_CM = 0;
    this.totaldiariaquarto01_MP = 0;
    this.totaldiariaquarto01_PC = 0;
    this.totaldiariaquarto02_CM = 0;
    this.totaldiariaquarto02_MP = 0;
    this.totaldiariaquarto03_PC = 0;
    this.totaldiariaquarto03_CM = 0;
    this.totaldiariaquarto03_MP = 0;
    this.totaldiariaquarto03_PC = 0;
  }

}
