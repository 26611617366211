import { Component, OnInit } from '@angular/core';
import { CalculaReservaV1Service } from '../events/calcula-reserva-v1.service';
import { DadosReservaEscolhidaService } from '../events/dados-reserva-escolhida.service';

@Component({
  selector: 'app-disponibilidade',
  templateUrl: './disponibilidade.component.html',
  styleUrls: ['./disponibilidade.component.scss']
})
export class DisponibilidadeComponent implements OnInit {

  /**
   * Variaveis de ambiente
   */
  totalpensaocompleta: number = 0;
  totalmeiapensao: number = 0;
  totalsempensao: number = 0;
  opcaoescolhida: number = 3;
  totaldiaria_CM: number = 0;
  totaldiaria_MP: number = 0;
  totaldiaria_PC: number = 0;
  mediaDiariaCM: number = 0;
  mediaDiariaMP: number = 0;
  mediaDiariaPC: number = 0;

  isParcelado: boolean = false;
  quantParcela_CM: number = 0;
  parcela_CM!: number;
  quantParcela_MP: number = 0;
  parcela_MP!: number;
  quantParcela_PC: number = 0;
  parcela_PC!: number;

  quartos: number = 1;
  adultos: number = 0;
  jovens: number = 0;
  criancas: number = 0;

  constructor(
    private readonly calculaReservaService: CalculaReservaV1Service,
    private readonly dadosReservaEscolhidaService: DadosReservaEscolhidaService
  ) { }

  ngOnInit(): void {

    this.calculaReservaService.calculaReservadisponivel
      .subscribe(newcalculo => {
        this.zeraVariaveis();
        this.quartos = newcalculo.quartos;
        this.criancas = newcalculo.criancas;
        this.totaldiaria_CM = newcalculo.totaldiaria_CM;
        this.totaldiaria_MP = newcalculo.totaldiaria_MP;
        this.totaldiaria_PC = newcalculo.totaldiaria_PC;
        this.totalsempensao = newcalculo.totalsempensao;
        this.totalmeiapensao = newcalculo.totalmeiapensao;
        this.totalpensaocompleta = newcalculo.totalpensaocompleta;
        this.mediaDiariaCM = newcalculo.mediaDiariaCM;
        this.mediaDiariaMP = newcalculo.mediaDiariaMP;
        this.mediaDiariaPC = newcalculo.mediaDiariaPC;
        this.parcela_CM = this.calculaParcela(this.totalsempensao, 'CM');
        this.parcela_MP = this.calculaParcela(this.totalmeiapensao, 'MP');
        this.parcela_PC = this.calculaParcela(this.totalpensaocompleta, 'PC');
        this.opSelecionada(3);
      });
  }

  zeraVariaveis() {
    this.quartos = 1;
    this.criancas = 0;
    this.totaldiaria_CM = 0;
    this.totaldiaria_MP = 0;
    this.totaldiaria_PC = 0;
    this.totalsempensao = 0;
    this.totalmeiapensao = 0;
    this.totalpensaocompleta = 0;
    this.mediaDiariaCM = 0;
    this.mediaDiariaMP = 0;
    this.mediaDiariaPC = 0;
    this.parcela_CM = 0;
    this.parcela_MP = 0;
    this.parcela_PC = 0;
  }

  opSelecionada(opcao: number) {
    opcao == 1 ? this.pacoteCafedaManha() : false;
    opcao == 2 ? this.pacoteMeiaPensao() : false;
    opcao == 3 ? this.pacotePensaoCompleta() : false;
  }

  pacoteCafedaManha() {
    this.opcaoescolhida = 1;
    this.dadosReservaEscolhidaService.isDetalhesReservaEscolhida(1);
  }

  pacoteMeiaPensao() {
    this.opcaoescolhida = 2;
    this.dadosReservaEscolhidaService.isDetalhesReservaEscolhida(2);
  }

  pacotePensaoCompleta() {
    this.opcaoescolhida = 3;
    this.dadosReservaEscolhidaService.isDetalhesReservaEscolhida(3);
  }

  valor(valor: number): number {
    let numero!: number;
    let texto01: string = valor.toFixed(2).toString().replace(/[^\d]+/g, '');
    numero = +parseFloat(texto01.substring(0, texto01.length + -2) + "." + texto01.substring(texto01.length + -2, texto01.length));
    // numero = +texto01
    return numero;
  }

  calculaParcela(valor: number, tpreserva: string): number {
    let valorRetornado: number = 0;

    /** avaliando o valor e definindo o parcelamento possivel */
    /** maior ou igual a 1200 */
    valor >= 1200 ? (
      tpreserva == 'CM' ? (
        this.quantParcela_CM = 6,
        valorRetornado = valor / 6
      ) : null,
      tpreserva == 'MP' ? (
        this.quantParcela_MP = 6,
        valorRetornado = valor / 6
      ) : null,
      tpreserva == 'PC' ? (
        this.quantParcela_PC = 6,
        valorRetornado = valor / 6
      ) : null
    ) : null;

    /** maior ou igual a 1000 ou menor ou igual a 1199 */
    valor >= 1000 && valor <= 1199 ? (
      tpreserva == 'CM' ? (
        this.quantParcela_CM = 5,
        valorRetornado = valor / 5
      ) : null,
      tpreserva == 'MP' ? (
        this.quantParcela_MP = 5,
        valorRetornado = valor / 5
      ) : null,
      tpreserva == 'PC' ? (
        this.quantParcela_PC = 5,
        valorRetornado = valor / 5
      ) : null
    ) : null;

    /** maior ou igual a 800 ou menor ou igual a 999 */
    valor >= 800 && valor <= 999 ? (
      tpreserva == 'CM' ? (
        this.quantParcela_CM = 4,
        valorRetornado = valor / 4
      ) : null,
      tpreserva == 'MP' ? (
        this.quantParcela_MP = 4,
        valorRetornado = valor / 4
      ) : null,
      tpreserva == 'PC' ? (
        this.quantParcela_PC = 4,
        valorRetornado = valor / 4
      ) : null
    ) :

      /** maior ou igual a 600 ou menor ou igual a 799 */
      valor >= 600 && valor <= 799 ? (
        tpreserva == 'CM' ? (
          this.quantParcela_CM = 3,
          valorRetornado = valor / 3
        ) : null,
        tpreserva == 'MP' ? (
          this.quantParcela_MP = 3,
          valorRetornado = valor / 3
        ) : null,
        tpreserva == 'PC' ? (
          this.quantParcela_PC = 3,
          valorRetornado = valor / 3
        ) : null
      ) :

        /** menor ou igual a 400 ou menor ou igual a 599 */
        valor >= 400 && valor <= 599 ? (
          tpreserva == 'CM' ? (
            this.quantParcela_CM = 2,
            valorRetornado = valor / 2
          ) : null,
          tpreserva == 'MP' ? (
            this.quantParcela_MP = 2,
            valorRetornado = valor / 2
          ) : null,
          tpreserva == 'PC' ? (
            this.quantParcela_PC = 2,
            valorRetornado = valor / 2
          ) : null
        ) : null;

    /** menor ou igual a 399 */
    valor <= 399 ? (
      valorRetornado = valor,
      this.quantParcela_CM = 0,
      this.quantParcela_MP = 0,
      this.quantParcela_PC = 0
    ) : null;

    return valorRetornado;
  }

  formatter(value: number): string {
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value);
  }

}
