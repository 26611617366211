import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-muita-diversao',
  templateUrl: './home-muita-diversao.component.html',
  styleUrls: ['./home-muita-diversao.component.scss'],
})
export class HomeMuitaDiversaoComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  aboutImage: Image[] = [
    {
      img: 'assets/img/muita-diversao/hotel-ctc.png',
    },
  ];
  aboutContent: Content[] = [
    {
      subTitle: 'Hotel CTC',
      title: 'Água termal mineral e muita diversão!',
      paragraphText1:
        'O Hotel CTC oferece o melhor da água termal da região, disponibilizando diversão e tranquilidade para toda a família e contato com a natureza. Hóspedes do hotel CTC tem acesso exclusivo a três piscinas, sauna e ofurôs além de toda estrutura do Metropolitan Park grátis!',
      paragraphText2:
        'Todo parque aquático são diariamente lavadas e higienizadas afim de oferecer a melhor qualidade de balneabilidade de cidade.',
      defaultBtnIcon: 'flaticon-right',
      defaultBtnText: 'Mais detalhes?',
      defaultBtnLink: 'about-us',
    },
  ];
  featuresList: List[] = [
    {
      icon: 'assets/img/muita-diversao/alimentacao.png',
      title: 'Alimentação',
      subTitle: 'água termal natural',
      link: 'instalacoes-alimentacao',
    },
    {
      icon: 'assets/img/muita-diversao/apartamento.png',
      title: 'Apartamentos',
      subTitle: 'água termal natural',
      link: 'instalacoes-apartamentos',
    },
    {
      icon: 'assets/img/muita-diversao/lazer.png',
      title: 'Lazer',
      subTitle: 'água termal natural',
      link: 'instalacoes-lazer',
    },
    {
      icon: 'assets/img/muita-diversao/servicos.png',
      title: 'Serviços',
      subTitle: 'água termal natural',
      link: 'instalacoes-servicos',
    },
  ];
}
class Image {
  img: string;
}
class Content {
  subTitle: string;
  title: string;
  paragraphText1: string;
  paragraphText2: string;
  defaultBtnIcon: string;
  defaultBtnText: string;
  defaultBtnLink: string;
}
class List {
  icon: string;
  title: string;
  subTitle: string;
  link: string;
}
