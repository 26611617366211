import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DadosReservaV2Model } from 'src/app/reservas/models/dados-reserva-v2.model';
import { UtilsService } from 'src/app/share/utils.service';

@Component({
  selector: 'app-form-disabled',
  templateUrl: './form-disabled.component.html',
  styleUrls: ['./form-disabled.component.scss']
})
export class FormDisabledComponent {

  /** variaveis de ambiente */
  iniDate?: string;
  minDate?: string;
  quantAdultoT: number = 0;
  quantJovensT: number = 0;
  quantCriancaT: number = 0;
  disabled: boolean = true;
  reservaEscolhida: DadosReservaV2Model

  bsConfig = {
    dateInputFormat: 'DD/MM/YYYY',
    adaptivePosition: true,
    showWeekNumbers: false,
    returnFocusToInput: true,
    containerClass: 'theme-orange',
  };
  isDisabled = true

  /**
   * variáveis do component
   */
  form!: FormGroup;
  submitted = false;
  formErrors: any;

  constructor(
    private route: Router,
    private fb: FormBuilder,
    private readonly utilsService: UtilsService,
  ) { }

  ngOnInit(): void {
    this.reservaEscolhida = this.utilsService.getDadosReserva();
    this.dadosForm(this.reservaEscolhida);
    this.form = this.fb.group(this.getFormReserva());
    this.form.controls['dtentrada'].disable();
    this.form.controls['dtsaida'].disable();
  }

  // Irrelevante para a documentação
  get f() {
    return this.form.controls;
  }

  getFormReserva() {
    return {
      dtentrada: ['', Validators.compose([Validators.required])],
      dtsaida: ['', Validators.compose([Validators.required,])],
      adultos: [''],
      jovens: [''],
      criancas: [''],
    };
  }

  dadosForm(reservaEscolhida: DadosReservaV2Model) {
    this.iniDate = reservaEscolhida.dtInicio;
    this.minDate = reservaEscolhida.dtFinal;
    this.quantAdultoT = reservaEscolhida.totalAdultos;
    this.quantJovensT = reservaEscolhida.totalJovens;
    this.quantCriancaT = reservaEscolhida.totalCriancas;
  }

  onSubmit() {

  }

}
