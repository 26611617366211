<section class="page-title-area">
  <div class="container">
    <div class="page-title-content" *ngFor="let pageTitle of pageTitleArea;">
      <h2>{{pageTitle.title}}</h2>
      <ul>
        <li><a routerLink="/">Home</a></li>
        <li>{{pageTitle.title}}</li>
        <li>{{pageTitle.subTitle}}</li>
      </ul>
    </div>
  </div>

  <div class="shape-img1"><img src="assets/img/shape/shape1.svg" alt="image"></div>
  <div class="shape-img2"><img src="assets/img/shape/shape2.png" alt="image"></div>
  <div class="shape-img3"><img src="assets/img/shape/shape3.png" alt="image"></div>
</section>

<section class="case-studies-details-area">
  <div class="container">
    <div class="row">
      <div class="col-lg-8 col-md-12">
        <div class="case-studies-details-desc" *ngFor="let DetailsDesc of caseStudiesDetailsDesc;">
          <span class="sub-title">{{DetailsDesc.subTitle}}</span>
          <h3>{{DetailsDesc.title1}}</h3>
          <p style="text-align: justify;"><strong>SEGURANÇA</strong><br>
            - Não deixe pertences soltos ou sem supervisão na recepção, parque aquático, restaurantes e
            demais áreas comuns e dependências do hotel e clube.<br>
            - Visando sua privacidade, não dispomos de câmeras nas piscinas.<br>
            - Não aceite ajuda ou propostas de estranhos, informe-se sempre com as equipes do hotel.<br>
            - Não afaste-se de sua bagagem durante o check in ou check out.<br>
            - Ao sair do apartamento, certifique-se de que janelas e porta estejam fechadas.<br>
            Cofre: Disponível gratuitamente nos apartamentos, disponibilizado o trancão na recepção. O hotel
            não se responsabiliza por objetos de valor, joias, dinheiro etc. mantidos no apartamento fora do cofre.
            Pela mesma razão, não se responsabiliza por perdas, extravios, furtos ou quaisquer outros sinistros
            que porventura possam ocorrer nas áreas sociais e dependências do hotel e clube.
            Em caso de perda da chave, será cobrado o valor de mercado para arrombamento e reposição do
            trancão.</p>
          <p style="text-align: justify;"><strong>HOSPEDAGEM</strong><br>
            Recepção: atendimento 24 horas<br>
            Horários: Entrada à partir das 12h e Saída até 10h.<br>
            Saída Tardia (late check out): verificar valores e condições com a recepção. Saídas tardias, se não
            informadas, estão passíveis de cobranças adicionais.<br>
            - Em cumprimento aos artigos 82 e 83 do ECA – Estatuto da Criança e do Adolescente,
            exigimos a apresentação de documento de menores para hospedagem
            - Não é permitida a hospedagem de animais de nenhum tipo, espécie ou porte.<br>
            - Exigimos o cumprimento do horário de silêncio das 22h às 8h diariamente em todas as áreas
            do hotel.<br>
            - Não é permitido estender ou pendurar roupas, toalhas, bandeiras ou quaisquer outros artigos
            nas janelas dos apartamentos.<br>
            - Não é permitido o uso, porte ou consumo de qualquer tipo de droga na estrutura do hotel e
            parque aquático. Não hesitaremos em acionar a força policial.<br>
            - Não é permitido o uso de equipamentos sonoros nos apartamentos que venham a perturbar
            outros hóspedes.<br>
            - É autorizado à gerência do hotel ou seu preposto, entrar em qualquer apartamento, com ou
            sem a presença do hóspede, desde que haja justificativa para tal.<br>
            Cartão de Consumo: Ao hospedar-se, o hóspede recebe um cartão para realizar consumos extras nos
            pontos de venda do hotel, os quais serão pagos na saída (check out).<br>
            Formas de pagamento: Aceitamos pagamentos em espécie, PIX, todos os cartões de crédito e cartões
            de débito nacionais. Não aceitamos cheques como forma de pagamento.<br>
            Estacionamento: O hotel dispõe de dois bolsões de estacionamento, um no hotel e um segundo no
            clube, com acesso pela rua lateral.<br>
            Frigobar: Para sua comodidade, o frigobar encontra-se vazio e itens podem ser adquiridos
            nos bares e lanchonetes. É proibido o consumo de itens trazidos de fora do hotel no parque
            aquático.<br>
            Internet Wi-Fi: Disponível gratuitamente em todo o hotel.</p>
          <p style="text-align: justify;"><strong>Espaço Home Office</strong><br>
            Visando atender a necessidade de hóspedes em trabalho remoto, oferecemos
            gratuitamente o espaço home office, disponibilizando internet gratuita dedicada, exclusiva para
            finalidades não recreativas.<br>
            TV: Oferecemos 17 canais entre abertos e à cabo, com programação infantil, esportes, filmes e
            variados.<br>
            Achados e perdidos: Nosso departamento de governança mantém os objetos encontrados
            por um período de 60 (sessenta) dias a contar da data em que foram encontrados e podem
            ser retirados pessoalmente, por terceiros ou enviados pelo correio correndo a despesa por
            conta do hóspede. Após este período, os itens serão descartados ou doados.</p>
          <p style="text-align: justify;"><strong>ALIMENTAÇÃO</strong><br>
            Horários:<br>
            Café da Manhã de 07h às 09h30<br>
            Almoço de 11h30 às 14h30<br>
            Jantar 19h às 21h30<br>
            - Os serviços de buffet podem ser substituídos por a la carte ou outro serviço sem aviso prévio.<br>
            - No restaurante, conforme orientação da vigilância sanitária, não é permitida a entrada
            descalço, molhado, sem camisa e/ou em trajes de banho.<br>
            - Pensões eventualmente não consumidas durante a hospedagem, não serão repostas ou
            restituídas.<br>
            Bar piscina localizado nas piscinas do hotel das 08h às 00h<br>
            Bar III localizado no parque aquático das 09h às 20h<br>
            Butekos localizado no parque aquático das 10h às 18h<br>
            Demais pontos de venda são parceiros terceirizados, maiores informações no local.</p>
          <p style="text-align: justify;"><strong>LAZER</strong><br>
            Nossa equipe de recreação oferece diariamente uma programação de atividades nas piscinas
            do hotel e no parque aquático. A programação estará disposta na recepção e pode sofrer alterações
            por condições climáticas e/ou quórum na atividade.<br>
            Nosso parque aquático dispõe de piscinas com água termal mineral, naturalmente quentes,
            não possuímos sistema de aquecimento artificial, por esta razão, sua temperatura varia de acordo
            com as condições climáticas e a hora do dia.<br>

            Piscinas e Parque Aquático: funciona das 06h às 22h<br>
            - Não é permitido o consumo de itens adquiridos fora do hotel nas áreas comuns e parque
            aquático.<br>
            - Não é permitido o uso de equipamentos sonoros individuais, de qualquer tamanho, modelo
            ou potência, no parque aquático.<br>
            - Não é permitido o consumo de alimentos no interior das piscinas.<br>
            - Não é permitido o uso de Nargilés, cigarros elétricos ou qualquer artigo fumígeno no interior
            das piscinas, áreas comuns, apartamentos ou áreas fechadas.<br>
            - Brincadeiras com bolas, boias, armas d’água, saltos, mergulhos, malabarismos, ou qualquer
            ação adversa nas piscinas ou entorno que apresente risco será proibida.<br>
            - Não é permitido o uso de boias que atrapalhem a utilização normal das piscinas.<br>
            - Os toboáguas podem ter sua atividade alterada ou suspensa de acordo com necessidade
            justificada.<br>
            - É proibido o uso de produtos como clareadores de pelos, água oxigenada e óleos de bronzear
            nas piscinas. Solicitamos que utilize as duchas antes de utilizá-las.<br>
            - Não é permitido o uso de trajes inapropriados para o uso das piscinas, tais como camisetas,
            shorts e bermudas de algodão ou jeans.<br>
            Sauna do hotel: Dispomos de sauna úmida nas piscinas do hotel. Regras de uso e horário de
            funcionamento no local.<br>
            Quadra: Dispomos de quadra poliesportiva à disposição dos hóspedes. Não fornecemos bolas.
            Academia: Dispomos de academia ao ar livre das 06h às 22h, localizado no parque aquático.
            Copa da Mamãe: Localizada no Bar Piscina.<br>
            Salão de Beleza: Localizado na recepção. Valores e informações no local.<br>
            Massagem: Dispomos de dois serviços terceirizados de massagem, um na recepção, próximo ao
            restaurante que oferece massagem com equipamentos e outro na entrada principal do clube,
            oferecendo massagistas especializados em técnicas orientais. Valores e informações no local.
            Semi joias: Oferecemos na recepção a M1 Joias, que oferece uma grande variedade de joias, semi
            joias e relógios entre outros artigos. Valores e informações no local.</p>
        </div>
      </div>

      <!-- <div class="col-lg-4 col-md-12">
          <div class="case-studies-sidebar-sticky">
            <div class="case-studies-details-info">
              <ul>
                <li *ngFor="let Info of caseStudiesDetailsInfo;">
                  <div class="icon">
                    <i class='{{Info.icon}}'></i>
                  </div>
                  <span>{{Info.title}}:</span>
                  {{Info.subTitle}}
                </li>
              </ul>
            </div>
          </div>
        </div> -->

    </div>
  </div>
</section>

<!-- <app-related-case-studies></app-related-case-studies> -->
