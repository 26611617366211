<app-form-reserva (eventIndisponibilidade)="returnEventIndisponibilidade($event)"
  (eventDisponibilidade)="returnEventDisponibilidade($event)"
  (eventOrganizaQuartos)="returnEventOrganizaQuartos($event)">
</app-form-reserva>

<app-indisponibilidade *ngIf="indisponiveis" [inDisponibilidade]="inDisponibilidade">
</app-indisponibilidade>

<div class="container" *ngIf="disponivel">
  <div class="row justify-content-center">
    <div class="row my-2">
      <div class="col-9">
        <app-disponibilidade></app-disponibilidade>
        <app-organiza-quartos></app-organiza-quartos>
      </div>
      <div class="col-3">
        <app-reserva-escolhida></app-reserva-escolhida>
      </div>
      <div class="col-12">
        <app-footer-empresa-gateway></app-footer-empresa-gateway>
      </div>
    </div>
  </div>
</div>

<app-nav-tabs *ngIf="!disponivel"></app-nav-tabs>
