import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import { TemaReservaV1Model } from '../model/tema-reserva-v1.model';
import { ExternalReferenceV2Model } from '../model/external-referece-v2.model';

@Component({
  selector: 'app-dados-reserva',
  templateUrl: './dados-reserva.component.html',
  styleUrls: ['./dados-reserva.component.scss']
})
export class DadosReservaComponent implements OnInit {

  /** Variaveis de entrada */
  @Input() isReserva!: boolean;
  @Input() temaReserva!: TemaReservaV1Model;
  @Input() externalReference!: ExternalReferenceV2Model;

  /** variaveis de ambiente */
  quantDiarias!: number;

  constructor() { }

  ngOnInit(): void { }

  telefone(rawNum: string) {
    rawNum = rawNum.replace(/[^a-zA-Z0-9]/g, '');
    if (rawNum.length == 10) {
      const areaCode = rawNum.slice(0, 2);
      const oneTre = rawNum.slice(2, 6);
      const toTre = rawNum.slice(6, 10);
      return `(${areaCode}) ${oneTre}-${toTre}`;
    } else {
      const areaCode = rawNum.slice(0, 2);
      const oneTre = rawNum.slice(2, 5);
      const toTre = rawNum.slice(5, 8);
      const treTre = rawNum.slice(8, 11);
      return `(${areaCode}) ${oneTre}-${toTre}-${treTre}`;
    }
  }

  regime(op: number) {
    let texto: string;
    switch (op) {
      default:
        texto = 'Estadia';
        break;
      case 1:
        texto = 'Café da manhã';
        break;
      case 2:
        texto = 'Meia pensão';
        break;
      case 3:
        texto = 'Pensão completa';
        break;
      case 4:
        texto = 'Café e jantar';
        break;
    }
    return texto;
  }

  tpapto(op: string) {
    let texto: string;
    switch (op) {
      default:
        texto = 'EXTRA/ADM';
        break;
      case "01":
        texto = 'TRIPLO';
        break;
      case "02":
        texto = 'QUADRUPLO';
        break;
      case "03":
        texto = 'QUINTUPLO';
        break;
      case "04":
        texto = 'TRAVEL';
        break;
    }
    return texto;
  }

  tptarifa(op: string) {
    let texto: string;
    switch (op) {
      default:
        texto = 'Altíssima';
        break;
      case "01":
        texto = 'Alta';
        break;
      case "02":
        texto = 'Média';
        break;
      case "03":
        texto = 'Baixa';
        break;
      case "04":
        texto = 'Baixíssima';
        break;
    }
    return texto;
  }

  diarias(tarifas: any) {
    let dias = moment(tarifas.saida, "YYYY-MM-DD").diff(moment(tarifas.entrada, "YYYY-MM-DD"));
    this.quantDiarias = moment.duration(dias).asDays()
    return this.quantDiarias
  }

  formatValue(price: number) {
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(price);
  }

}
