import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-contato-page',
  templateUrl: './contato-page.component.html',
  styleUrls: ['./contato-page.component.scss'],
})
export class ContatoPageComponent implements OnInit {
  @Input() public isFaleConoscoTabElement: Boolean = false;

  constructor() {}

  ngOnInit(): void {}
  pageTitleArea: pageTitle[] = [
    {
      title: 'Entre em contato!',
    },
  ];
  contactInfoBox1: InfoBox1[] = [
    {
      icon: 'bx bx-map',
      title: 'Nosso endereço',
      location: 'Avenida Orcalino Santos, 219, Centro, Caldas Novas - Goiás',
    },
  ];
  contactInfoBox2: InfoBox2[] = [
    {
      icon: 'bx bx-phone-call',
      title: 'Fale conosco (whatsapp)',
      number: '(64) 3453--1515',
      email: 'ctc@hotelctc.com.br',
    },
  ];
  contactInfoBox3: InfoBox2[] = [
    {
      icon: 'bx bxs-inbox',
      title: 'Reservas',
      number: '(64) 3453--1515',
      email: 'reservas@hotelctc.com.br',
    },
  ];

  sectionTitle: sectionTitleContent[] = [
    {
      subTitle: 'Fale conosco',
      title: 'Estamos a sua disposição!',
      paragraphText:
        'Fale conosco, tire suas dúvidas ou envie-nos suas sugestões!',
    },
  ];
  contactImage: Image[] = [
    {
      img: 'assets/img/contact.png',
    },
  ];
}
class pageTitle {
  title: string;
}
class InfoBox1 {
  icon: string;
  title: string;
  location: string;
}
class InfoBox2 {
  icon: string;
  title: string;
  number: string;
  email: string;
}
class InfoBox3 {
  icon: string;
  title: string;
  text1: string;
  text2: string;
}

class sectionTitleContent {
  subTitle: string;
  title: string;
  paragraphText: string;
}
class Image {
  img: string;
}
