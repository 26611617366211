import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DadosReservaEscolhidaService {

  /** Variavel de ambiente */
  public reload: boolean = false;

  /** Ações de emitir o eventos */
  public detalhesReservaEscolhida = new EventEmitter<any>();
  public hospedesReservaEscolhida = new EventEmitter<any>();

  /**
   * Funções pública de acionamento do eventos
   * */

  public isDetalhesReservaEscolhida(
    detalhesReserva: any
  ) {
    this.setDetalhesReservaEscolhida(detalhesReserva);
  }

  public isHospedesReservaEscolhida(
    hospedesReserva: any
  ) {
    this.setHospedesReservaEscolhida(hospedesReserva);
  }

  /**
   * Setando o evento contendo dados do quarto simples EventEmitter()
   * */
  setDetalhesReservaEscolhida(
    detalhesReserva: any
  ) {
    this.detalhesReservaEscolhida.emit(detalhesReserva);
  }

  setHospedesReservaEscolhida(
    hospedesReserva: any
  ) {
    this.hospedesReservaEscolhida.emit(hospedesReserva);
  }

}
