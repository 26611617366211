import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-metropolitan-servicos',
  templateUrl: './metropolitan-servicos.component.html',
})
export class MetropolitanServicosComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  pageTitleArea: pageTitle[] = [
    {
      title: 'Metropolitan Park',
      subTitle: 'Serviços',
    },
  ];
  caseStudiesDetailsDesc: DetailsDesc[] = [
    {
      title: 'Crepes e Churros',
      paragraph:
        'Localizado ao lado do parque infantil Petit Paris, oferece crepes e churros em diversos sabores. Informações no local.',
      img: 'assets/img/metropolitan/churros-e-crepes.png',
    },
    {
      title: 'Loja de Souvenirs',
      paragraph:
        'Bóias, roupas de banho, brinquedos, suvenirs... Tudo ao lado da entrada do clube. Informações no local.',
      // img: 'assets/img/metropolitan/loja-souvenirs.png'
      img: 'assets/img/metropolitan/boutique.jpg',
    },
    {
      title: 'Spa Persona estética ',
      paragraph:
        'Localizado na entrada do clube, oferece várias terapias e tratamentos estéticos e de saúde. Disponível também nas piscinas do hotel e no acesso às piscinas com Quick Massagem. Informações nos locais.',
      img: 'assets/img/metropolitan/servicos-spa-persona.png',
    },
  ];
  caseStudiesDetailsDescImportantFacts: ImportantFacts[] = [
    {
      title: 'Piscinas',
    },
    {
      title: 'Saúna',
    },
    {
      title: 'Auditório',
    },
    {
      title: 'Ofurôs',
    },
    {
      title: 'Toboáguas',
    },
    {
      title: 'Home Office',
    },
  ];
  caseStudiesDetailsInfo: Info[] = [
    {
      icon: 'bx bx-user-pin',
      title: 'Reservas',
      subTitle: 'form reservas online',
    },
  ];
}
class pageTitle {
  title: string;
  subTitle: string;
}
class DetailsDesc {
  title: string;
  paragraph: string;
  img: string;
}
class ImportantFacts {
  title: string;
}
class Info {
  icon: string;
  title: string;
  subTitle: string;
}
