<section class="margin-top-area">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12">
        <div class="alert alert-success" role="alert">
          Escolha entre uma das opções abaixo ou aceite nossa sugestão de <strong>"MELHOR OFERTA"</strong>.
        </div>
      </div>
      <div class="col-4 mb-3">
        <div class="card mcursos" [ngClass]="{bgcard: opcaoescolhida == 1}" (click)="opSelecionada(1)">
          <div class="card-body d-flex flex-column justify-content-between position-relative z-index-2">
            <div class="justify-content-between">
              <img class="shapetop" src="../../../assets/imgs/star.png" width="54" height="54" alt=""
                *ngIf="opcaoescolhida == 1">
              <img class="shapetop" src="../../../assets/imgs/rocket.png" width="54" height="54" alt=""
                *ngIf="opcaoescolhida != 1">
              <div class="align-items-center">
                <h5 class="mb-0"><strong>Diária com café</strong></h5>
                <small class="text-secondary fsize">(Café da manhã)</small>
              </div>
              <p class="valores fs--1 text-700 text-black mt-3">à vista: <strong><span class="fs-5">{{ formatter(totalsempensao)
                    }}</span></strong>
                <br />ou em até <strong><span class="fs-5">{{ quantParcela_CM }}</span></strong> x de
                <strong><span class="fs-5">{{ formatter(parcela_CM) }}</span></strong>
                <br /><small class="text-secondary fsize">valor médio da diária:<strong> {{
                    formatter(mediaDiariaCM) }}</strong></small>
              </p>
            </div>
            <div class="shapeleft"><img src="../../../assets/imgs/3.png" alt="image"></div>
            <div class="shaperight"><img src="../../../assets/imgs/circle-shape1.png" class="tm" alt="image">
            </div>
          </div>
        </div>
      </div>
      <div class="col-4 mb-3">
        <div class="card mcursos" [ngClass]="{bgcard: opcaoescolhida == 2}" (click)="opSelecionada(2)">
          <div class="card-body d-flex flex-column justify-content-between position-relative z-index-2">
            <div class="justify-content-between">
              <img class="shapetop" src="../../../assets/imgs/star.png" width="54" height="54" alt="" *ngIf="opcaoescolhida == 2">
              <img class="shapetop" src="../../../assets/imgs/bag-2.png" width="54" height="54" alt="" *ngIf="opcaoescolhida != 2">
              <div class="align-items-center">
                <h5 class="mb-0"><strong>Meia pensão</strong></h5>
                <small class="text-secondary fsize">(Café da manhã e almoço)</small>
              </div>
              <p class="valores fs--1 text-700 text-black mt-3">à vista: <strong><span class="fs-5">{{ formatter(totalmeiapensao)
                    }}</span></strong>
                <br />ou em até <strong><span class="fs-5">{{ quantParcela_MP }}</span></strong> x de
                <strong><span class="fs-5">{{ formatter(parcela_MP) }}</span></strong>
                <br /><small class="text-secondary fsize">valor médio da diária: <strong>{{
                    formatter(mediaDiariaMP) }}</strong></small>
              </p>
            </div>
            <div class="shapeleft"><img src="../../../assets/imgs/2.png" alt="image"></div>
            <div class="shaperight"><img src="../../../assets/imgs/circle-shape1.png" class="tm" alt="image">
            </div>
          </div>
        </div>
      </div>
      <div class="col-4 mb-3">
        <div class="card mcursos position-relative" [ngClass]="{bgcard: opcaoescolhida == 3}" (click)="opSelecionada(3)">
          <div class="card-body d-flex flex-column justify-content-between position-relative z-index-2">
            <div class="justify-content-between">
              <img class="shapetop" src="../../../assets/imgs/star.png" width="54" height="54" alt="" *ngIf="opcaoescolhida == 3">
              <img class="shapetop" src="../../../assets/imgs/shield-2.png" width="54" height="54" alt="" *ngIf="opcaoescolhida != 3">
              <div class="align-items-center">
                <h5 class="mb-0"><strong>Pensão completa</strong></h5>
                <small class="text-secondary fsize">(Café da manhã, almoço e jantar)</small>
              </div>
              <p class="valores fs--1 text-700 text-black mt-3">à vista: <strong><span class="fs-5">{{
                    formatter(totalpensaocompleta) }}</span></strong>
                <br />ou em até <strong><span class="fs-5">{{ quantParcela_PC }}</span></strong> x de
                <strong><span class="fs-5">{{ formatter(parcela_PC) }}</span></strong>
                <br /><small class="text-secondary fsize">valor médio da diária: <strong>{{
                    formatter(mediaDiariaPC) }}</strong></small>
              </p>
            </div>
            <div class="shapeleft"><img src="../../../assets/imgs/1.png" alt="image"></div>
            <div class="shaperight"><img src="../../../assets/imgs/circle-shape1.png" class="tm" alt="image">
            </div>
          </div>
          <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-success">
            MELHOR OFERTA
          </span>
        </div>
      </div>

      <!-- alert quando acima de 1 quarto -->
      <div class="col-12" *ngIf="quartos > 1">
        <div class="alert alert-warning" role="alert">
          A sua opção de reserva e para mais de <strong>um quarto</strong>, abaixo está a disposição
          dos quartos.
        </div>
      </div>

      <!-- Alerta quando tem criança(s) abaixo de 08 anos -->
      <div class="col-12" *ngIf="criancas != 0">
        <div class="alert alert-info" role="alert">
          Criança(s) abaixo de <strong>08 anos</strong> não tem custo de reserva.
        </div>
      </div>

    </div>
  </div>
</section>
