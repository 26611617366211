<section class="boxes-area pt-70 pb-70">
  <div class="container">
    <div class="row">
      <div class="col-lg-4 col-md-6 col-sm-6" *ngFor="let BoxItem of singleBoxesItem;">
        <div class="single-boxes-item">
          <div class="icon">
            <img [src]="BoxItem.icon" alt="image">
          </div>
          <!-- <h3><a routerLink="/{{BoxItem.link}}">{{BoxItem.title}}</a></h3> -->
          <p style="height: 183px !important;">{{BoxItem.paragraphText}}</p>
          <ng-container *ngIf="BoxItem.link">
            <a href="{{BoxItem.link}}" class="default-btn" target="_blank"><i
                class="{{BoxItem.btnIcon}}"></i>{{BoxItem.btnText}}<span></span></a>
          </ng-container>
          <ng-container *ngIf="!BoxItem.link">
            <a class="default-btn btn btn-secondary disabled" disabled aria-disabled="true"><i
                class="{{BoxItem.btnIcon}}"></i>{{BoxItem.btnText}}<span></span></a>
          </ng-container>

          <div class="shape1"><img src="{{BoxItem.img}}" alt="image"></div>
        </div>
      </div>
    </div>
  </div>
</section>
