<hr class="mt-3">
<div class="container text-center">
  <div class="row justify-content-md-center">
    <div class="col col-lg-2">
      <div id="cpf" class="form-text">Potal seguro</div>
      <a href="https://www.ssllabs.com/ssltest/analyze.html?d=hotelctc.com.br&latest" class="link-underline-dark" target="_blank">
        <img class="img" src="../../../assets/imgs/certificado.png" alt="">
      </a>
    </div>
    <div class="col-md-auto">
      <div id="cpf" class="form-text">Portal hospedado</div>
      <a href="https://www.locaweb.com.br/sobre-locaweb/" class="link-underline-dark" target="_blank">
        <img style="height: 1.5rem !important;" src="../../../assets/imgs/locaweb.png" alt="">
      </a>
    </div>
    <div class="col col-lg-2">
      <div id="cpf" class="form-text">Gestão de cobranças</div>
      <a href="https://www.asaas.com/sobre-nos" class="link-underline-dark" target="_blank">
        <img class="img" src="../../../assets/imgs/asaas.png" alt="">
      </a>
    </div>
  </div>
</div>
