import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { EPIGRAFO_API } from "src/app/share/api";
import { ResultV1Model } from "src/app/share/models/result-v1.model";
import { UtilsService } from "src/app/share/utils.service";
import { NewPaymentAsaasV2Model } from "../model/new-payment-asaas-v2.model";
import { NewPaymentOnlineTemaAsaasV2Model, NewReservaOnlineTemaV2Model } from "../model/new-payment-online-tema-asaas-v2.model";
import { LancaAntecipaModel } from "../model/lanca-antecipa.model";
import { AsaasUnicoExtRefV2Model } from "../model/asaas-unico-ext-ref-v2.model";
import { Etapa01NewPaymentOnlineTemaAsaasV2Model } from "../model/etapa01-new-payment-online-tema-asaas-v2.model";
import { NewCustomersAsaasV2Model } from "../model/new-customers-asaas-v2.model";

@Injectable({
  providedIn: 'root'
})
export class PostPaymentV1Service {
  constructor(
    private http: HttpClient,
    private readonly utilsService: UtilsService
  ) { }

  postPaymentEtapa01(
    payment: Etapa01NewPaymentOnlineTemaAsaasV2Model
  ) {
    return this.http.post<ResultV1Model>(
      `${EPIGRAFO_API}/v2/payment/online/etapa01`,
      payment,
      { headers: this.utilsService.composeHeader() }
    );
  }

  postPaymentEtapa02(
    newCustomers: NewCustomersAsaasV2Model
  ) {
    return this.http.post<ResultV1Model>(
      `${EPIGRAFO_API}/v2/payment/online/etapa02`,
      newCustomers,
      { headers: this.utilsService.composeHeader() }
    );
  }

  postPaymentFinal(
    payment: NewPaymentOnlineTemaAsaasV2Model
  ) {
    return this.http.post<ResultV1Model>(
      `${EPIGRAFO_API}/v2/payment/online/final`,
      payment,
      { headers: this.utilsService.composeHeader() }
    );
  }

  postNewReservaTema(
    newReserva: NewReservaOnlineTemaV2Model
  ) {
    return this.http.post<ResultV1Model>(
      `${EPIGRAFO_API}/v2/gateway/tema/online/newreserva`,
      newReserva,
      { headers: this.utilsService.composeHeader() }
    );
  }

  postPayment(
    payment: NewPaymentAsaasV2Model
  ) {
    return this.http.post<ResultV1Model>(
      `${EPIGRAFO_API}/v2/gateway/payment/cartao/individual`,
      payment,
      { headers: this.utilsService.composeHeader() }
    );
  }

  postPaymentOnline(
    payment: NewPaymentOnlineTemaAsaasV2Model
  ) {
    return this.http.post<ResultV1Model>(
      `${EPIGRAFO_API}/v2/payment/online`,
      payment,
      { headers: this.utilsService.composeHeader() }
    );
  }

  updateAntecipaTema(
    lancaAntecipaTema: LancaAntecipaModel
  ) {
    return this.http.post<any>(
      `${EPIGRAFO_API}/v2/gateway/tema/lancaantecipa`,
      lancaAntecipaTema,
      { headers: this.utilsService.composeHeader() }
    )
  }

  updateExtRef(
    extRef: string,
    assaUnico: AsaasUnicoExtRefV2Model
  ) {
    return this.http.post<any>(
      `${EPIGRAFO_API}/v2/gateway/extref/${extRef}`,
      assaUnico,
      { headers: this.utilsService.composeHeader() }
    )
  }
}
