import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-metropolitan-alimentacao',
  templateUrl: './metropolitan-alimentacao.component.html',
})
export class MetropolitanAlimentacaoComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  pageTitleArea: pageTitle = {
    title: 'Metropolitan Park',
    subTitle: 'Alimentação',
  };

  caseStudiesDetailsDesc: DetailsDesc[] = [
    {
      title: 'Restaurante Bon Gourmet',
      paragraph:
        'O Bon Gourmet é o restaurante oficial do Metropolitan Park, localizado logo na entrada do clube. Oferece diariamente em um espaço amplo e arejado, saboroso café da manhã das 7h às 9h30 e no almoço, completo buffet incluindo sucos e sobremesas a valores convidativos e justos, então pra que abrir mão do seu conforto, buscando alimentação fora do clube?',
      img: 'assets/img/metropolitan/alimentacao-restaurante-bom-gourmet.png',
    },
    {
      title: 'Bar Piscina',
      paragraph:
        'Localizado na piscina de pedra, funciona das 09h às 20h servindo bebidas, petiscos, salgados, e sorvetes.',
      img: 'assets/img/metropolitan/bar-piscina.png',
    },
    // {
    //   title: 'Bar Butekos',
    //   paragraph: 'Localizado na piscina das rampas, funciona das 10h às 18h servindo bebidas, petiscos, salgados, e sorvetes.',
    //   img: ''
    // },
    {
      title: 'Café Paris',
      paragraph:
        'Localizado no parque infantil Petit Paris, servindo petiscos, lanches, salgados e bebidas.',
      img: 'assets/img/metropolitan/alimentacao-cafe-paris.png',
    },
  ];
  caseStudiesDetailsDescImportantFacts: ImportantFacts[] = [
    {
      title: 'Piscinas',
    },
    {
      title: 'Saúna',
    },
    {
      title: 'Auditório',
    },
    {
      title: 'Ofurôs',
    },
    {
      title: 'Toboáguas',
    },
    {
      title: 'Home Office',
    },
  ];
  caseStudiesDetailsInfo: Info[] = [
    {
      icon: 'bx bx-user-pin',
      title: 'Reservas',
      subTitle: 'form reservas online',
    },
  ];
}
class pageTitle {
  title: string;
  subTitle: string;
}
class DetailsDesc {
  title: string;
  paragraph: string;
  img: string;
}
class ImportantFacts {
  title: string;
}
class Info {
  icon: string;
  title: string;
  subTitle: string;
}
