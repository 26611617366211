import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

/** Hotel-CTC */
import { defineLocale } from 'ngx-bootstrap/chronos';
import { ptBrLocale } from 'ngx-bootstrap/locale';
defineLocale('pt-br', ptBrLocale);
import { BsDatepickerModule, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';

import { AppRoutingModule } from './app.routing';
import { AppComponent } from './app.component';
import { GalleryPageComponent } from './components/pages/gallery-page/gallery-page.component';
import { EventsPageComponent } from './components/pages/events-page/events-page.component';
import { ErrorPageComponent } from './components/pages/error-page/error-page.component';
import { CaseStudiesThreeColumnsPageComponent } from './components/pages/case-studies-three-columns-page/case-studies-three-columns-page.component';
import { FooterComponent } from './components/layouts/footer/footer.component';
import { PreloaderComponent } from './components/layouts/preloader/preloader.component';
import { HomepageOneComponent } from './components/pages/homepage-one/homepage-one.component';
import { SolutionsComponent } from './components/common/solutions/solutions.component';
import { HomeoneServicesComponent } from './components/pages/homepage-one/homeone-services/homeone-services.component';
import { HomeoneProcessComponent } from './components/pages/homepage-one/homeone-process/homeone-process.component';
import { HomeoneCaseStudiesComponent } from './components/pages/homepage-one/homeone-case-studies/homeone-case-studies.component';
import { HomeoneScientistComponent } from './components/pages/homepage-one/homeone-scientist/homeone-scientist.component';
import { HomeoneTestimonialsComponent } from './components/pages/homepage-one/homeone-testimonials/homeone-testimonials.component';
import { HomeonePartnerComponent } from './components/pages/homepage-one/homeone-partner/homeone-partner.component';
import { HomeoneBlogComponent } from './components/pages/homepage-one/homeone-blog/homeone-blog.component';
import { ProjectStartComponent } from './components/common/project-start/project-start.component';
import { HomeoneAboutComponent } from './components/pages/homepage-one/homeone-about/homeone-about.component';
import { HomeoneBannerComponent } from './components/pages/homepage-one/homeone-banner/homeone-banner.component';
import { HomepageTwoComponent } from './components/pages/homepage-two/homepage-two.component';
import { HometwoPartnerComponent } from './components/pages/homepage-two/hometwo-partner/hometwo-partner.component';
import { HometwoTestimonialsComponent } from './components/pages/homepage-two/hometwo-testimonials/hometwo-testimonials.component';
import { HometwoScientistComponent } from './components/pages/homepage-two/hometwo-scientist/hometwo-scientist.component';
import { HometwoProcessComponent } from './components/pages/homepage-two/hometwo-process/hometwo-process.component';
import { HometwoServicesComponent } from './components/pages/homepage-two/hometwo-services/hometwo-services.component';
import { HometwoFunfactsComponent } from './components/pages/homepage-two/hometwo-funfacts/hometwo-funfacts.component';
import { HometwoMissionComponent } from './components/pages/homepage-two/hometwo-mission/hometwo-mission.component';
import { HometwoAboutComponent } from './components/pages/homepage-two/hometwo-about/hometwo-about.component';
import { HometwoBannerComponent } from './components/pages/homepage-two/hometwo-banner/hometwo-banner.component';
import { HometwoBoxesComponent } from './components/pages/homepage-two/hometwo-boxes/hometwo-boxes.component';
import { HomethreeBannerComponent } from './components/pages/homepage-three/homethree-banner/homethree-banner.component';
import { HomepageThreeComponent } from './components/pages/homepage-three/homepage-three.component';
import { HomethreePartnerComponent } from './components/pages/homepage-three/homethree-partner/homethree-partner.component';
import { HomethreeFeaturedServicesComponent } from './components/pages/homepage-three/homethree-featured-services/homethree-featured-services.component';
import { HomethreeAboutComponent } from './components/pages/homepage-three/homethree-about/homethree-about.component';
import { HomethreeMissionComponent } from './components/pages/homepage-three/homethree-mission/homethree-mission.component';
import { HomethreeFunfactsComponent } from './components/pages/homepage-three/homethree-funfacts/homethree-funfacts.component';
import { HomethreeCoursesComponent } from './components/pages/homepage-three/homethree-courses/homethree-courses.component';
import { HomethreeExploreLearningComponent } from './components/pages/homepage-three/homethree-explore-learning/homethree-explore-learning.component';
import { HomethreeBootcampsComponent } from './components/pages/homepage-three/homethree-bootcamps/homethree-bootcamps.component';
import { HomethreeTestimonialsComponent } from './components/pages/homepage-three/homethree-testimonials/homethree-testimonials.component';
import { HomethreeBlogComponent } from './components/pages/homepage-three/homethree-blog/homethree-blog.component';
import { SubscribeComponent } from './components/common/subscribe/subscribe.component';
import { HomepageFourComponent } from './components/pages/homepage-four/homepage-four.component';
import { HomefourBlogComponent } from './components/pages/homepage-four/homefour-blog/homefour-blog.component';
import { HomefourTestimonialsComponent } from './components/pages/homepage-four/homefour-testimonials/homefour-testimonials.component';
import { HomefourIndustriesServeComponent } from './components/pages/homepage-four/homefour-industries-serve/homefour-industries-serve.component';
import { HomefourServicesComponent } from './components/pages/homepage-four/homefour-services/homefour-services.component';
import { HomefourFunfactsComponent } from './components/pages/homepage-four/homefour-funfacts/homefour-funfacts.component';
import { HomefourWhatWeDoComponent } from './components/pages/homepage-four/homefour-what-we-do/homefour-what-we-do.component';
import { HomefourWhyChooseUsComponent } from './components/pages/homepage-four/homefour-why-choose-us/homefour-why-choose-us.component';
import { HomefourFeaturedServicesComponent } from './components/pages/homepage-four/homefour-featured-services/homefour-featured-services.component';
import { HomefourBannerComponent } from './components/pages/homepage-four/homefour-banner/homefour-banner.component';
import { AboutPageComponent } from './components/pages/about-page/about-page.component';
import { AboutpageAboutComponent } from './components/pages/about-page/aboutpage-about/aboutpage-about.component';
import { AboutpageHistoryComponent } from './components/pages/about-page/aboutpage-history/aboutpage-history.component';
import { TeamPageComponent } from './components/pages/team-page/team-page.component';
import { ServicesPageComponent } from './components/pages/services-page/services-page.component';
import { ServicesDetailsPageComponent } from './components/pages/services-details-page/services-details-page.component';
import { ContactPageComponent } from './components/pages/contact-page/contact-page.component';
import { BlogPageComponent } from './components/pages/blog-page/blog-page.component';
import { BlogDetailsPageComponent } from './components/pages/blog-details-page/blog-details-page.component';
import { RelatedServicesComponent } from './components/pages/services-details-page/related-services/related-services.component';
import { CaseStudiesDetailsPageComponent } from './components/pages/case-studies-details-page/case-studies-details-page.component';
import { RelatedCaseStudiesComponent } from './components/pages/case-studies-details-page/related-case-studies/related-case-studies.component';
import { FaqPageComponent } from './components/pages/faq-page/faq-page.component';
import { EventsDetailsPageComponent } from './components/pages/events-details-page/events-details-page.component';
import { EventsSpeakersComponent } from './components/pages/events-details-page/events-speakers/events-speakers.component';
import { CoursesPageComponent } from './components/pages/courses-page/courses-page.component';
import { CoursesDetailsPageComponent } from './components/pages/courses-details-page/courses-details-page.component';
import { RelatedCoursesComponent } from './components/pages/courses-details-page/related-courses/related-courses.component';
import { ReservaOnlineComponent } from './reservas/reserva-online.component';
import { FormReservaComponent } from './reservas/form-reserva/form-reserva.component';
import { PagamentosComponent } from './pagamentos/pagamentos.component';
import { CustomValidator } from './share/custom.validators';
import { UtilsService } from './share/utils.service';
import { NavTabsComponent } from './reservas/nav-tabs/nav-tabs.component';
import { AtrativosComponent } from './reservas/nav-tabs/atrativos/atrativos.component';
import { DestaquesComponent } from './reservas/nav-tabs/destaques/destaques.component';
import { IndisponibilidadeComponent } from './reservas/indisponibilidade/indisponibilidade.component';
import { HomePageComponent } from './components/routes/home-page/home-page.component';
import { HomeBannerComponent } from './components/routes/home-page/home-banner/home-banner.component';
import { NavbarComponent } from './components/layouts/navbar/navbar.component';
import { SeguimentosComponent } from './components/routes/home-page/seguimentos/seguimentos.component';
import { HomeMuitaDiversaoComponent } from './components/routes/home-page/home-muita-diversao/home-muita-diversao.component';
import { HomeMaisDiversaoComponent } from './components/routes/home-page/home-mais-diversao/home-mais-diversao.component';
import { SobreHotelCtcComponent } from './components/routes/hotel-ctc/sobre-hotel-ctc/sobre-hotel-ctc.component';
import { GalleryHotelComponent } from './components/common/gallery-hotel/gallery-hotel.component';
import { InstalacoesAlimentacaoComponent } from './components/routes/instalacoes/instalacoes-alimentacao/instalacoes-alimentacao.component';
import { GalleryRestauranteComponent } from './components/common/gallery-restaurante/gallery-restaurante.component';
import { InstalacoesApartamentoComponent } from './components/routes/instalacoes/instalacoes-apartamento/instalacoes-apartamento.component';
import { GalleryQuartosComponent } from './components/common/gallery-quartos/gallery-quartos.component';
import { InstalacoesLazerComponent } from './components/routes/instalacoes/instalacoes-lazer/instalacoes-lazer.component';
import { InstalacoesServicosComponent } from './components/routes/instalacoes/instalacoes-servicos/instalacoes-servicos.component';
import { SobreAguasQuentesDeCaldasNovasComponent } from './components/routes/hotel-ctc/sobre-aguas-quentes-de-caldas-novas/sobre-aguas-quentes-de-caldas-novas.component';
import { PoliticasDeHospedagemComponent } from './components/routes/hotel-ctc/politicas-de-hospedagem/politicas-de-hospedagem.component';
import { ContatoPageComponent } from './components/routes/hotel-ctc/contato-page/contato-page.component';
import { MetropolitanAtracoesComponent } from './components/routes/hotel-ctc/metropolitan-park/metropolitan-atracoes/metropolitan-atracoes.component';
import { MetropolitanAlimentacaoComponent } from './components/routes/hotel-ctc/metropolitan-park/metropolitan-alimentacao/metropolitan-alimentacao.component';
import { MetropolitanServicosComponent } from './components/routes/hotel-ctc/metropolitan-park/metropolitan-servicos/metropolitan-servicos.component';
import { DisponibilidadeComponent } from './reservas/disponibilidade/disponibilidade.component';
import { ReservaEscolhidaComponent } from './reservas/reserva-escolhida/reserva-escolhida.component';
import { OrganizaQuartosComponent } from './reservas/organiza-quartos/organiza-quartos.component';
import { DadosResponsavelPagamentoComponent } from './pagamentos/dados-responsavel-pagamento/dados-responsavel-pagamento.component';
import { DadosPagamentoComponent } from './pagamentos/dados-pagamento/dados-pagamento.component';
import { DadosReservaComponent } from './pagamentos/dados-reserva/dados-reserva.component';
import { CepPipe } from './share/utils/cep.pipe';
import { CpfCnpjPipe } from './share/utils/cpf-cnpj.pipe';
import { FooterEmpresaGatewayComponent } from './footer-empresa-gateway/footer-empresa-gateway.component';
import { FormDisabledComponent } from './pagamento-online/form-disabled/form-disabled.component';
import { PagamentoOnlineComponent } from './pagamento-online/pagamento-online.component';
import { ReservaEscolhidaPaymentComponent } from './pagamento-online/reserva-escolhida-payment/reserva-escolhida-payment.component';
import { DadosResponsavelPagamentoOnlineComponent } from './pagamento-online/dados-responsavel-pagamento-online/dados-responsavel-pagamento-online.component';
import { DadosPagamentoOnlineComponent } from './pagamento-online/dados-pagamento-online/dados-pagamento-online.component';
import { ReservaConfirmadaComponent } from './pagamento-online/reserva-confirmada/reserva-confirmada.component';

@NgModule({
  declarations: [
    AppComponent,
    GalleryPageComponent,
    EventsPageComponent,
    ErrorPageComponent,
    CaseStudiesThreeColumnsPageComponent,
    FooterComponent,
    PreloaderComponent,
    HomepageOneComponent,
    SolutionsComponent,
    HomeoneServicesComponent,
    HomeoneProcessComponent,
    HomeoneCaseStudiesComponent,
    HomeoneScientistComponent,
    HomeoneTestimonialsComponent,
    HomeonePartnerComponent,
    HomeoneBlogComponent,
    ProjectStartComponent,
    HomeoneAboutComponent,
    HomeoneBannerComponent,
    HomepageTwoComponent,
    HometwoPartnerComponent,
    HometwoTestimonialsComponent,
    HometwoScientistComponent,
    HometwoProcessComponent,
    HometwoServicesComponent,
    HometwoFunfactsComponent,
    HometwoMissionComponent,
    HometwoAboutComponent,
    HometwoBannerComponent,
    HometwoBoxesComponent,
    HomethreeBannerComponent,
    HomepageThreeComponent,
    HomethreePartnerComponent,
    HomethreeFeaturedServicesComponent,
    HomethreeAboutComponent,
    HomethreeMissionComponent,
    HomethreeFunfactsComponent,
    HomethreeCoursesComponent,
    HomethreeExploreLearningComponent,
    HomethreeBootcampsComponent,
    HomethreeTestimonialsComponent,
    HomethreeBlogComponent,
    SubscribeComponent,
    HomepageFourComponent,
    HomefourBlogComponent,
    HomefourTestimonialsComponent,
    HomefourIndustriesServeComponent,
    HomefourServicesComponent,
    HomefourFunfactsComponent,
    HomefourWhatWeDoComponent,
    HomefourWhyChooseUsComponent,
    HomefourFeaturedServicesComponent,
    HomefourBannerComponent,
    AboutPageComponent,
    AboutpageAboutComponent,
    AboutpageHistoryComponent,
    TeamPageComponent,
    ServicesPageComponent,
    ServicesDetailsPageComponent,
    ContactPageComponent,
    BlogPageComponent,
    BlogDetailsPageComponent,
    RelatedServicesComponent,
    CaseStudiesDetailsPageComponent,
    RelatedCaseStudiesComponent,
    FaqPageComponent,
    EventsDetailsPageComponent,
    EventsSpeakersComponent,
    CoursesPageComponent,
    CoursesDetailsPageComponent,
    RelatedCoursesComponent,

    /** Hotel CTC */
    FooterEmpresaGatewayComponent,
    CpfCnpjPipe,
    CepPipe,
    /** reservas */
    ReservaOnlineComponent,
    ReservaEscolhidaComponent,
    FormReservaComponent,
    NavTabsComponent,
    AtrativosComponent,
    DestaquesComponent,
    IndisponibilidadeComponent,
    DisponibilidadeComponent,
    HomePageComponent,
    HomeBannerComponent,
    NavbarComponent,
    SeguimentosComponent,
    HomeMuitaDiversaoComponent,
    HomeMaisDiversaoComponent,
    SobreHotelCtcComponent,
    GalleryHotelComponent,
    InstalacoesAlimentacaoComponent,
    GalleryRestauranteComponent,
    InstalacoesApartamentoComponent,
    GalleryQuartosComponent,
    InstalacoesLazerComponent,
    InstalacoesServicosComponent,
    SobreAguasQuentesDeCaldasNovasComponent,
    PoliticasDeHospedagemComponent,
    ContatoPageComponent,
    MetropolitanAtracoesComponent,
    MetropolitanAlimentacaoComponent,
    MetropolitanServicosComponent,
    OrganizaQuartosComponent,
    /** pagamentos */
    PagamentosComponent,
    DadosPagamentoComponent,
    DadosReservaComponent,
    DadosResponsavelPagamentoComponent,
    PagamentoOnlineComponent,
    FormDisabledComponent,
    ReservaEscolhidaPaymentComponent,
    DadosResponsavelPagamentoOnlineComponent,
    DadosPagamentoOnlineComponent,
    ReservaConfirmadaComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    FontAwesomeModule,
    TabsModule.forRoot(),
    BsDatepickerModule.forRoot(),
    BsDropdownModule.forRoot(),
    NgxMaskDirective,
    NgxMaskPipe,
  ],
  exports: [BsDatepickerModule],
  providers: [provideNgxMask(), UtilsService, CustomValidator],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private localeService: BsLocaleService) {
    localeService.use('pt-br');
  }
}
