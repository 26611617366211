<section class="gallery-area pt-100 pb-70">
  <div class="container">
    <div class="row">
      <div class="col-lg-3 col-md-6 col-sm-6" *ngFor="let Image of galleryImages;">
        <div class="single-gallery-item">
          <a data-fancybox="gallery" href="{{ Image.img }}">
            <img [src]="Image.img" alt="image">
          </a>
        </div>
      </div>
    </div>
  </div>
</section>
