<div class="col-lg-12 text-center mt-4 margin-top-area">
  <h5 class="mb-4">Sua reserva está <strong>confirmada</strong>,<br />
    tenha à mão este voucher de confirmação
  </h5>
  <h5 class="mb-4">
    Código identificador da reserva é: <strong>{{ idReserva }}</strong>.
  </h5>

  <div style="display: flex; justify-content: center; margin:0 30px 10px 30px;">
    <div class="alert alert-danger" role="alert" style="padding:30px;">
      <p style="color:#181C32; font-size: 18px; font-weight: 700; margin-bottom:13px">
        ATENÇÃO!
      </p>
      <div style="display:flex">
        <div>
          <span style="padding-top:3px; margin:0;font-family:Arial,Helvetica,sans-serif">
            Será enviado um e-mail contendo a confirmação, detalhes da reserva,<br />
            localização e outras informações, você pode imprimir esta tela ou anotar o<br />
            código identificador da reserva "{{ idReserva }}", com este número você<br />
            terá todas informações necessárias sobre sua reserva. Obrigado!</span>
        </div>
        <div class="separator separator-dashed" style="margin:17px 0 15px 0"></div>
      </div>
    </div>
  </div>

  <div style="display: flex; justify-content: center; margin:0 30px 10px 30px;">
    <div class="alert alert-warning" role="alert" style="padding:30px;">
      <p style="color:#181C32; font-size: 18px; font-weight: 700; margin-bottom:13px">
        Detalhes da Reserva:
      </p>

      <!-- Data de entrada -->
      <div style="display:flex">
        <div>
          <span style="color:#5E6278; padding-top:3px; margin:0;font-family:Arial,Helvetica,sans-serif">
            Entrada:</span> <strong>{{ dadosReserva.dtInicio }}</strong>
        </div>
        <div class="separator separator-dashed" style="margin:17px 0 15px 0"></div>
      </div>

      <!-- Data de Saída -->
      <div style="display:flex">
        <div>
          <span style="color:#5E6278; padding-top:3px; margin:0;font-family:Arial,Helvetica,sans-serif">
            Saida:</span> <strong>{{ dadosReserva.dtFinal }}</strong>
        </div>
        <div class="separator separator-dashed" style="margin:17px 0 15px 0"></div>
      </div>

      <!-- Sua reserva -->
      <div style="display:flex">
        <div>
          <span style="color:#5E6278; padding-top:3px; margin:0;font-family:Arial,Helvetica,sans-serif">
            Sua reserva:</span> <strong>{{ dadosReserva.quantasDiarias }}</strong> diárias, <strong>{{ dadosReserva.quantQuartos }}</strong> quartos
        </div>
        <div class="separator separator-dashed" style="margin:17px 0 15px 0"></div>
      </div>

      <!-- Endereço -->
      <div style="display:flex">
        <div>
          <span style="color:#5E6278; padding-top:3px; margin:0;font-family:Arial,Helvetica,sans-serif">
            Localização:</span> <strong>Avenida Orcalino Santos, 219, Centro<br /> Caldas Novas - Goiás, CEP: 75680-013.</strong>
        </div>
        <div class="separator separator-dashed" style="margin:17px 0 15px 0"></div>
      </div>

      <!-- Contato telefone -->
      <div style="display:flex">
        <div>
          <span style="color:#5E6278; padding-top:3px; margin:0;font-family:Arial,Helvetica,sans-serif">
            Telefone:</span> <strong>+55 (64) 3453-1515 (whatsapp)</strong>
        </div>
        <div class="separator separator-dashed" style="margin:17px 0 15px 0"></div>
      </div>

      <!-- Contato e-mail -->
      <div style="display:flex">
        <div>
          <span style="color:#5E6278; padding-top:3px; margin:0;font-family:Arial,Helvetica,sans-serif">
            Contato:</span> <strong>reservas@hotelctc.com.br</strong>
        </div>
        <div class="separator separator-dashed" style="margin:17px 0 15px 0"></div>
      </div>

    </div>
  </div>

  <div style="display: flex; justify-content: center; margin:0 30px 10px 30px;">
    <div class="alert alert-light" role="alert" style="padding:30px;">
      <p style="color:#181C32; font-size: 18px; font-weight: 700; margin-bottom:13px">
        Comece a planejar, monte sua rota
      </p>
      <p style="color:#181C32; font-size: 18px; font-weight: 600; margin-bottom:13px">
        Localização Hotel CTC<br />
        Avenida Orcalino Santos, 219, Centro, Caldas Novas - Goiás, CEP: 75680-013.<br />
      </p>
      <p style="color:#181C32; font-size: 18px; font-weight: 600; margin-bottom:13px">
        Tem dúvidas de como chegar no Hotel CTC? <br />
        Clique na imagem do mapa e monte a melhora rota.
      </p>
      <a href='https://maps.google.com/maps/dir//Hotel+CTC+Av.+Orcalino+Santos,+219+-+Centro+Caldas+Novas+-+GO+75680-013/@-17.7416431,-48.6262386,13z/data=!4m5!4m4!1m0!1m2!1m1!1s0x94a736b24a447d11:0xe8f5b23b18583303'
        target="_blank">
        <img src="https://hotel-ctc-files.s3.sa-east-1.amazonaws.com/imagens/mapa-hctc.jpg" width="90%">
      </a>
    </div>
  </div>

  <div style="display: flex; justify-content: center; margin:0 30px 10px 30px;">
    <div class="alert alert-danger" role="alert" style="padding:30px;">
      <p style="color:#181C32; font-size: 18px; font-weight: 700; margin-bottom:13px">
        Recomendação para sua viagem
      </p>
      <div style="display:flex">
        <div>
          <span style="padding-top:3px; margin:0;font-family:Arial,Helvetica,sans-serif">
            Conforme ECA – Estatuto da Criança e do Adolescente – artigo 82:
            É proibida a hospedagem de criança ou adolescente em hotel, motel, pensão ou estabelecimento congênere,
            salvo se autorizado ou acompanhado pelos pais ou responsável. Dessa forma será exigida documentação de
            menores de idade para hospedagem.
            Para evitar aborrecimentos, atente para o horário de check in à partir das 12:00 (meio dia)</span>

          <p style="color:#181C32; font-size: 18px; font-weight: 600; margin-bottom:13px">
            Para mais informações, acesse:
            <a href="https://servicos.hotelctc.com.br" target="_blank">https://servicos.hotelctc.com.br</a>
          </p>
        </div>
        <div class="separator separator-dashed" style="margin:17px 0 15px 0"></div>
      </div>
    </div>
  </div>


  <div style="display: flex; justify-content: center; margin:0 30px 10px 30px;">
    <div class="alert alert-light" role="alert" style="padding:30px;">
      <a href="https://www.facebook.com/ctccaldas" target="_blank" style="margin-right:10px"><img alt="Logo" style="width: 36px;" src="https://hotel-ctc-files.s3.sa-east-1.amazonaws.com/imagens/icon-facebook.svg" /></a>
      <a href="https://instagram.com/ctc.caldasnovas" target="_blank" style="margin-right:10px"><img alt="Logo" style="width: 36px;" src="https://hotel-ctc-files.s3.sa-east-1.amazonaws.com/imagens/icon-instagram.svg" /></a>
      <a href="https://www.youtube.com/@hotelctccn" target="_blank" style="margin-right:10px"><img alt="Logo" style="width: 36px;" src="https://hotel-ctc-files.s3.sa-east-1.amazonaws.com/imagens/icon-youtube.svg" /></a>
    </div>
  </div>

</div>
