import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-politicas-de-hospedagem',
  templateUrl: './politicas-de-hospedagem.component.html',
  styleUrls: ['./politicas-de-hospedagem.component.scss'],
})
export class PoliticasDeHospedagemComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  pageTitleArea: pageTitle[] = [
    {
      title: 'Políticas de Hospedagens',
      subTitle: 'VOLTAGEM NO HOTEL: 220 VOLTS.',
    },
  ];
  caseStudiesDetailsDesc: DetailsDesc[] = [
    {
      subTitle: 'Políticas de Hospedagens',
      title1: 'VOLTAGEM NO HOTEL: 220 VOLTS.',
      paragraph1:
        'Aqui, as águas termais se forma à partir da água da chuva que infiltra no chão onde são aquecidas devido ao calor natural do interior do planeta. Aos poucos, devido à geologia da região, as águas alcançam grandes profundidades em direção ao centro da terra que como todos sabemos é extremamente quente, irradiando seu calor por milhares de quilômetros. Conforme aquecem, vão ganhando pressão até brotarem naturalmente na superfície.',
      paragraph2:
        'E essas águas, trazem consigo características muito benéficas para saúde, que vão além do conforto e relaxamento que um banho quente proporciona. Ela é capaz de aliviar dores no corpo, melhorar a circulação, repor sais minerais essenciais como como ferro, cálcio e zinco, regular a pressão arterial, combater o stress, enxaqueca entre muitos outros benefícios.',
      paragraph3:
        'Mas fique atento, não é assim em todo lugar, aqui no CTC não aquecemos artificialmente nossas piscinas! Possuímos 3 poços termais que garantem água mineral pura, podendo inclusive ser ingerida, de preferência após refrigeração.',
    },
  ];
  caseStudiesDetailsInfo: Info[] = [
    {
      icon: 'bx bx-user-pin',
      title: 'Reservas',
      subTitle: 'form reservas online',
    },
  ];
}
class pageTitle {
  title: string;
  subTitle: string;
}
class Image {
  img: string;
}
class DetailsDesc {
  subTitle: string;
  title1: string;
  paragraph1: string;
  paragraph2: string;
  paragraph3: string;
}
class ImportantFacts {
  title: string;
}
class Info {
  icon: string;
  title: string;
  subTitle: string;
}
