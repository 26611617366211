import { Component, Input, OnInit } from '@angular/core';
import { DisponibilidadeMapadiarioV1 } from '../models/disponibilidade-mapadiario-v1.model';

@Component({
  selector: 'app-indisponibilidade',
  templateUrl: './indisponibilidade.component.html',
  styleUrls: ['./indisponibilidade.component.scss']
})
export class IndisponibilidadeComponent implements OnInit {

  @Input() inDisponibilidade: DisponibilidadeMapadiarioV1[] = [];

  constructor(
  ) {}

  ngOnInit(): void {
  }
}
