import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BsModalRef, ModalDirective } from 'ngx-bootstrap/modal';
import { OrgabinzaQuartosModel } from '../models/organiza-quartos.model';
import { DropdrownReservaModel } from '../models/dropdown-reserva.model';
import { CalculaReservaV1Service } from '../events/calcula-reserva-v1.service';
import { DadosOrganizaQuartosService } from '../events/dados-organiza-quartos.service';
import { DadosReservaEscolhidaService } from '../events/dados-reserva-escolhida.service';
import { UtilsService } from 'src/app/share/utils.service';

@Component({
  selector: 'app-organiza-quartos',
  templateUrl: './organiza-quartos.component.html',
  styleUrls: ['./organiza-quartos.component.scss']
})
export class OrganizaQuartosComponent implements OnInit {

  /**
    * variáveis do component
    */
  form!: FormGroup;
  submitted = false;
  formErrors: any;

  /**
   * Variaveis de ambiente
   */
  btnNewQuartos: boolean = false;
  btnResetQuartos: boolean = true;
  quartos: number = 1;
  tquartos: number = 1;
  adultos: number = 1;
  tadultos: number = 0;
  jovens: number = 0;
  tjovens: number = 0;
  criancas: number = 0;
  tcriancas: number = 0;
  adultosquarto01: number = 0;
  jovensquarto01: number = 0;
  criancasquarto01: number = 0;
  adultosquarto02: number = 0;
  jovensquarto02: number = 0;
  criancasquarto02: number = 0;
  adultosquarto03: number = 0;
  jovensquarto03: number = 0;
  criancasquarto03: number = 0;
  adultosquarto04: number = 0;
  jovensquarto04: number = 0;
  criancasquarto04: number = 0;
  adultosquarto05: number = 0;
  jovensquarto05: number = 0;
  criancasquarto05: number = 0;
  quartoSimples!: OrgabinzaQuartosModel;

  diariaQuarto01: number = 0;
  totaldiariaquarto01_CM: number = 0;
  totaldiariaquarto01_MP: number = 0;
  totaldiariaquarto01_PC: number = 0;

  diariaQuarto02: number = 0;
  totaldiariaquarto02_CM: number = 0;
  totaldiariaquarto02_MP: number = 0;
  totaldiariaquarto02_PC: number = 0;

  diariaQuarto03: number = 0;
  totaldiariaquarto03_CM: number = 0;
  totaldiariaquarto03_MP: number = 0;
  totaldiariaquarto03_PC: number = 0;

  diariaQuarto04: number = 0;
  totaldiariaquarto04_CM: number = 0;
  totaldiariaquarto04_MP: number = 0;
  totaldiariaquarto04_PC: number = 0;

  diariaQuarto05: number = 0;
  totaldiariaquarto05_CM: number = 0;
  totaldiariaquarto05_MP: number = 0;
  totaldiariaquarto05_PC: number = 0;

  listaAdultos?: DropdrownReservaModel[];
  quantAdultoT: string = "2 Adultos";
  // jovens: number = 0;
  listaJovens?: DropdrownReservaModel[];
  quantJovensT: string = "0 Jovens";
  // criancas: number = 0;
  listaCriancas?: DropdrownReservaModel[];
  quantCriancaT: string = '0 Crianças';
  disabledJoven: boolean = false;
  disabledCrianca: boolean = false;
  mediaDiariaCM: number = 0;
  mediaDiariaMP: number = 0;
  mediaDiariaPC: number = 0;

  nquarto: number = 0;
  nadultos: number = 0;
  njovens: number = 0;
  ncriancas: number = 0;
  q01adulto: number = 0;
  q01joven: number = 0;
  q01crianca: number = 0;
  nq02: boolean = false;
  q02adulto: number = 0;
  q02joven: number = 0;
  q02crianca: number = 0;
  nq03: boolean = false;
  q03adulto: number = 0;
  q03joven: number = 0;
  q03crianca: number = 0;
  nq04: boolean = false;
  q04adulto: number = 0;
  q04joven: number = 0;
  q04crianca: number = 0;
  nq05: boolean = false;
  q05adulto: number = 0;
  q05joven: number = 0;
  q05crianca: number = 0;

  /**
   * variaveis modal
   */
  modalRef?: BsModalRef;
  configmd = {
    keyboard: true,
    ignoreBackdropClick: true,
    class: 'modal-dialog-centered modal-md'
  };
  @ViewChild('childModal', { static: false }) childModal?: ModalDirective;
  @ViewChild('menosquartosModal', { static: false }) menosquartosModal?: ModalDirective;

  hideChildModal(index: number): void {
    this.resetQuartos(index);
    this.childModal?.hide();
  }

  showMenosQuartos(index: number): void {
    // this.nquarto = index;
    // this.montaQuarto(index);
    this.menosquartosModal?.show();
  }

  hideMenosQuartos(): void {
    this.menosquartosModal?.hide();
  }

  constructor(
    private fb: FormBuilder,
    private readonly utilsService: UtilsService,
    public calculaReservaV1Service: CalculaReservaV1Service,
    private readonly dadosOrganizaQuartosService: DadosOrganizaQuartosService,
    private readonly dadosReservaEscolhidaService: DadosReservaEscolhidaService
  ) {
    /**
     * atualiza os dados obtidos no processo de pesquisa de disponibilidade
     */
    this.dadosOrganizaQuartosService.organizaSimples
      .subscribe(quartoSimples => {
        this.quartoSimples = quartoSimples;
        this.adultos = quartoSimples.adultos;
        this.jovens = quartoSimples.jovens;
        this.criancas = quartoSimples.criancas;
        this.tadultos = quartoSimples.adultos;
        this.tjovens = quartoSimples.jovens;
        this.tcriancas = quartoSimples.criancas;
        this.utilsService.setQuartos(quartoSimples);
        this.organizaQuartos(quartoSimples);
      });
    this.dadosOrganizaQuartosService.organizaAvancado
      .subscribe(valorPorQuarto => {
        this.totaldiariaquarto01_CM = valorPorQuarto.totaldiariaquarto01_CM;
        this.totaldiariaquarto01_MP = valorPorQuarto.totaldiariaquarto01_MP;
        this.totaldiariaquarto01_PC = valorPorQuarto.totaldiariaquarto01_PC;
        this.totaldiariaquarto02_CM = valorPorQuarto.totaldiariaquarto02_CM;
        this.totaldiariaquarto02_MP = valorPorQuarto.totaldiariaquarto02_MP;
        this.totaldiariaquarto02_PC = valorPorQuarto.totaldiariaquarto02_PC;
        this.totaldiariaquarto03_CM = valorPorQuarto.totaldiariaquarto03_CM;
        this.totaldiariaquarto03_MP = valorPorQuarto.totaldiariaquarto03_MP;
        this.totaldiariaquarto03_PC = valorPorQuarto.totaldiariaquarto03_PC;
      });
    this.dadosReservaEscolhidaService.detalhesReservaEscolhida
      .subscribe(opcaoescolhida => {
        this.opSelecionada(opcaoescolhida);
      });
  }

  ngOnInit(): void {
    this.form = this.fb.group(
      this.getFormReserva()
    );
    this.listaJovens = [
      { id: 0, nomesingle: 'Nenhuma criança', nomeplural: '' },
      { id: 1, nomesingle: 'Criança', nomeplural: '' },
      { id: 2, nomesingle: '', nomeplural: 'Crianças' },
      { id: 3, nomesingle: '', nomeplural: 'Crianças' },
      { id: 4, nomesingle: '', nomeplural: 'Crianças' }
    ];
    this.listaAdultos = [
      { id: 1, nomesingle: 'Adulto', nomeplural: '' },
      { id: 2, nomesingle: '', nomeplural: 'Adultos' },
      { id: 3, nomesingle: '', nomeplural: 'Adultos' },
      { id: 4, nomesingle: '', nomeplural: 'Adultos' }
    ];
    this.listaCriancas = [
      { id: 0, nomesingle: 'Nenhuma criança', nomeplural: '' },
      { id: 1, nomesingle: 'Criança', nomeplural: '' },
      { id: 2, nomesingle: '', nomeplural: 'Crianças' }
    ];
  }

  // Irrelevante para a documentação
  get f() {
    return this.form.controls
  }

  getFormReserva() {
    return {
      adultos: [''],
      jovens: [''],
      criancas: ['']
    }
  }

  onSubmit() {
    if (this.nquarto == 1) {
      this.q01adulto = this.nadultos;
      this.q01joven = this.njovens ? this.njovens : 0;
      this.q01crianca = this.ncriancas ? this.ncriancas : 0;
      if (this.q01adulto != this.adultosquarto01) {
        this.btnNewQuartos = false,
        this.btnResetQuartos = false
      }
    }
    if (this.nquarto == 2) {
      this.q02adulto = this.nadultos;
      this.q02joven = this.njovens ? this.njovens : 0;
      this.q02crianca = this.ncriancas ? this.ncriancas : 0;
      if (this.q02adulto != this.adultosquarto02) {
        this.btnNewQuartos = false,
        this.btnResetQuartos = false
      }
    }
    if (this.nquarto == 3) {
      this.q03adulto = this.nadultos;
      this.q03joven = this.njovens ? this.njovens : 0;
      this.q03crianca = this.ncriancas ? this.ncriancas : 0;
      if (this.q03adulto != this.adultosquarto03) {
        this.btnNewQuartos = false,
        this.btnResetQuartos = false
      }
    }
    if (this.nquarto == 4) {
      this.q04adulto = this.nadultos;
      this.q04joven = this.njovens ? this.njovens : 0;
      this.q04crianca = this.ncriancas ? this.ncriancas : 0;
      if (this.q04adulto != this.adultosquarto04) {
        this.btnNewQuartos = true,
        this.btnResetQuartos = false
      }
    }
    if (this.nquarto == 5) {
      this.q05adulto = this.nadultos;
      this.q05joven = this.njovens ? this.njovens : 0;
      this.q05crianca = this.ncriancas ? this.ncriancas : 0;
      if (this.q05adulto != this.adultosquarto05) {
        this.newQuarto();
      }
    }
    this.hideChildModal(0);
  }

  newQuarto(){
    this.tquartos == 4 ? (
      this.tquartos = this.tquartos + 1,
      this.nq05 = true,
      this.btnNewQuartos = true,
      this.btnResetQuartos = false
    ) : null ;
    this.tquartos == 3 ? (
      this.tquartos = this.tquartos + 1,
      this.nq04 = true,
      this.btnNewQuartos = false,
      this.btnResetQuartos = false
    ) : null ;
    this.tquartos == 2 ? (
      this.tquartos = this.tquartos + 1,
      this.nq03 = true,
      this.btnNewQuartos = false,
      this.btnResetQuartos = false
    ) : null ;
    this.tquartos == 1 ? (
      this.tquartos = this.tquartos + 1,
      this.nq02 = true,
      this.btnNewQuartos = false,
      this.btnResetQuartos = false
    ) : null ;
  }

  removeQuarto() {
    this.tquartos == 5 ? (
      this.tquartos - 1
    ) : null ;
    this.tquartos == 4 ? (
      this.tquartos - 1
    ) : null ;
    this.tquartos == 3 ? (
      this.tquartos - 1
    ) : null ;
    this.tquartos == 2 ? (
      this.tquartos - 1
    ) : null ;
    this.tquartos == 1 ? (
      this.tquartos - 1
    ) : null ;
  }

  montaQuarto(nquarto: number) {
    if (nquarto == 1) {
      this.nadultos = this.q01adulto;
      this.njovens = this.q01joven;
      this.ncriancas = this.q01crianca;
      this.disabledJoven = this.q01joven != 0 ? false : true;
      this.disabledCrianca = this.q01crianca != 0 ? false : true;
    }
    if (nquarto == 2) {
      this.nadultos = this.q02adulto;
      this.njovens = this.q02joven;
      this.ncriancas = this.q02crianca;
      this.disabledJoven = this.q01joven != 0 ? false : true;
      this.disabledCrianca = this.q01crianca != 0 ? false : true;
    }
    if (nquarto == 3) {
      this.nadultos = this.q03adulto;
      this.njovens = this.q03joven;
      this.ncriancas = this.q03crianca;
      this.disabledJoven = this.q01joven != 0 ? false : true;
      this.disabledCrianca = this.q01crianca != 0 ? false : true;
    }
    this.isAdulto(this.nadultos);
    this.isJovens(this.njovens);
    this.isCriancas(this.ncriancas);
  }

  isAdulto(quantA: number) {
    this.quantAdultoT = quantA == 1 ? `${quantA} Adulto` : `${quantA} Adultos`;
    this.nadultos = quantA;
    if (this.jovens != 0) {
      if (quantA <= 3) {
        this.contJovens(3);
      }
      quantA == 1 ? this.contJovens(3) : null;
      quantA == 2 ? this.contJovens(2) : null;
      quantA == 3 ? this.contJovens(1) : null;
      quantA == 4 ? this.contJovens(0) : null;
    }
  }

  isJovens(quantJ: number) {
    this.quantJovensT = quantJ == 1 ? `${quantJ} jovem` : `${quantJ} jovens`;
    this.njovens = quantJ;
  }

  isCriancas(quantC: number) {
    this.quantCriancaT = quantC == 1 ? `${quantC} criança` : `${quantC} crianças`;
    this.ncriancas = quantC;
  }

  contAdultos(cont: number) {
    this.listaAdultos = [];
    for (let index = 1; index <= cont; index++) {
      this.listaAdultos?.push({
        id: index,
        nomesingle: index == 1 ? 'Adulto' : '',
        nomeplural: index != 1 ? 'Adultos' : 'null',
      });
    }
  }

  contJovens(cont: number) {
    this.listaJovens = [];
    if (cont != 0) {
      for (let index = 1; index <= cont; index++) {
        if (index === 1) {
          this.listaJovens?.push({
            id: 0,
            nomesingle: 'Nenhum jovem',
            nomeplural: 'null',
          });
        }
        this.listaJovens?.push({
          id: index,
          nomesingle: index == 1 ? 'Jovem' : '',
          nomeplural: index != 1 ? 'Jovens' : 'null',
        });
      }
      this.disabledJoven = false;
      this.disabledCrianca = false;
      this.quantJovensT = "0 Jovens";
      this.quantCriancaT = "0 Crianças";
    } else {
      this.disabledJoven = true;
      this.disabledCrianca = true;
      this.quantJovensT = "0 Jovens";
      this.njovens = 0;
      this.ncriancas = 0;
    }
  }

  opSelecionada(opcao: number) {
    opcao == 1 ? (
      this.diariaQuarto01 = this.totaldiariaquarto01_CM,
      this.diariaQuarto02 = this.totaldiariaquarto02_CM,
      this.diariaQuarto03 = this.totaldiariaquarto03_CM ? this.totaldiariaquarto03_CM : 0
    ) : null;
    opcao == 2 ? (
      this.diariaQuarto01 = this.totaldiariaquarto01_MP,
      this.diariaQuarto02 = this.totaldiariaquarto02_MP,
      this.diariaQuarto03 = this.totaldiariaquarto03_MP ? this.totaldiariaquarto03_MP : 0
    ) : null;
    opcao == 3 ? (
      this.diariaQuarto01 = this.totaldiariaquarto01_PC,
      this.diariaQuarto02 = this.totaldiariaquarto02_PC,
      this.diariaQuarto03 = this.totaldiariaquarto03_PC ? this.totaldiariaquarto03_PC : 0
    ) : null;
  }

  organizaQuartos(quartoSimples: OrgabinzaQuartosModel) {
    this.quartos = quartoSimples.quartos;
    this.tquartos = quartoSimples.quartos;
    this.adultosquarto01 = quartoSimples.adultosquarto01;
    this.adultosquarto02 = quartoSimples.adultosquarto02;
    this.adultosquarto03 = quartoSimples.adultosquarto03;
    this.jovensquarto01 = quartoSimples.jovensquarto01;
    this.jovensquarto02 = quartoSimples.jovensquarto02;
    this.jovensquarto03 = quartoSimples.jovensquarto03;
    this.criancasquarto01 = quartoSimples.criancasquarto01;
    this.criancasquarto02 = quartoSimples.criancasquarto02;
    this.criancasquarto03 = quartoSimples.criancasquarto03;

    this.q01adulto = quartoSimples.adultosquarto01;
    this.q01joven = quartoSimples.jovensquarto01;
    this.q01crianca = quartoSimples.criancasquarto01;
    this.q02adulto = quartoSimples.adultosquarto02;
    this.q02joven = quartoSimples.jovensquarto02;
    this.q02crianca = quartoSimples.criancasquarto02;
    this.q03adulto = quartoSimples.adultosquarto03;
    this.q03joven = quartoSimples.jovensquarto03;
    this.q03crianca = quartoSimples.criancasquarto03;
    this.verificaQuartos();
  }

  verificaQuartos() {
    this.btnNewQuartos = (this.q01adulto + this.q01joven + this.q01crianca) >= 1 ? true : false;
    this.nq02 = (this.q02adulto + this.q02joven + this.q02crianca) >= 1 ? true : false;
    this.nq03 = (this.q03adulto + this.q03joven + this.q03crianca) >= 1 ? true : false;
    this.nq04 = (this.q04adulto + this.q04joven + this.q04crianca) >= 1 ? true : false;
    this.nq05 = (this.q05adulto + this.q05joven + this.q05crianca) >= 1 ? true : false;

  }

  reiniciaQuartos(){
    this.btnResetQuartos = true;
    this.tquartos = this.quartos;
    this.q01adulto = this.adultosquarto01;
    this.q02adulto = this.adultosquarto02;
    this.q03adulto = this.adultosquarto03;
    this.q01joven = this.jovensquarto01;
    this.q02joven = this.jovensquarto02;
    this.q03joven = this.jovensquarto03;
    this.q01crianca = this.criancasquarto01;
    this.q02crianca = this.criancasquarto02;
    this.q03crianca = this.criancasquarto03;
    this.verificaQuartos();
  }

  resetQuartos(nquarto: number) {
    if (nquarto == 1) {
      this.q01adulto = this.adultosquarto01 != this.q01adulto ? this.q01adulto : this.adultosquarto01;
      this.q01joven = this.jovensquarto01 != this.q01joven ? this.q01joven : this.jovensquarto01;
      this.q01crianca = this.criancasquarto01 != this.q01crianca ? this.q01crianca : this.criancasquarto01;
    }
    if (nquarto == 2) {
      this.q02adulto = this.adultosquarto02 != this.q02adulto ? this.q02adulto : this.adultosquarto02;
      this.q02joven = this.jovensquarto02 != this.q02joven ? this.q02joven : this.jovensquarto02;
      this.q02crianca = this.criancasquarto02 != this.q02crianca ? this.q02crianca : this.criancasquarto02;
    }
    if (nquarto == 3) {
      this.q03adulto = this.adultosquarto03 != this.q03adulto ? this.q03adulto : this.adultosquarto03;
      this.q03joven = this.jovensquarto03 != this.q03joven ? this.q03joven : this.jovensquarto03;
      this.q03crianca = this.criancasquarto03 != this.q03crianca ? this.q03crianca : this.criancasquarto03;
    }
  }

  valor(valor: number): number {
    let numero!: number;
    let texto01: string = valor.toFixed(2).toString().replace(/[^\d]+/g, '');
    numero = +parseFloat(texto01.substring(0, texto01.length + -2) + "." + texto01.substring(texto01.length + -2, texto01.length));
    // numero = +texto01
    return numero;
  }

  formatter(value: number): string {
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value);
  }

}
