// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  api: 'http://localhost:3035',
  account_token: `R3k0Wmk2THExU3c3TG01TWQyR3Q0VXM3UW42U3ExRWI3VWk4R3UxWXoyRnM4UWU0VHA1WnI2V3kyUXU5SWc0TXo0U2UxRnk2WGs3WWIxQmc1VnQ5QXA2Vm0yWWU3THE2UGQzUw==`
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
