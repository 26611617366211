<div class="card" style="width: 100%; border:0;">
  <div class="card-body">
    <h5 class="card-title">Caldas Termas Clube CTC</h5>
    <div class="col-12 d-flex align-items-center">
      <div class="servicos">
        <fa-icon [icon]="faWifi" class="px-1"></fa-icon>Wi-fi gratuito
      </div>
      <div class="servicos">
        <svg width="15" height="15" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1"
          viewBox="0 0 24 24">
          <path
            d="m2,9v-4C2,2.243,4.243,0,7,0h10c2.757,0,5,2.243,5,5v4h-5v-3c0-.552-.447-1-1-1s-1,.448-1,1v3H2Zm15,2v6c0,.553-.447,1-1,1s-1-.447-1-1v-6H2v8c0,2.757,2.243,5,5,5h10c2.757,0,5-2.243,5-5v-8h-5Z" />
        </svg> Frigobar
      </div>
      <div class="servicos">
        <fa-icon [icon]="faSnowflake" class="px-1"></fa-icon>Ar-Condicionado
      </div>
    </div>
    <div class="col-12 d-flex align-items-center mb-3">
      <div class="servicos">
        <fa-icon [icon]="faWater" class="px-1"></fa-icon>Parque Aquático
      </div>
      <div class="servicos">
        <fa-icon [icon]="faWifi" class="px-1"></fa-icon>Wi-fi no parque
      </div>
      <div class="servicos">
        <fa-icon [icon]="faGlassMartini" class="px-1"></fa-icon>Bar
      </div>
      <div class="servicos">
        <fa-icon [icon]="faCoffee" class="px-1"></fa-icon>Restaurante
      </div>
      <div class="servicos">
        <fa-icon [icon]="faCar" class="px-1"></fa-icon>Estacionamento
      </div>
      <div class="servicos">
        <fa-icon [icon]="faSpa" class="px-1"></fa-icon>SPA e Sauna
      </div>
    </div>
    <p class="card-text">Inaugurado no ano de 1966, o Caldas Termas Clube surgiu como uma das primeiras opções de
      hospedagem
      de Caldas Novas, já oferecendo banhos termais de fontes minerais e naturais. Com a crescente demanda
      de pessoas em busca das águas medicinais de Caldas Novas para descanso, lazer e tratamentos de saúde,
      o CTC cresceu e consolidou-se como referência em hospitalidade, preservando essa característica até
      hoje. </p>
    <p class="card-text">Espalhado por uma área de mais 40.000m² em pleno centro da cidade, disponibiliza uma área verde
      invejável e rica flora e fauna nativas. Localizando no centro da cidade, o CTC está a poucos passos da
      maioria das atrações turísticas e serviços necessários a uma hospedagem memorável em Caldas Novas.
      Atualmente o complexo conta com 11 piscinas termais das quais 2 de uso exclusivo dos hóspedes, 2
      ofurôs igualmente exclusivos, saunas, duchas naturais, toboáguas, parque aquático infantil, sorveteria
      Nestlé, restaurante, bares, lanchonetes, recreação diariamente, serestas à partir das 21:00 (de acordo
      com calendário) bem como espaço Home Office para suas necessidades de trabalho on line, tudo à
      disposição para oferecer o melhor conforto e comodidade para seus visitantes. Paralelamente, o hotel
      ainda oferece serviços de massagem, loja de semi jóias, churros, crepes, doces gourmets e souvenirs
      além de transporte interno entre suas instalações.</p>
  </div>
</div>
