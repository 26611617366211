import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-nav-tabs',
  templateUrl: './nav-tabs.component.html',
  styleUrls: ['./nav-tabs.component.scss'],
})
//alteração sendo realizadas temporariamente
export class NavTabsComponent implements OnInit {
  @Output() isFaleConosoTabElement: EventEmitter<Boolean>;
  constructor() {}

  ngOnInit(): void {}

  setIsFaleConosco(event) {
    this.isFaleConosoTabElement.emit(event);
  }
} 
