<div class="row">
  <!-- titulos -->
  <div class="col-lg-6">
    <h5 class="m-0">Responsável pelo pagamento</h5>
  </div>
  <div class="col-lg-6">
    <h5 class="m-0">Dados do cartão</h5>
  </div>
  <!-- dados do customer e cartão -->
  <div class="col-lg-6">
    <div class="card mt-2">
      <div class="card-body">

        <div class="row mb-3">
          <div class="col-lg-6">
            <div class="fs-6 mb-0 pb-0 border-bottom border-warning">
              {{ customer.nome | titlecase }}
            </div>
            <div id="nome" class="form-text">Títular do cartão.</div>
          </div>
          <div class="col-lg-6">
            <div class="fs-6 mb-0 pb-0 border-bottom border-warning">{{ customer.cpf | cpfcnpj }}</div>
            <div id="cpf" class="form-text">CPF.</div>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-lg-6">
            <div class="fs-6 mb-0 pb-0 border-bottom border-warning">{{ customer.email }}</div>
            <div id="email" class="form-text">E-mail.</div>
          </div>
          <div class="col-lg-6">
            <div class="fs-6 mb-0 pb-0 border-bottom border-warning">{{ telefone(customer.fonemovel) }}
            </div>
            <div id="fonemovel" class="form-text">Celular.</div>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-lg-8">
            <div class="fs-6 mb-0 pb-0 border-bottom border-warning">{{ customer.logradouro }}</div>
            <div id="logradouro" class="form-text">Rua, Av. Alameda, Etc.</div>
          </div>
          <div class="col-lg-4">
            <div class="fs-6 mb-0 pb-0 border-bottom border-warning">{{ customer.cep | cepBr }}</div>
            <div id="cep" class="form-text">CEP.</div>
          </div>
        </div>

        <div class="row mb-3" *ngIf="customer.complemento">
          <div class="col-lg-12">
            <div class="fs-6 mb-0 pb-0 border-bottom border-warning">{{ customer.complemento }}</div>
            <div id="complemento" class="form-text">Complemento.</div>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-lg-3">
            <div class="fs-6 mb-0 pb-0 border-bottom border-warning">{{ customer.numero }}</div>
            <div id="numero" class="form-text">nº.</div>
          </div>
          <div class="col-lg-9">
            <div class="fs-6 mb-0 pb-0 border-bottom border-warning">{{ customer.bairro }}</div>
            <div id="bairro" class="form-text">Bairro.</div>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-lg-8">
            <div class="fs-6 mb-0 pb-0 border-bottom border-warning">{{ customer.cidade }}</div>
            <div id="cidade" class="form-text">Cidade.</div>
          </div>
          <div class="col-lg-4">
            <div class="fs-6 mb-0 pb-0 border-bottom border-warning">{{ customer.estado }}</div>
            <div id="estado" class="form-text">Estado.</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-6">
    <div class="card mt-2">
      <div class="card-body">

        <div class="row mb-3">
          <div class="row mb-3">
            <div class="col-lg-6">
              <input type="text" class="form-control" disabled [(ngModel)]="valor">
              <div id="nome" class="form-text">Valor a ser pago.</div>
            </div>
            <div class="col-lg-6">
              <div class="btn-group d-grid gap-2" dropdown>
                <button id="button-parcelamento" dropdownToggle type="button"
                  class="form-control btn btn-light dropdown-toggle" aria-controls="dropdown-parcelamento">
                  {{ valorParcela }}
                </button>
                <ul id="dropdown-parcelamento" *dropdownMenu class="dropdown-menu" role="menu"
                  aria-labelledby="button-parcelamento">
                  <li role="menuitem" *ngFor="let item of parcelasDefinidas">
                    <a class="dropdown-item" (click)="onCountryChanged(item)">
                      {{ item.number }}x de {{ formatValue(item.total) }}
                    </a>
                  </li>
                </ul>
              </div>
              <div id="cpf" class="form-text">Opções de parcelamento.</div>
            </div>
          </div>
        </div>

        <form novalidate="" role="formPagamento" [formGroup]="formPagamento" name="dadosPagamento"
          (ngSubmit)="onSubmitPagamento()">

          <div class="row mb-3">
            <div class="col-lg-12">
              <label for="number" class="form-label"><span class="text-danger">*</span>Digite número do cartão.</label>
              <input type="text" formControlName="number" class="form-control" id="number" aria-describedby="number"
                mask="0000 0000 0000 0000" required [ngClass]="{ 'is-invalid': fpg['number'].touched && fpg['number'].errors,
                        'is-valid': fpg['number'].touched && !fpg['number'].errors }">
              <div id="number" class="form-text">Somente números</div>
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-lg-8">
              <label for="number" class="form-label"><span class="text-danger">*</span>Data de vencimento.</label>
            </div>
            <div class="col-lg-4">
              <label for="number" class="form-label"><span class="text-danger">*</span>Verificação.</label>
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-lg-4">
              <input type="text" formControlName="vencemes" class="form-control" id="vencemes" mask="00"
                aria-describedby="vencemes" required [ngClass]="{ 'is-invalid': fpg['vencemes'].touched && fpg['vencemes'].errors,
                    'is-valid': fpg['vencemes'].touched && !fpg['vencemes'].errors }">
              <div id="vencemes" class="form-text">Mês 00</div>
            </div>
            <div class="col-lg-4">
              <input type="text" formControlName="venceano" class="form-control" id="venceano" mask="0000"
                aria-describedby="venceano" required [ngClass]="{ 'is-invalid': fpg['venceano'].touched && fpg['venceano'].errors,
                    'is-valid': fpg['venceano'].touched && !fpg['venceano'].errors }">
              <div id="vencemes" class="form-text">Ano 0000</div>
            </div>
            <div class="col-lg-4">
              <input type="text" formControlName="ccv" class="form-control" id="ccv" mask="000" aria-describedby="ccv"
                required [ngClass]="{ 'is-invalid': fpg['ccv'].touched && fpg['ccv'].errors,
                    'is-valid': fpg['ccv'].touched && !fpg['ccv'].errors }">
              <div id="vencemes" class="form-text">CCV 000</div>
            </div>
          </div>

          <div class="row mb-1">
            <div class="col-lg-12">
              <div class="float-end">
                <div class="btn-group" role="group" aria-label="Basic example">
                  <button type="button" class="btn btn-warning" (click)="voltaDadosPagador()">Voltar</button>
                  <button type="submit" class="btn btn-primary"
                    [disabled]="formPagamento.pristine || formPagamento.invalid">Pagar</button>
                </div>
              </div>
            </div>
          </div>
        </form>

      </div>
    </div>
  </div>
</div>
