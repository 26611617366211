<section class="our-mission-area ptb-100">
  <div class="container-fluid">
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-12">
        <div class="our-mission-content">
          <div class="content" *ngFor="let Content of ourMissionContent;">
            <span class="sub-title"><img src="assets/img/star-icon.png" alt="image"> {{Content.subTitle}}</span>
            <h2>{{Content.title}}</h2>
            <p>{{Content.paragraphText1}}<br /><span style="font-size: 12px;">{{Content.paragraphText2}}</span></p>
            <ul class="our-mission-list">
              <li *ngFor="let List of ourMissionList;" style="cursor: pointer;" routerLink="/{{List.link}}">
                <span>
                  <div class="icon">
                    <!-- <i class="{{List.icon}}"></i> -->
                    <img [src]="List.icon" alt="image">
                  </div>
                  <h5>{{List.title}}</h5>
                  <img src="assets/img/seguimentos/shape-metropolitan.png" alt="image">
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-12">
        <div class="our-mission-image">
          <div class="shape"><img src="assets/img/mais-diversao/toboagua.png"></div>
        </div>
      </div>
    </div>
  </div>
</section>
