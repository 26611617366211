// Components do sistema
import { HttpHeaders } from '@angular/common/http';
import { account_token } from 'src/app/share/api';
import { DadosReservaV2Model } from '../reservas/models/dados-reserva-v2.model';
import { DadosFormPaymentV1Model } from '../pagamentos/model/dados-form-payment-v1.model';
import { OrgabinzaQuartosModel } from '../reservas/models/organiza-quartos.model';

export class UtilsService {
  public update = null;

  public setDadosReserva(
    dadosreserva: DadosReservaV2Model
  ) {
    localStorage.setItem('dadosreserva', btoa(JSON.stringify(dadosreserva)));
  }

  public setPayment(
    customer: DadosFormPaymentV1Model
  ) {
    localStorage.setItem('dadosPayment', btoa(JSON.stringify(customer)));
  }

  public setQuartos(
    quartoSimples: OrgabinzaQuartosModel
  ) {
    localStorage.setItem('dadosquartos', btoa(JSON.stringify(quartoSimples)));
  }

  public getDadosReserva(): DadosReservaV2Model {
    const data = localStorage.getItem('dadosreserva');
    return data ? JSON.parse(atob(data)) : false;
  }

  public getDadosPayment(): DadosFormPaymentV1Model {
    const data = localStorage.getItem('dadosPayment');
    return data ? JSON.parse(atob(data)) : false;
  }


  public getQuartos(): OrgabinzaQuartosModel {
    const data = localStorage.getItem('dadosquartos');
    return data ? JSON.parse(atob(data)) : undefined;
  }


  public isDadosReserva(): boolean {
    if (this.getDadosReserva())
      return true;
    else
      return false;
  }

  public isDadosPayment(): boolean {
    if (this.getDadosPayment())
      return true;
    else
      return false;
  }

  public isDadosQuartos(): boolean {
    if (this.getQuartos())
      return true;
    else
      return false;
  }

  public clear() {
    localStorage.removeItem('dadosreserva');
    localStorage.removeItem('dadosPayment');
    localStorage.removeItem('dadosquartos');
  }

  public composeHeader() {
    const headers = new HttpHeaders().set('accountaccess', account_token);
    return headers;
  }

}
