<div class="preloader">
  <div class="loader">
    <div class="sbl-half-circle-spin">
      <div></div>
    </div>
    <div>
      <h5>Aguarde...</h5>
    </div>
  </div>
</div>
