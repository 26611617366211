import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gallery-hotel',
  templateUrl: './gallery-hotel.component.html',
  styleUrls: ['./gallery-hotel.component.scss'],
})
export class GalleryHotelComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  pageTitleArea: pageTitle[] = [
    {
      title: 'Gallery',
    },
  ];
  galleryImages: Image[] = [
    {
      img: 'assets/img/gallery-hotel/img-13.jpeg',
    },
    {
      img: 'assets/img/gallery-hotel/img-14.jpeg',
    },
    {
      img: 'assets/img/gallery-hotel/img-15.jpeg',
    },
    {
      img: 'assets/img/gallery-hotel/img-16.jpeg',
    },
    {
      img: 'assets/img/gallery-hotel/img-17.jpeg',
    },
    {
      img: 'assets/img/gallery-hotel/img-18.jpeg',
    },
    {
      img: 'assets/img/gallery-hotel/img-19.jpeg',
    },
    {
      img: 'assets/img/gallery-hotel/img-20.jpeg',
    },
    {
      img: 'assets/img/gallery-hotel/img-21.jpeg',
    },
    {
      img: 'assets/img/gallery-hotel/img-22.jpeg',
    },
  ];
}
class pageTitle {
  title: string;
}
class Image {
  img: string;
}
