import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sobre-hotel-ctc',
  templateUrl: './sobre-hotel-ctc.component.html',
  styleUrls: ['./sobre-hotel-ctc.component.scss'],
})
export class SobreHotelCtcComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  pageTitleArea: pageTitle[] = [
    {
      title: 'Sobre o Hotel CTC',
      subTitle: 'Saiba mais sobre o Hotel CTC.',
    },
  ];

  caseStudiesDetailsDesc: DetailsDesc[] = [
    {
      subTitle: 'Sobre Hotel CTC',
      title1: 'Tradição, conforto, história e muita diversão!',
      paragraph1:
        'O hotel CTC é um dos hotéis mais tradicionais de Caldas Novas, trazendo em sua essência toda a tradição da hotelaria, com atendimento caloroso e diferenciado oferecendo ambiente familiar e tranquilo e em meio a uma natureza muito agradável. No parque aquático, 11 piscinas e dois ofurôs com água termal mineral, proveniente de seus poços onde a água brota da terra a temperaturas superiores a 50oC (em determinadas épocas do ano até mais!!), as quais são diariamente lavadas e higienizadas afim de oferecer a melhor qualidade de balneabilidade de cidade. Completam o pacote academia ao ar livre, sauna úmida, spa, sala de massagem, salão de beleza e grande oferta de gastronomia além de wifi grátis em todo o empreendimento e o Espaço Home Office, para você trabalhar com tranquilidade e internet dedicada em ambiente confortável e tranquilo.',
      img1: 'assets/img/projects/img2.jpg',
      title2: 'Mais por menos!',
      paragraph2:
        'Estamos localizados no centro de Caldas Novas, a poucos passos das melhores e mais procuradas atrações turísticas como o parque de diversões Kitaka, o famoso “parquinho de Caldas”, a feira do Luar, que oferece dezenas de opções de artesanato e gastronomia, ponto de embarque dos tradicionais trenzinhos, que fazem a alegria dos turistas de todas as idades com suas luzes, música e personagens pra lá de animados e a igreja matriz (Paróquia Nossa Senhora das Dores). E se você quiser aproveitar mais a cidade, o Hotel CTC possui um trenzinho exclusivo para conhecer outras atrações como a cachaçaria, o shopping serra verde, museu dos bonecos gigantes e tantas outras.',
      paragraph3:
        'E para completar, oferecemos a mais tradicional e agradável seresta de Caldas, onde você ouvirá os eternos sucessos e as últimas novidades musicais nacionais e internacionais ao vivo. Se tudo isso não bastar, variados serviços estão no entorno como bares, restaurantes, sorveterias, farmácias, padarias, supermercados, enfim, tudo para que você não se preocupe utilizar seu veículo para nada, apenas curtir sua hospedagem e descansar.',
    },
  ];
  caseStudiesDetailsDescImportantFacts: ImportantFacts[] = [
    {
      title: 'Piscinas',
    },
    {
      title: 'Saúna',
    },
    {
      title: 'Auditório',
    },
    {
      title: 'Ofurôs',
    },
    {
      title: 'Toboáguas',
    },
    {
      title: 'Home Office',
    },
  ];
  caseStudiesDetailsInfo: Info[] = [
    {
      icon: 'bx bx-user-pin',
      title: 'Reservas',
      subTitle: 'form reservas online',
    },
  ];
}
class pageTitle {
  title: string;
  subTitle: string;
}
class DetailsDesc {
  subTitle: string;
  title1: string;
  title2: string;
  img1: string;
  paragraph1: string;
  paragraph2: string;
  paragraph3: string;
}
class ImportantFacts {
  title: string;
}
class Info {
  icon: string;
  title: string;
  subTitle: string;
}
