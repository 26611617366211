<footer class="{{footerClass}}">
  <div class="container">
    <div class="row">
      <div class="col-lg-4 col-md-6 col-sm-6">
        <div class="single-footer-widget">
          <a routerLink="/" class="logo"><img src="assets/img/logo.png" alt="logo"></a>
          <p>Localizado no Centro de Caldas Novas. Há mais de 4 décadas oferecendo conforto, segurança, lazer e diversão
            à sua família.</p>
          <ul class="social-link">
            <li><a href="https://www.facebook.com/ctccaldas" class="d-block" target="_blank"><i
                  class='bx bxl-facebook'></i></a></li>
            <li><a href="https://instagram.com/ctc.caldasnovas" class="d-block" target="_blank"><i
                  class='bx bxl-instagram'></i></a></li>
          </ul>
        </div>
      </div>

      <div class="col-lg-2 col-md-6 col-sm-6">
        <div class="single-footer-widget pl-5">
          <h3>Navegue</h3>
          <ul class="footer-links-list">
            <li><a routerLink="">Home</a></li>
            <li><a href="https://metropolitanpark.com.br/" target="_blank">Metropolitan Park</a></li>
            <li><a routerLink="/sobre-hotel-ctc">Sobre o Hotel CTC</a></li>
            <li><a routerLink="/sobre-aguas-quentes-de-caldas-novas">Sobre Caldas Novas</a></li>
            <li><a routerLink="/contato">Entre em contato</a></li>
          </ul>
        </div>
      </div>

      <div class="col-lg-2 col-md-6 col-sm-6">
        <div class="single-footer-widget">
          <h3>Conheça</h3>
          <ul class="footer-links-list">
            <li><a routerLink="">Muita Diversão</a></li>
            <li><a routerLink="/instalacoes-apartamentos">Suítes Confortáveis</a></li>
            <li><a href="https://www.google.com/search?sca_esv=561395159&rlz=1C1VDKB_pt-PTBR1062BR1062&tbs=lf:1,lf_ui:9&tbm=lcl&sxsrf=AB5st
                BjcJ6Xg8uUtuGoThZxzoj3KryBFGg:1693423432554&q=restaurantes+bem+avaliados+em+caldas+novas&
                rflfq=1&num=10&sa=X&ved=2ahUKEwis_5rxjYWBAxVKtJUCHSfGBtUQjGp6BAggEAE&biw=1920&bih=973&dpr=1#rlfi=hd
                :;si:;mv:[[-17.7258993,-48.6227412],[-17.752754499999998,-48.6363365]];tbs:lrf:!1m4!1u3!2m2!3m1!1e1!1m4!1u5!2m2!5m1!1sgcid_3bars_1and_1pubs
                !1m4!1u5!2m2!5m1!1sgcid_3brazilian_1restaurant!1m4!1u2!2m2!2m1!1e1!1m4!1u1!2m2!1m1!1e1!1m4!1u1!2m2!1m1!1e2!2m1!1e2!2m1!1e5!2m1!1e1!2m1!1e3!3sIAEqAkJS,lf:1
                ,lf_ui:9" target="_blank">
                Restaurantes
              </a>
            </li>
            <li><a
                href="
              https://www.google.com/search?q=bares+bem+avaliados+em+caldas+novas&sca_esv=561395159&rlz=1C1VDKB_pt-PTBR1062BR1062&biw=1920&bih=973&tbm=lcl&sxsrf=AB5stBjwx0afxqG2mdATMzEjt5AWuNerig%3A1693423441273&ei=UZfvZJKvEODS1sQPprW1sAY&oq=bares+bem+avaliad&gs_lp=Eg1nd3Mtd2l6LWxvY2FsIhFiYXJlcyBiZW0gYXZhbGlhZCoCCAAyBRAhGKABSJMbUABYpBZwAXgAkAEAmAGvAaAB8RKqAQQwLjE4uAEDyAEA-AEBwgIEECMYJ8ICCxAAGIAEGLEDGIMBwgIIEAAYgAQYsQPCAgUQABiABMICCxAAGIoFGLEDGIMBwgIHEAAYigUYQ8ICChAAGIoFGLEDGEPCAgsQABiABBixAxjJA8ICCBAAGIoFGJIDwgIIEAAYigUYsQPCAgcQABgNGIAEiAYB&sclient=gws-wiz-local#rlfi=hd:;si:;mv:[[-17.7103152,-48.5825441],[-17.7689615,-48.647569999999995]];tbs:lrf:!1m4!1u3!2m2!3m1!1e1!1m4!1u2!2m2!2m1!1e1!2m1!1e2!2m1!1e3!3sIAE,lf:1,lf_ui:9"
                target="_blank">Bares</a></li>
            <li><a routerLink="">Pacotes e Promoções</a></li>
          </ul>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 col-sm-6">
        <div class="single-footer-widget">
          <h3>Endereço</h3>
          <ul class="footer-contact-info">
            <li><i class='bx bx-map'></i>Avenida Orcalino Santos, 219, Centro, Caldas Novas - Goiás, CEP: 75680-013</li>
            <li><i class='bx bx-phone-call'></i><a href="tel:+44587154756">(64) 3453-1515</a> (whatsapp)</li>
            <li><i class='bx bx-envelope'></i><a href="mailto:hello@wilo.com">ctc@hotelctc.com.br</a></li>
          </ul>
        </div>
      </div>
    </div>

    <div class="footer-bottom-area">
      <div class="row align-items-center">
        <div class="col-lg-6 col-md-6">
          <p><i class='bx bx-copyright'></i>2023 <strong>Hotel CTC</strong> - Caldas Termas Clube</p>
        </div>

        <div class="col-lg-6 col-md-6">
          <ul>
            <li><a routerLink="/politicas-de-hospedagens" class="nav-link" routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}">Políticas de Hospedagens</a>
              <!-- <a routerLink="/">Políticas de Hospedagens</a> -->
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <div class="footer-map"><img src="assets/img/footer-map.png" alt="image"></div>
</footer>

<!-- <div class="go-top"><i class="flaticon-up"></i></div> -->
