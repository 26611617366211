<div class="container">
  <div class="row">
    <div class="col-12">
      <div class="card border-secondary-subtle">
        <div class="card-header text-center">
          <h5 class="card-title m-0">Disposição do(s) quarto(s)</h5>
        </div>
        <div class="card-body">
          <div class="row">

            <!--Quarto 01-->
            <div class="col-4">
              <div class="card border-secondary-subtle bgquartos text-center">
                <h5 class="m-2"><strong>1º Quarto</strong></h5>
                <!-- <small class="text-secondary fsize">valor da diária:
                  <strong> {{ formatter(diariaQuarto01) }}</strong>
                </small> -->
                <div class="row mt-3">
                  <div class="col-4">
                    <h5 class="mb-0"><strong>{{ q01adulto }}</strong></h5>
                    <small class="mt-0 fsize">
                      Adulto(s)
                    </small>
                  </div>
                  <div class="col-4">
                    <h5 class="mb-0"><strong>{{ q01joven }}</strong></h5>
                    <small class="mt-0 fsize">
                      Jovem(ns)
                    </small>
                  </div>
                  <div class="col-4">
                    <h5 class="mb-0"><strong>{{ q01crianca }}</strong></h5>
                    <small class="mt-0 fsize">
                      Criança(s)
                    </small>
                  </div>
                </div>
              </div>
            </div>

            <!--Quarto 2-->
            <div class="col-4" *ngIf="nq02">
              <div class="card border-secondary-subtle bgquartos text-center">
                <h5 class="m-2"><strong>2º Quarto</strong></h5>
                <!-- <small class="text-secondary fsize">valor da diária:
                  <strong> {{ formatter(diariaQuarto02) }}</strong>
                </small> -->
                <div class="row mt-3">
                  <div class="col-4">
                    <h5 class="mb-0"><strong>{{ q02adulto }}</strong></h5>
                    <small class="mt-0 fsize">
                      Adulto(s)
                    </small>
                  </div>
                  <div class="col-4">
                    <h5 class="mb-0"><strong>{{ q02joven }}</strong></h5>
                    <small class="mt-0 fsize">
                      Jovem(ns)
                    </small>
                  </div>
                  <div class="col-4">
                    <h5 class="mb-0"><strong>{{ q02crianca }}</strong></h5>
                    <small class="mt-0 fsize">
                      Criança(s)
                    </small>
                  </div>
                </div>
              </div>
            </div>

            <!--Quarto 3-->
            <div class="col-4" *ngIf="nq03">
              <div class="card border-secondary-subtle bgquartos text-center">
                <h5 class="m-2"><strong>3º Quarto</strong></h5>
                <!-- <small class="text-secondary fsize">valor da diária:
                  <strong> {{ formatter(diariaQuarto03) }}</strong>
                </small> -->
                <div class="row mt-3">
                  <div class="col-4">
                    <h5 class="mb-0"><strong>{{ q03adulto }}</strong></h5>
                    <small class="mt-0 fsize">
                      Adulto(s)
                    </small>
                  </div>
                  <div class="col-4">
                    <h5 class="mb-0"><strong>{{ q03joven }}</strong></h5>
                    <small class="mt-0 fsize">
                      Jovem(ns)
                    </small>
                  </div>
                  <div class="col-4">
                    <h5 class="mb-0"><strong>{{ q03crianca }}</strong></h5>
                    <small class="mt-0 fsize">
                      Criança(s)
                    </small>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>
