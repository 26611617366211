<section class="margin-top-area">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="features-list p-2">
          <form novalidate="" role="form" [formGroup]="form" name="reservaForm" (ngSubmit)="onSubmit()">

            <!-- <alert type="success  pt-0 pb-2 px-2">1 -->
            <div class="row">

              <!-- input Data de entrada -->
              <div class="col-lg-2 col-xl-2 col-md-4 col-sm-12">
                <div>
                  <label class="form-label mb-1">
                    <small><span class="text-danger">*</span> Checkin</small>
                  </label>
                  <div class="clockpicker">
                    <input type="text" formControlName="dtentrada" placeholder="Data de Entrada" class="form-control"
                      (bsValueChange)="onValueChange($event)" [maxDate]="maxDate" [minDate]="iniDate"
                      [ngClass]="{ 'is-invalid': f['dtentrada'].touched && f['dtentrada'].errors, 'is-valid': f['dtentrada'].touched && !f['dtentrada'].errors }"
                      id="dtentrada" required #dp="bsDatepicker" bsDatepicker [bsConfig]="bsConfig">
                  </div>
                </div>
              </div>

              <!-- input Data de saída -->
              <div class="col-lg-2 col-xl-2 col-md-4 col-sm-12">
                <div>
                  <label class="form-label mb-1">
                    <small><span class="text-danger">*</span> Checkout</small>
                  </label>
                  <div class="clockpicker">
                    <input type="text" formControlName="dtsaida" placeholder="Data de Saída" class="form-control"
                      [minDate]="minDate" [maxDate]="maxDate"
                      [ngClass]="{ 'is-invalid': f['dtsaida'].touched && f['dtsaida'].errors, 'is-valid': f['dtsaida'].touched && !f['dtsaida'].errors }"
                      id="dtsaida" required #dp="bsDatepicker" bsDatepicker [bsConfig]="bsConfig">
                  </div>
                </div>
              </div>

              <!-- input quantidade de adultos -->
              <div class="col-lg-2 col-xl-2 col-md-4 col-sm-12">
                <div>
                  <label class="form-label mb-1">
                    <small><span class="text-danger">*</span> Acima de 12 anos</small>
                  </label>
                  <div class="btn-group d-grid gap-2" dropdown>
                    <button id="button-adultos" dropdownToggle type="button"
                      class="form-control btn btn-light dropdown-toggle" id="adultos" aria-controls="dropdown-adultos">
                      {{ quantAdultoT }}
                    </button>
                    <ul id="dropdown-adultos" *dropdownMenu class="dropdown-menu" role="menu"
                      aria-labelledby="button-adultos">
                      <li role="menuitem" *ngFor="let lista of listaAdultos">
                        <a class="dropdown-item" [ngClass]="lista.id === adultos ? 'disabled' : ''"
                          (click)="isAdulto(lista.id)">{{
                          lista.id }} {{ lista.id == 1 ?
                          lista.nomesingle : lista.nomeplural }}</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <!-- input quantidade de jovens -->
              <div class="col-lg-2 col-xl-2 col-md-4 col-sm-12">
                <div>
                  <label class="form-label mb-1">
                    <small>Entre 08 e 12 anos</small>
                  </label>
                  <div class="btn-group d-grid gap-2" dropdown>
                    <button id="button-jovens" dropdownToggle type="button" [disabled]="disabled"
                      class="form-control btn btn-light dropdown-toggle" aria-controls="dropdown-jovens">
                      {{ quantJovensT }} <span class="caret"></span>
                    </button>
                    <ul id="dropdown-jovens" *dropdownMenu class="dropdown-menu" role="menu"
                      aria-labelledby="button-jovens">
                      <li role="menuitem" *ngFor="let lista of listaJovens">
                        <a class="dropdown-item" [ngClass]="lista.id === jovens ? 'disabled' : ''"
                          (click)="isJovens(lista.id)">{{ lista.id }} {{ lista.id == 1 || lista.id === 0 ?
                          lista.nomesingle : lista.nomeplural }}</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <!-- input quantidade de criancas -->
              <div class="col-lg-2 col-xl-2 col-md-4 col-sm-12">
                <div>
                  <label class="form-label mb-1">
                    <small>Abaixo de 08 anos</small>
                  </label>
                  <div class="btn-group d-grid gap-2" dropdown>
                    <button id="button-criancas" dropdownToggle type="button" [disabled]="disabled"
                      class="form-control btn btn-light dropdown-toggle" aria-controls="dropdown-criancas">
                      {{ quantCriancaT }} <span class="caret"></span>
                    </button>
                    <ul id="dropdown-criancas" *dropdownMenu class="dropdown-menu" role="menu"
                      aria-labelledby="button-criancas">
                      <li role="menuitem" *ngFor="let lista of listaCriancas">
                        <a class="dropdown-item" [ngClass]="lista.id === criancas ? 'disabled' : ''"
                          (click)="isCriancas(lista.id)">{{ lista.id }} {{ lista.id == 1 || lista.id === 0?
                          lista.nomesingle : lista.nomeplural }}</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <!-- button submit -->
              <div class="col-lg-2 col-xl-2 col-md-4 col-sm-12">
                <div>
                  <label class="form-label mb-1">
                    <small>Dados Informados</small>
                  </label>
                  <div class="d-grid gap-2">
                    <button type="submit" class="btn btn-light default-btn"
                      [disabled]="form.pristine || form.invalid"><i class="flaticon-right"></i>Pesquisar...</button>
                  </div>
                </div>
              </div>
            </div>
            <!-- </alert> -->

          </form>
        </div>
      </div>
    </div>
  </div>
</section>
