import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { EPIGRAFO_API } from "src/app/share/api";
import { ResultV1Model } from "src/app/share/models/result-v1.model";
import { UtilsService } from "src/app/share/utils.service";

@Injectable({
  providedIn: 'root'
})
export class GetPaymentV1Service {

  constructor(
    private http: HttpClient,
    private readonly utilsService: UtilsService
  ) { }

  getPayment(
    codigo: string,
    grupo: string
  ) {
    return this.http.get<ResultV1Model>(
      `${EPIGRAFO_API}/v1/urlpayment/${codigo}/${grupo}`,
      { headers: this.utilsService.composeHeader() }
    );
  }
}
