<div class="card mb-4 mt-4 p-0 margin-top-area">
  <div class="card-body text-bg-success">
    <h5 class="card-title m-0">{{ reservaEscolhida?.titleSelecionado }}</h5>
  </div>
  <ul class="list-group list-group-flush">
    <li class="list-group-item card-body d-flex flex-column position-relative z-index-2">
      <p class="card-text m-0">Todos os hospedes tem acesso ao <strong>Metropolitan Park</strong>.</p>
      <div class="shaperight"><img src="../../../assets/imgs/shape-metropolitan.png" class="tm" alt="image"></div>
    </li>
    <li class="list-group-item">
      <div class="row">
        <div class="col align-self-start">
          Check-in
        </div>
        <div class="col align-self-end">
          <p class="text-end mb-0"><strong>{{ reservaEscolhida?.dtInicio }}</strong></p>
        </div>
      </div>
    </li>
    <li class="list-group-item">
      <div class="row">
        <div class="col align-self-start">
          Check-out
        </div>
        <div class="col align-self-end">
          <p class="text-end mb-0"><strong>{{ reservaEscolhida?.dtFinal }}</strong></p>
        </div>
      </div>
    </li>
    <li class="list-group-item">
      <div class="row">
        <div class="col align-self-start">
          Valor diária
        </div>
        <div class="col align-self-end">
          <p class="text-end mb-0"><strong>{{ reservaEscolhida?.isCupom ? formatter(reservaEscolhida?.valorDiariaCupom) : formatter(reservaEscolhida?.valorDiaria) }}</strong></p>
        </div>
      </div>
    </li>
    <li class="list-group-item">
      <div class="row">
        <div class="col align-self-start">
          Valor Total
        </div>
        <div class="col align-self-end">
          <p class="text-end mb-0 fs-5"><strong>{{ reservaEscolhida?.isCupom ? formatter(reservaEscolhida?.totalReservaCupom) : formatter(reservaEscolhida?.totalReserva) }}</strong></p>
        </div>
      </div>
    </li>
    <!-- Cupom -->
    <ng-container *ngIf="reservaEscolhida?.isCupom">
      <li class="list-group-item bgcupom" *ngIf="reservaEscolhida?.cupom.tpcupom">
        <div class="row">
          <div class="col align-self-start">
            Cupom
          </div>
          <div class="col align-self-end">
            <p class="text-end mb-0"><strong>{{ reservaEscolhida?.cupom.porcent }}%</strong></p>
          </div>
        </div>
      </li>
      <li class="list-group-item bgcupom" *ngIf="!reservaEscolhida?.cupom.tpcupom">
        <div class="row">
          <div class="col align-self-start">
            Cupom
          </div>
          <div class="col align-self-end">
            <p class="text-end mb-0"><strong>{{ formatter(reservaEscolhida?.cupom.valor) }}</strong></p>
          </div>
        </div>
      </li>
    </ng-container>

    <li class="list-group-item">
      <div class="row">
        <div class="col align-self-start">
          Quarto(s)
        </div>
        <div class="col align-self-end">
          <p class="text-end mb-0"><strong>{{ reservaEscolhida?.quantQuartos }}</strong></p>
        </div>
      </div>
    </li>
    <li class="list-group-item">
      <div class="row">
        <div class="col align-self-start">
          Diárias
        </div>
        <div class="col align-self-end">
          <p class="text-end mb-0"><strong>{{ reservaEscolhida?.quantasDiarias }}</strong></p>
        </div>
      </div>
    </li>
    <li class="list-group-item">
      <div class="row">
        <div class="col align-self-start">
          Adulto(s)
        </div>
        <div class="col align-self-end">
          <p class="text-end mb-0"><strong>{{ reservaEscolhida?.totalAdultos }}</strong></p>
        </div>
      </div>
    </li>
    <li class="list-group-item pdlistbt pdlistdescjoven" *ngIf="reservaEscolhida?.totalJovens != 0">
      <div class="row">
        <div class="col align-self-start">
          Jovens
        </div>
        <div class="col align-self-end">
          <p class="text-end mb-0"><strong>{{ reservaEscolhida?.totalJovens }}</strong></p>
        </div>
      </div>
    </li>
    <li class="list-group-item pdlistdescjoven pdlisttp" *ngIf="reservaEscolhida?.totalJovens != 0">
      <div class="col align-self-start">
        <span style="font-size: 0.8rem; ">entre 08 à 12 anos</span>
      </div>
    </li>
    <li class="list-group-item pdlistbt pdlistdesccrianca" *ngIf="reservaEscolhida?.totalCriancas != 0">
      <div class="row">
        <div class="col align-self-start">
          Crianças
        </div>
        <div class="col align-self-end">
          <p class="text-end mb-0"><strong>{{ reservaEscolhida?.totalCriancas }}</strong></p>
        </div>
      </div>
    </li>
    <li class="list-group-item pdlistdesccrianca pdlisttp" *ngIf="reservaEscolhida?.totalCriancas != 0">
      <div class="col align-self-start">
        <span style="font-size: 0.8rem; ">abaixo de 08 anos</span>
      </div>
    </li>
  </ul>
</div>
