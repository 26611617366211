import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-banner',
  templateUrl: './home-banner.component.html',
  styleUrls: ['./home-banner.component.scss'],
})
export class HomeBannerComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  mainBannerContent: Content[] = [
    {
      title: 'Tradição, conforto e tranquilidade',
      paragraphText:
        'No coração da maior estância hidrotermal do mundo, o hotel CTC tem satisfação em receber seus hóspedes com carinho e hospitalidade diferenciada, há mais de 40 anos. Em ambiente familiar, oferece cuidado e responsabilidade na higienização geral, além de muita área verde e água 100% minerotermal.',
      defaultBtnIcon: 'flaticon-structure',
      defaultBtnText: 'Reservas Online',
      defaultBtnLink: '#',
      videoBtnIcon: 'flaticon-google-play',
      videoBtnText: 'Veja o Vídeo',
      // videoBtnLink: 'https://www.youtube.com/watch?v=Y5KCDWi7h9o'
      videoBtnLink: 'https://www.youtube.com/watch?v=keNQwTdz3NA',
    },
  ];
}
class Content {
  title: string;
  paragraphText: string;
  defaultBtnIcon: string;
  defaultBtnText: string;
  defaultBtnLink: string;
  videoBtnIcon: string;
  videoBtnText: string;
  videoBtnLink: string;
}
