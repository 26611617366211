import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import Swal from 'sweetalert2';
import * as moment from 'moment';
declare let $: any;
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UtilsService } from 'src/app/share/utils.service';
import { ResultV1Model } from 'src/app/share/models/result-v1.model';
import { AsaasUnicoExtRefV2Model } from 'src/app/pagamentos/model/asaas-unico-ext-ref-v2.model';
import { DadosFormPaymentV1Model } from 'src/app/pagamentos/model/dados-form-payment-v1.model';
import { TemaParcelasModel } from 'src/app/pagamentos/model/tema-parcelas.model';
import { PostPaymentV1Service } from 'src/app/pagamentos/service/post-payment-v1.service';
import { DadosReservaV2Model } from 'src/app/reservas/models/dados-reserva-v2.model';
import { NewPaymentOnlineTemaAsaasV2Model, NewReservaOnlineTemaV2Model } from 'src/app/pagamentos/model/new-payment-online-tema-asaas-v2.model';
import { GetIpAddressService } from 'src/app/share/utils/get-ip-address.service';
import { DadosReservasHospedeV1Model } from 'src/app/reservas/models/dados-reservas-hospede-v1.model';
import { PaymentAsaasUnicaV2Model } from 'src/app/pagamentos/model/payment-asaas-unica-v2.model';
import { LancaAntecipaModel } from 'src/app/pagamentos/model/lanca-antecipa.model';
import { Etapa01NewPaymentOnlineTemaAsaasV2Model } from 'src/app/pagamentos/model/etapa01-new-payment-online-tema-asaas-v2.model';
import { NewCustomersAsaasV2Model } from 'src/app/pagamentos/model/new-customers-asaas-v2.model';

@Component({
  selector: 'app-dados-pagamento-online',
  templateUrl: './dados-pagamento-online.component.html',
  styleUrls: ['./dados-pagamento-online.component.scss']
})
export class DadosPagamentoOnlineComponent implements OnInit {

  /** Variaveis de entrada */
  @Output() eventVoltar = new EventEmitter();
  @Output() eventConcluir = new EventEmitter();

  /** variaveis de ambiente */
  preloader!: boolean;
  payment!: string;
  valor!: any;
  ncidade!: number;
  valorParcela!: any;
  parcelas!: number;
  parcelasDefinidas!: TemaParcelasModel[];
  dadosReserva: DadosReservaV2Model;
  dadosdaReservaHospede: DadosReservasHospedeV1Model;
  installmentCount: number = 1;
  installmentValue: number = 0;
  ipAddress: string;

  customer!: DadosFormPaymentV1Model;
  asaasUnico!: AsaasUnicoExtRefV2Model;

  /**
   * Define o vencimento deste pagamento
   */
  vencimento = new Date(moment(moment(new Date()).add(3, 'days').toDate()).format('YYYY-MM-DD'));

  /**
   * form pagamento
   */
  formPagamento!: FormGroup;
  submitted = false;
  formErrors: any;

  constructor(
    private fb: FormBuilder,
    private readonly utilsService: UtilsService,
    private readonly postPaymentV1Service: PostPaymentV1Service,
    private readonly getIpAddress: GetIpAddressService
  ) {
    /** Form PAGAMENTO */
    this.formPagamento = this.fb.group(
      this.getFormPagamento()
    );
  }

  // Irrelevante para a documentação
  get fpg() {
    return this.formPagamento.controls
  }

  getFormPagamento() {
    return {
      number: ['',
        Validators.compose([
          Validators.minLength(16),
          Validators.maxLength(16),
          Validators.required
        ])
      ],
      vencemes: ['',
        Validators.compose([
          Validators.minLength(2),
          Validators.maxLength(2),
          Validators.required
        ])],
      venceano: ['',
        Validators.compose([
          Validators.minLength(4),
          Validators.maxLength(4),
          Validators.required
        ])],
      ccv: ['',
        Validators.compose([
          Validators.minLength(3),
          Validators.maxLength(3),
          Validators.required
        ])]
    }
  }

  ngOnInit(): void {
    this.prencheForm(this.utilsService.getDadosPayment());
    this.dadosReserva = this.utilsService.getDadosReserva();
    this.processAsassUnico();
    this.parcelas = 1;
    this.getIp();
  }

  async getIp() {
    await this.getIpAddress.getIPAddress()
      .subscribe(
        (resultado: any) => {
          this.ipAddress = resultado.ip;
        }
      );
  }

  processAsassUnico() {
    let valor: number = this.dadosReserva.isCupom ? this.dadosReserva.totalReservaCupom : this.dadosReserva.totalReserva;
    this.asaasUnico = new AsaasUnicoExtRefV2Model(
      undefined,
      false,
      true,
      valor,
      'Indefinida',
      'Indefinida',
      this.vencimento,
      undefined
    )
    this.valor = this.formatValue(valor);
    this.valorParcela = `1x de ${this.formatValue(valor)}`;
    this.processaCartao(valor);
  }

  processaCartao(
    valor: number
  ) {
    this.parcelasDefinidas = [];

    /**
     * processando parcelas
     */
    let loop: number = this.calculaParcela(valor);
    for (let index: number = 1; index <= loop; index++) {
      this.parcelasDefinidas.push({
        number: index,
        total: valor / index
      })
    }
  }

  prencheForm(
    customer: DadosFormPaymentV1Model
  ) {
    this.customer = {
      nome: customer.nome,
      cpf: customer.cpf,
      logradouro: customer.logradouro,
      email: customer.email,
      complemento: customer.complemento,
      numero: customer.numero,
      bairro: customer.bairro,
      cep: customer.cep,
      cidade: customer.cidade,
      estado: customer.estado,
      fonefixo: customer.fonefixo,
      fonemovel: customer.fonemovel,
    };
    this.ncidade = customer.ncidade;
  }

  voltaDadosPagador() {
    this.eventVoltar.emit(false);
  }

  async onSubmitPagamento() {

    this.submitted = true;

    if (this.formPagamento.invalid) {
      return;
    }

    this.isPreloader(true);

    let newPayment = new NewPaymentOnlineTemaAsaasV2Model(
      {
        codigo: undefined,
        nome: this.customer.nome,
        email: this.customer.email,
        datanascimento: moment(this.dadosReserva.dtInicio, 'DD/MM/YYYY').format("YYYY-MM-DD"),
        cpfcgc: this.customer.cpf,
        fonecelular: this.customer.fonemovel,
        cep: this.customer.cep,
        logradouro: this.customer.logradouro,
        numero: this.customer.numero,
        complemento: this.customer.complemento,
        bairro: this.customer.bairro,
        cidade: this.ncidade,
        ncidade: this.customer.cidade,
        estado: this.customer.estado
      },
      {
        idreserva: undefined,
        codigo: undefined,
        cpf: this.customer.cpf,
        nome: this.customer.nome,
        usunome: this.customer.nome,
        aptos: this.dadosReserva.quantQuartos,
        paxpg: this.dadosReserva.totalAdultos,
        paxcp: this.dadosReserva.totalJovens,
        paxcn: this.dadosReserva.totalCriancas,
        diaria: this.dadosReserva.isCupom ? this.dadosReserva.valorDiariaCupom : this.dadosReserva.valorDiaria,
        dataentrada: moment(this.dadosReserva.dtInicio, 'DD/MM/YYYY').format("YYYY-MM-DD"),
        datasaida: moment(this.dadosReserva.dtFinal, 'DD/MM/YYYY').format("YYYY-MM-DD"),
        tipoapto: '02',
        acao: undefined,
        dtconf: undefined,
        fone: this.customer.fonemovel,
        email: this.customer.email,
        regime: this.regime(this.dadosReserva)
      },
      this.asaasUnico,
      undefined,
      {
        name: this.customer.nome,
        cpfCnpj: this.customer.cpf,
        email: this.customer.email,
        phone: this.customer.fonefixo ? this.customer.fonefixo : this.customer.fonemovel,
        mobilePhone: this.customer.fonemovel,
        addressNumber: this.customer.numero,
        postalCode: this.customer.cep,
        notificationDisabled: true,
        groupName: 'HotelCTC',
        externalReference: undefined,
        complement: this.customer.complemento ? this.customer.complemento : '',
      },
      {
        installmentCount: this.installmentCount,
        totalValue: this.asaasUnico.valor
      },
      {
        holderName: this.customer.nome,
        number: this.formPagamento.controls['number'].value,
        expiryMonth: this.formPagamento.controls['vencemes'].value,
        expiryYear: this.formPagamento.controls['venceano'].value,
        ccv: this.formPagamento.controls['ccv'].value
      },
      this.dadosReserva,
      await this.ipAddress
    )

    let etapa01Payment = new Etapa01NewPaymentOnlineTemaAsaasV2Model(
      {
        codigo: undefined,
        nome: this.customer.nome,
        email: this.customer.email,
        datanascimento: moment(this.dadosReserva.dtInicio, 'DD/MM/YYYY').format("YYYY-MM-DD"),
        cpfcgc: this.customer.cpf,
        fonecelular: this.customer.fonemovel,
        cep: this.customer.cep,
        logradouro: this.customer.logradouro,
        numero: this.customer.numero,
        complemento: this.customer.complemento,
        bairro: this.customer.bairro,
        cidade: this.ncidade,
        ncidade: this.customer.cidade,
        estado: this.customer.estado
      },
      {
        idreserva: undefined,
        codigo: undefined,
        cpf: this.customer.cpf,
        nome: this.customer.nome,
        usunome: this.customer.nome,
        aptos: this.dadosReserva.quantQuartos,
        paxpg: this.dadosReserva.totalAdultos,
        paxcp: this.dadosReserva.totalJovens,
        paxcn: this.dadosReserva.totalCriancas,
        diaria: this.dadosReserva.isCupom ? this.dadosReserva.valorDiariaCupom : this.dadosReserva.valorDiaria,
        dataentrada: moment(this.dadosReserva.dtInicio, 'DD/MM/YYYY').format("YYYY-MM-DD"),
        datasaida: moment(this.dadosReserva.dtFinal, 'DD/MM/YYYY').format("YYYY-MM-DD"),
        tipoapto: '02',
        acao: undefined,
        dtconf: undefined,
        fone: this.customer.fonemovel,
        email: this.customer.email,
        regime: this.regime(this.dadosReserva)
      }
    )

    await this.postPaymentV1Service
      .postPaymentEtapa01(etapa01Payment)
      .subscribe(
        async (resultado: ResultV1Model) => {
          if (resultado.success) {
            newPayment.externalReference = resultado.data;
            await this.etapa02(
              newPayment
            );
          } else {
            this.isPreloader(false);
            setTimeout(() => {
              this.sweetModel(resultado);
            }, 800);
          }
        }
      );
  }

  async etapa02(
    newPayment: NewPaymentOnlineTemaAsaasV2Model
  ) {
    let newCustomers: NewCustomersAsaasV2Model = newPayment.newCustomer;
    await this.postPaymentV1Service
      .postPaymentEtapa02(newCustomers)
      .subscribe(
        async (resultado: ResultV1Model) => {
          if (resultado.success) {
            newPayment.newCustomer = resultado.data;
            await this.etapaFinal(
              newPayment
            );
          } else {
            this.isPreloader(false);
            setTimeout(() => {
              this.sweetModel(resultado);
            }, 800);
          }
        }
      );
  }

  async etapaFinal(
    newPayment: NewPaymentOnlineTemaAsaasV2Model
  ) {
    await this.postPaymentV1Service
      .postPaymentFinal(newPayment)
      .subscribe(
        async (resultado: ResultV1Model) => {
          if (resultado.success) {
            let retornoEvent: any = {
              resultado: resultado.data,
              dadosReserva: this.dadosReserva
            }
            this.eventConcluir.emit(retornoEvent);
          } else {
            this.isPreloader(false);
            setTimeout(() => {
              this.sweetModel(resultado);
            }, 800);
          }
        }
      );
  }

  regime(
    dadosReserva: DadosReservaV2Model
  ) {
    let regime: number = 0;
    dadosReserva.cafeDaManha ? regime = 1 : null;
    dadosReserva.meiaPensao ? regime = 2 : null;
    dadosReserva.pensaoCompleta ? regime = 3 : null;
    return regime;
  }

  // modal de possíveis erros
  sweetModel(err: ResultV1Model) {
    Swal.fire({
      title: `${err.titulo}`,
      text: `${err.message}`,
      icon: 'warning',
      backdrop: `rgba(73,80,87,0.50)`
    });
  }

  telefone(rawNum: string) {
    rawNum = rawNum.replace(/[^a-zA-Z0-9]/g, '');
    if (rawNum.length == 10) {
      const areaCode = rawNum.slice(0, 2);
      const oneTre = rawNum.slice(2, 6);
      const toTre = rawNum.slice(6, 10);
      return `(${areaCode}) ${oneTre}-${toTre}`;
    } else {
      const areaCode = rawNum.slice(0, 2);
      const oneTre = rawNum.slice(2, 5);
      const toTre = rawNum.slice(5, 8);
      const treTre = rawNum.slice(8, 11);
      return `(${areaCode}) ${oneTre}-${toTre}-${treTre}`;
    }
  }

  telefoneCelular(rawNum: string, cel: boolean) {
    rawNum = rawNum.replace(/[^a-zA-Z0-9]/g, '');
    if (rawNum.length == 10) {
      if (cel) {
        const areaCode = rawNum.slice(0, 2);
        const oneTre = rawNum.slice(2, 6);
        const toTre = rawNum.slice(6, 10);
        return `${areaCode}9${oneTre}${toTre}`;
      } else {
        const areaCode = rawNum.slice(0, 2);
        const oneTre = rawNum.slice(2, 6);
        const toTre = rawNum.slice(6, 10);
        return `${areaCode}${oneTre}${toTre}`;
      }
    } else {
      const areaCode = rawNum.slice(0, 2);
      const oneTre = rawNum.slice(2, 5);
      const toTre = rawNum.slice(5, 8);
      const treTre = rawNum.slice(8, 11);
      return `${areaCode}${oneTre}${toTre}${treTre}`;
    }
  }

  onCountryChanged(event: any) {
    this.valorParcela = `${event.number}x de ${this.formatValue(event.total)}`;
    this.installmentCount = parseInt(event.number);
  }

  /**
   * Referente ao percelamento do valor total da tarifa
   * em ate 6x dependendo do valor
   */
  calculaParcela(valor: number) {
    let quantParcela: number = 0;

    /** avaliando o valor e definindo o parcelamento possivel */
    /** maior ou igual a 1200 */
    valor >= 1200 ? quantParcela = 6 : null;

    /** maior ou igual a 1000 ou menor ou igual a 1199 */
    valor >= 1000 && valor <= 1199 ? quantParcela = 5 : null;

    /** maior ou igual a 800 ou menor ou igual a 999 */
    valor >= 800 && valor <= 999 ? quantParcela = 4 : null;

    /** maior ou igual a 600 ou menor ou igual a 799 */
    valor >= 600 && valor <= 799 ? quantParcela = 3 : null;

    /** menor ou igual a 400 ou menor ou igual a 599 */
    valor >= 400 && valor <= 599 ? quantParcela = 2 : null;

    /** menor ou igual a 399 */
    valor <= 399 ? quantParcela = 1 : null;

    return quantParcela;
  }

  formatValue(price: number) {
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(price);
  }

  isPreloader(status: boolean) {
    this.preloader = status;
    status
      ? /**
         * start preloader
         */
      $('.preloader').fadeIn('slow')
      : /**
         * stop preloader
         */
      ($.getScript('../assets/js/main.js'), $('.preloader').fadeOut('slow'));
  }
}
