import { Component } from '@angular/core';
import {
  faBowlingBall,
  faCar,
  faFilm,
  faGamepad,
  faGlassMartini,
  faShoppingBag,
  faUtensils,
  faWalking,
} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-atrativos',
  templateUrl: './atrativos.component.html',
  styleUrls: ['./atrativos.component.scss'],
})
export class AtrativosComponent {
  //ícones da aba Atrativos
  faFilm = faFilm;
  faGamepad = faGamepad;
  faShoppingBag = faShoppingBag;
  faBowlingBall = faBowlingBall;
  faGlassMartini = faGlassMartini;
  faCar = faCar;
  faUtensils = faUtensils;

  faWalking = faWalking;
}
