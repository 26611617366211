import { Component, OnInit } from '@angular/core';
declare let $: any;
import { ActivatedRoute, Router } from '@angular/router';
import { GetPaymentV1Service } from './service/get-payment-v1.service';
import { ResultV1Model } from '../share/models/result-v1.model';
import { TemaReservaV1Model } from './model/tema-reserva-v1.model';
import { TemaHospedeV1Model } from './model/tema-hospede-v1.model';
import { DadosFormPaymentV1Model } from './model/dados-form-payment-v1.model';
import { AsaasUnicoExtRefV2Model } from './model/asaas-unico-ext-ref-v2.model';
import { PaymentAsaasUnicaV2Model } from './model/payment-asaas-unica-v2.model';
import { ExternalReferenceV2Model } from './model/external-referece-v2.model';

@Component({
  selector: 'app-pagamentos',
  templateUrl: './pagamentos.component.html',
  styleUrls: ['./pagamentos.component.scss']
})
export class PagamentosComponent implements OnInit {

  /** variaveis de ambiente */
  preloader!: boolean;
  asaasUnico!: AsaasUnicoExtRefV2Model;
  isCarrega: boolean = false;
  isMsn: boolean = false;
  isCodigo: boolean = false;
  isNotReserva: boolean = false;
  isCustomer: boolean = false;
  isConfirmado: boolean = false;
  isConcluir: boolean = false;
  counter: number = 7;

  temaReserva!: TemaReservaV1Model;
  temaHospede!: TemaHospedeV1Model;
  customer!: DadosFormPaymentV1Model;
  externalReference!: ExternalReferenceV2Model;

  // etapas do pagamento
  isReserva: boolean = false;
  dadosCustomers: boolean = false;
  isDadosCustomers: boolean = false;
  resultadoPayment!: PaymentAsaasUnicaV2Model;

  constructor(
    private route: Router,
    private router: ActivatedRoute,
    private readonly getPaymentV1Service: GetPaymentV1Service
  ) {
    this.isPreloader(true);
  }

  ngOnInit(): void {
    /** codigo URL de identificação do pagamento */
    this.router.snapshot.params['codigo'] && this.router.snapshot.params['grupo'] ?
      this.getExtRefCodigo(
        this.router.snapshot.params['codigo'],
        this.router.snapshot.params['grupo']
      ) : this.notCodigo();
  }

  async getExtRefCodigo(codigo: string, grupo: string) {
    await this.getPaymentV1Service
      .getPayment(codigo, grupo)
      .subscribe(
        (resultado: ResultV1Model) => {
          this.isCarrega = true;
          if (resultado.success) {
            this.temaReserva = resultado.data.reserva;
            this.asaasUnico = resultado.data.asaasUnico;
            this.externalReference = resultado.data.externalReference;
            if (this.asaasUnico != null) {
              if (this.asaasUnico.status == 'CONFIRMED') {
                this.isMsn = true;
                this.isConfirmado = true;
                this.isCodigo = false;
                this.isNotReserva = false;
                this.isReserva = false;
              } else {
                this.isReserva = true;
                this.dadosCustomers = true;
              }
            } else {
              this.isMsn = true;
              this.isConfirmado = false;
              this.isCodigo = false;
              this.isNotReserva = true;
              this.isReserva = false;
            }
          } else {
            this.isCodigo = false;
            this.isNotReserva = false;
          }
          this.isPreloader(false);
        }
      );
  }

  returnDadosResponsavelPagamento(event: any) {
    this.dadosCustomers = !event.dadosCustomers;
    this.isDadosCustomers = event.dadosCustomers
  }

  returnVoltarResponsavelPagamento() {
    this.isCustomer = true;
    this.dadosCustomers = true;
    this.isDadosCustomers = false;
  }

  concluiPayment(event: any) {
    this.isConcluir = true;
    this.dadosCustomers = false;
    this.isDadosCustomers = false;
    this.resultadoPayment = event.pagamento;
    this.isPreloader(false);
  }

  notCodigo() {
    this.isCarrega = true;
    this.isMsn = true;
    this.isCodigo = true;
    this.isPreloader(false);

    setTimeout(() => {
      this.route.navigate([`/`]);
    }, 10000);
  }


  isPreloader(status: boolean) {
    this.preloader = status;
    status
      ? /**
         * start preloader
         */
        $('.preloader').fadeIn('slow')
      : /**
         * stop preloader
         */
        ($.getScript('../assets/js/main.js'), $('.preloader').fadeOut('slow'));
  }
}
