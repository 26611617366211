import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gallery-restaurante',
  templateUrl: './gallery-restaurante.component.html',
  styleUrls: ['./gallery-restaurante.component.scss'],
})
export class GalleryRestauranteComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  pageTitleArea: pageTitle[] = [
    {
      title: 'Gallery',
    },
  ];
  galleryImages: Image[] = [
    {
      img: 'assets/img/gallery/restaurante/_DSF3874.jpg',
    },
    {
      img: 'assets/img/gallery/restaurante/_DSF3873.jpg',
    },
    {
      img: 'assets/img/gallery/restaurante/_DSF3869.jpg',
    },
    {
      img: 'assets/img/gallery/restaurante/_DSF3868.jpg',
    },
    {
      img: 'assets/img/gallery/restaurante/_DSF3864.jpg',
    },
    {
      img: 'assets/img/gallery/restaurante/_DSF3838.jpg',
    },
    {
      img: 'assets/img/gallery/restaurante/_DSF3846.jpg',
    },
    {
      img: 'assets/img/gallery/restaurante/_DSF3903.jpg',
    },
    {
      img: 'assets/img/gallery/restaurante/_DSF3887.jpg',
    },
  ];
}
class pageTitle {
  title: string;
}
class Image {
  img: string;
}
