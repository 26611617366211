import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BsModalRef, ModalDirective } from 'ngx-bootstrap/modal';
import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import Swal from 'sweetalert2';
declare let $: any;
import { ResultV1Model } from 'src/app/share/models/result-v1.model';
import { CalculaReservaV1Service } from '../events/calcula-reserva-v1.service';
import { ConsultaReservaModel } from '../models/consulta-reserva.model';
import { DisponibilidadeMapadiarioV1 } from '../models/disponibilidade-mapadiario-v1.model';
import { DropdrownReservaModel } from '../models/dropdown-reserva.model';
import {
  ReservaPeriodoV1Model,
  ReservaV1Model,
} from '../models/reserva-v1.model';
import { ReservaV1Service } from '../services/reserva-v1.service';
import { DadosOrganizaQuartosService } from '../events/dados-organiza-quartos.service';

@Component({
  selector: 'app-form-reserva',
  templateUrl: './form-reserva.component.html',
  styleUrls: ['./form-reserva.component.scss'],
})
export class FormReservaComponent implements OnInit {

  /** variaveis de retorno */
  @Output() eventIndisponibilidade = new EventEmitter<any>();
  @Output() eventDisponibilidade = new EventEmitter<any>();
  @Output() eventOrganizaQuartos = new EventEmitter<any>();

  /**
   * variaveis modal
   */
  modalRef?: BsModalRef;
  configmd = {
    keyboard: true,
    ignoreBackdropClick: true,
    class: 'modal-dialog-centered modal-sm',
  };
  @ViewChild('childModal', { static: false }) childModal?: ModalDirective;

  showChildModal(): void {
    this.childModal?.show();
  }

  hideChildModal(): void {
    this.childModal?.hide();
  }

  /**
   * variáveis do component
   */
  form!: FormGroup;
  submitted = false;
  formErrors: any;

  /**
   * datapicker config
   */
  bsConfig = {
    dateInputFormat: 'DD/MM/YYYY',
    adaptivePosition: true,
    showWeekNumbers: false,
    returnFocusToInput: true,
    containerClass: 'theme-orange',
  };
  iniDate?: Date = new Date();
  minDate?: Date;
  maxDate: Date = new Date(moment("2024-12-30", 'YYYY-MM-DD').format('YYYY-MM-DD'));


  // console.log(moment(value, 'YYYY-MM-DD').format('YYYY-MM-DD'));
  // console.log("2023-12-31")

  /** variaveis de ambiente */
  preloader!: boolean;
  quartos: number = 1;
  adultos: number = 2;
  listaAdultos?: DropdrownReservaModel[];
  quantAdultoT: string = '2 Adultos';
  jovens: number = 0;
  listaJovens?: DropdrownReservaModel[];
  quantJovensT: string = '0 Jovens';
  criancas: number = 0;
  listaCriancas?: DropdrownReservaModel[];
  quantCriancaT: string = '0 Crianças';
  disabled: boolean = false;
  reservaanterior?: ReservaPeriodoV1Model;
  isDisponibilidade: boolean = true;
  disponibilidade!: DisponibilidadeMapadiarioV1[];
  tarifasNaoLocalizadas: boolean = false;

  constructor(
    private route: Router,
    private fb: FormBuilder,
    private readonly reservaV1Service: ReservaV1Service,
    private readonly calculaReservaService: CalculaReservaV1Service,
    private readonly dadosOrganizaQuartosService: DadosOrganizaQuartosService
  ) {
    // this.maxDate.setDate(this.maxDate.getDate())
  }

  ngOnInit(): void {
    this.form = this.fb.group(this.getFormReserva());
    this.iniDate = new Date();
    this.listaJovens = [
      { id: 0, nomesingle: 'Nenhuma criança', nomeplural: '' },
      { id: 1, nomesingle: 'Criança', nomeplural: '' },
      { id: 2, nomesingle: '', nomeplural: 'Crianças' },
      { id: 3, nomesingle: '', nomeplural: 'Crianças' },
      { id: 4, nomesingle: '', nomeplural: 'Crianças' },
      { id: 5, nomesingle: '', nomeplural: 'Crianças' },
      { id: 6, nomesingle: '', nomeplural: 'Crianças' },
      { id: 7, nomesingle: '', nomeplural: 'Crianças' },
      { id: 8, nomesingle: '', nomeplural: 'Crianças' },
      { id: 9, nomesingle: '', nomeplural: 'Crianças' },
    ];
    this.listaAdultos = [
      { id: 1, nomesingle: 'Adulto', nomeplural: '' },
      { id: 2, nomesingle: '', nomeplural: 'Adultos' },
      { id: 3, nomesingle: '', nomeplural: 'Adultos' },
      { id: 4, nomesingle: '', nomeplural: 'Adultos' },
      { id: 5, nomesingle: '', nomeplural: 'Adultos' },
      { id: 6, nomesingle: '', nomeplural: 'Adultos' },
      { id: 7, nomesingle: '', nomeplural: 'Adultos' },
      { id: 8, nomesingle: '', nomeplural: 'Adultos' },
      { id: 9, nomesingle: '', nomeplural: 'Adultos' },
      { id: 10, nomesingle: '', nomeplural: 'Adultos' },
      { id: 11, nomesingle: '', nomeplural: 'Adultos' },
      { id: 12, nomesingle: '', nomeplural: 'Adultos' },
    ];
    this.listaCriancas = [
      { id: 0, nomesingle: 'Nenhuma criança', nomeplural: '' },
      { id: 1, nomesingle: 'Criança', nomeplural: '' },
      { id: 2, nomesingle: '', nomeplural: 'Crianças' },
      { id: 3, nomesingle: '', nomeplural: 'Crianças' },
      { id: 4, nomesingle: '', nomeplural: 'Crianças' },
    ];
  }

  // Irrelevante para a documentação
  get f() {
    return this.form.controls;
  }

  getFormReserva() {
    return {
      dtentrada: ['', Validators.compose([Validators.required])],
      dtsaida: ['', Validators.compose([Validators.required])],
      adultos: [''],
      jovens: [''],
      criancas: [''],
    };
  }

  isAdulto(quantA: number) {
    this.quantAdultoT = quantA == 1 ? `${quantA} Adulto` : `${quantA} Adultos`;
    if (quantA <= 3) {
      this.contJovens(3);
    }
    quantA == 1 ? this.contJovens(9) : null;
    quantA == 2 ? this.contJovens(9) : null;
    quantA == 3 ? this.contJovens(9) : null;
    quantA == 4 ? this.contJovens(8) : null;
    quantA == 5 ? this.contJovens(7) : null;
    quantA == 6 ? this.contJovens(6) : null;
    quantA == 7 ? this.contJovens(5) : null;
    quantA == 8 ? this.contJovens(4) : null;
    quantA == 9 ? this.contJovens(3) : null;
    quantA == 10 ? this.contJovens(2) : null;
    quantA == 11 ? this.contJovens(1) : null;
    quantA == 12 ? this.contJovens(0) : null;

    this.adultos = quantA;
  }

  contJovens(cont: number) {
    this.listaJovens = [];
    if (cont != 0) {
      for (let index = 1; index <= cont; index++) {
        if (index === 1) {
          this.listaJovens?.push({
            id: 0,
            nomesingle: 'Nenhum jovem',
            nomeplural: 'null',
          });
        }
        this.listaJovens?.push({
          id: index,
          nomesingle: index == 1 ? 'Jovem' : '',
          nomeplural: index != 1 ? 'Jovens' : 'null',
        });
      }
      this.disabled = false;
      this.quantJovensT = '0 Jovens';
      this.quantCriancaT = '0 Crianças';
    } else {
      this.disabled = true;
      this.quantJovensT = '0 Jovens';
    }
    this.jovens = 0;
    this.criancas = 0;
  }

  contAdultos(cont: number) {
    this.listaAdultos = [];
    for (let index = 1; index <= cont; index++) {
      this.listaAdultos?.push({
        id: index,
        nomesingle: index == 1 ? 'Adulto' : '',
        nomeplural: index != 1 ? 'Adultos' : 'null',
      });
    }
  }

  isJovens(quantJ: number) {
    this.quantJovensT = quantJ == 1 ? `${quantJ} jovem` : `${quantJ} jovens`;
    this.jovens = quantJ;
  }

  isCriancas(quantC: number) {
    this.quantCriancaT =
      quantC == 1 ? `${quantC} criança` : `${quantC} crianças`;
    this.criancas = quantC;
  }

  async onSubmit() {
    this.submitted = true;

    if (this.form.invalid) {
      return;
    }

    this.isPreloader(true);

    let consultaReserva: ConsultaReservaModel = {
      dataentrada: this.form.value['dtentrada'],
      datasaida: this.form.value['dtsaida'],
      adultos: this.adultos,
      jovens: this.jovens,
      criancas: this.criancas,
      quartos: this.quartos,
      cupom: this.form.value['cupom'],
    };

    let isConsulta: boolean = false;

    !this.reservaanterior?.isReserva
      ? (isConsulta = true)
      : (isConsulta = false);

    if (this.reservaanterior?.isReserva) {
      if (
        this.reservaanterior.datainicio ===
          moment(this.form.value['dtentrada'], 'YYYY-MM-DD').format(
            'YYYY-MM-DD'
          ) &&
        this.reservaanterior.datafinal ===
          moment(this.form.value['dtsaida'], 'YYYY-MM-DD').format('YYYY-MM-DD')
      ) {
        isConsulta = false;
        this.tarifasNaoLocalizadas ? this.showChildModal() : null;
      } else {
        isConsulta = true;
      }
    }

    if (isConsulta) {
      this.viewOrganizaQuartos(consultaReserva);

      let reserva: ReservaV1Model = {
        isReserva: true,
        datainicio: new Date(consultaReserva.dataentrada),
        datafinal: new Date(consultaReserva.datasaida),
        quantquartos: this.quartos,
      };

      await this.reservaV1Service
        .getReservas(reserva)
        .subscribe((resultado: ResultV1Model) => {
          if (resultado.success) {
            let eventIndisponibilidade: any = {
              indisponivel: false,
              disponivel: true,
              disponibilidade: resultado.data,
            };
            this.eventDisponibilidade.emit(eventIndisponibilidade);
            setTimeout(() => {
              this.calculaReservaService.isCalculaReserva(
                true,
                consultaReserva,
                resultado
              );
              this.isPreloader(false);
            }, 2000);
          } else {
            if (resultado.error != null) {
              setTimeout(() => {
                this.errorApi(resultado);
                this.isPreloader(false);
              }, 1000);
            } else {
              let eventIndisponibilidade: any = {
                indisponivel: true,
                disponivel: false,
                disponibilidade: resultado.data,
              };
              this.eventIndisponibilidade.emit(eventIndisponibilidade);
              setTimeout(() => {
                this.isPreloader(false);
              }, 2000);
            }
          }
          this.reservaanterior = {
            isReserva: true,
            datainicio: moment(
              this.form.value['dtentrada'],
              'YYYY-MM-DD'
            ).format('YYYY-MM-DD'),
            datafinal: moment(this.form.value['dtsaida'], 'YYYY-MM-DD').format(
              'YYYY-MM-DD'
            ),
            quantquartos: this.quartos,
          };
        });
    } else {
      setTimeout(() => {
        this.viewOrganizaQuartos(consultaReserva);
      }, 1000);

      setTimeout(() => {
        !this.tarifasNaoLocalizadas
          ? this.calculaReservaService.isCalculaReserva(false, consultaReserva)
          : null;
        this.isPreloader(false);
      }, 2000);
    }
  }

  viewOrganizaQuartos(consultaReserva: ConsultaReservaModel) {
    if (consultaReserva.adultos + consultaReserva.jovens > 4) {
      let eventOrganizaQuartos: any = {
        organizaQuartos: true,
      };
      this.eventOrganizaQuartos.emit(eventOrganizaQuartos);
    } else {
      let eventOrganizaQuartos: any = {
        organizaQuartos: false,
      };
      this.eventOrganizaQuartos.emit(eventOrganizaQuartos);
    }
  }

  onValueChange(value: Date): void {
    this.form.controls['dtsaida'].reset();
    this.minDate = new Date(value);
    if (moment(value).day() == 5) {
      this.minDate.setDate(this.minDate.getDate() + 2);
    } else if (moment(value).day() == 6) {
      this.minDate.setDate(this.minDate.getDate() + 2);
    } else {
      this.minDate.setDate(this.minDate.getDate() + 1);
    }
  }

  isPreloader(status: boolean) {
    this.preloader = status;
    status
      ? /**
         * start preloader
         */
        $('.preloader').fadeIn('slow')
      : /**
         * stop preloader
         */
        ($.getScript('../assets/js/main.js'), $('.preloader').fadeOut('slow'));
  }

  errorApi(resultado: ResultV1Model) {
    Swal.fire({
      icon: 'warning',
      title: `${resultado.titulo}`,
      text: `${resultado.message}`,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ok!',
    }).then(() => {
      this.route.navigate([`/`]);
    });
  }
}
