import { PaymentAsaasUnicaV2Model } from "./payment-asaas-unica-v2.model";

export class AsaasUnicoExtRefV2Model {
  constructor(
    public _id: string,
    public pix: boolean,
    public cartao: boolean,
    public valor: number,
    public status: string,
    public descricao: string,
    public vencimento: Date,
    public payment: string
  ) { }
}
