import { AsaasUnicoExtRefV2Model } from "./asaas-unico-ext-ref-v2.model";
import { CreditCardAsaasV2Model } from "./credit-card-asaas-v2-model";
import { ExternalReferenceV2Model } from "./external-referece-v2.model";
import { NewCustomersAsaasV2Model } from "./new-customers-asaas-v2.model";
import { ParcelamentoAsaasV2Model } from "./parcelamento-asaas-v2.model";

export class NewPaymentAsaasV2Model {
  constructor(
    public asaasUnico: AsaasUnicoExtRefV2Model,
    public externalReference: ExternalReferenceV2Model,
    public newCustomer: NewCustomersAsaasV2Model,
    public parcelamento: ParcelamentoAsaasV2Model,
    public dadosCartao: CreditCardAsaasV2Model,
    public ipAddress: string
  ) { }
}


