import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-gallery-quartos',
  templateUrl: './gallery-quartos.component.html',
  styleUrls: ['./gallery-quartos.component.scss'],
})
export class GalleryQuartosComponent implements OnInit {
  constructor() {}
  ngOnInit(): void {}

  @Input() isTabGalery?: boolean;
  

  pageTitleArea: pageTitle[] = [
    {
      title: 'Gallery',
    },
  ];
  galleryImages: Image[] = [
    {
      img: 'assets/img/gallery/img3.jpg',
    },
    {
      img: 'assets/img/gallery/img4.jpg',
    },
    {
      img: 'assets/img/gallery/img5.jpg',
    },
    {
      img: 'assets/img/gallery/img6.jpg',
    },
    {
      img: 'assets/img/gallery/img7.jpg',
    },
    {
      img: 'assets/img/gallery/img8.jpg',
    },
    {
      img: 'assets/img/gallery/img9.jpg',
    },
  ];

  tabGalleryImages: Image[] = [
    {
      img: 'assets/img/gallery/img3.jpg',
    },
    {
      img: 'assets/img/gallery/img4.jpg',
    },
    {
      img: 'assets/img/gallery/img5.jpg',
    },
    {
      img: 'assets/img/gallery/img6.jpg',
    },
    {
      img: 'assets/img/gallery/img7.jpg',
    },
    {
      img: 'assets/img/gallery/img8.jpg',
    },
    {
      img: 'assets/img/gallery/img9.jpg',
    },
    {
      img: 'assets/img/gallery/img1.jpg',
    },
  ];
}
class pageTitle {
  title: string;
}
class Image {
  img: string;
}