<div class="card mb-4 mt-4 p-0 margin-top-area">
  <div class="card-body text-bg-success">
    <h5 class="card-title m-0">{{ titleSelecionado }}</h5>
  </div>
  <ul class="list-group list-group-flush">
    <li class="list-group-item card-body d-flex flex-column position-relative z-index-2">
      <p class="card-text m-0">Todos os hospedes tem acesso ao <strong>Metropolitan Park</strong>.</p>
      <div class="shaperight"><img src="../../../assets/imgs/shape-metropolitan.png" class="tm" alt="image"></div>
    </li>
    <li class="list-group-item">
      <div class="row">
        <div class="col align-self-start">
          Check-in
        </div>
        <div class="col align-self-end">
          <p class="text-end mb-0"><strong>{{ dataEntrada }}</strong></p>
        </div>
      </div>
    </li>
    <li class="list-group-item">
      <div class="row">
        <div class="col align-self-start">
          Check-out
        </div>
        <div class="col align-self-end">
          <p class="text-end mb-0"><strong>{{ dataSaida }}</strong></p>
        </div>
      </div>
    </li>
    <li class="list-group-item">
      <div class="row">
        <div class="col align-self-start">
          Valor diária
        </div>
        <div class="col align-self-end">
          <p class="text-end mb-0"><strong>{{ formatter(valorDiaria) }}</strong></p>
        </div>
      </div>
    </li>
    <li class="list-group-item">
      <div class="row">
        <div class="col align-self-start">
          Valor Total
        </div>
        <div class="col align-self-end">
          <p class="text-end mb-0 fs-5"><strong>{{ formatter(totalReserva) }}</strong></p>
        </div>
      </div>
    </li>
    <!-- Cupom -->
    <ng-container *ngIf="isCupom">
      <li class="list-group-item bgcupom" *ngIf="cupomDesconto.tpcupom">
        <div class="row">
          <div class="col align-self-start">
            Cupom
          </div>
          <div class="col align-self-end">
            <p class="text-end mb-0"><strong>{{ porcentagemCupom }}%</strong></p>
          </div>
        </div>
      </li>
      <li class="list-group-item bgcupom" *ngIf="!cupomDesconto.tpcupom">
        <div class="row">
          <div class="col align-self-start">
            Cupom
          </div>
          <div class="col align-self-end">
            <p class="text-end mb-0"><strong>{{ formatter(cupomDesconto.valor) }}</strong></p>
          </div>
        </div>
      </li>
    </ng-container>

    <li class="list-group-item">
      <div class="row">
        <div class="col align-self-start">
          Quarto(s)
        </div>
        <div class="col align-self-end">
          <p class="text-end mb-0"><strong>{{ quartos }}</strong></p>
        </div>
      </div>
    </li>
    <li class="list-group-item">
      <div class="row">
        <div class="col align-self-start">
          Diárias
        </div>
        <div class="col align-self-end">
          <p class="text-end mb-0"><strong>{{ quantasDiarias }}</strong></p>
        </div>
      </div>
    </li>
    <li class="list-group-item">
      <div class="row">
        <div class="col align-self-start">
          Adulto(s)
        </div>
        <div class="col align-self-end">
          <p class="text-end mb-0"><strong>{{ adultos }}</strong></p>
        </div>
      </div>
    </li>
    <li class="list-group-item pdlistbt pdlistdescjoven" *ngIf="jovens != 0">
      <div class="row">
        <div class="col align-self-start">
          Jovens
        </div>
        <div class="col align-self-end">
          <p class="text-end mb-0"><strong>{{ jovens }}</strong></p>
        </div>
      </div>
    </li>
    <li class="list-group-item pdlistdescjoven pdlisttp" *ngIf="jovens != 0">
      <div class="col align-self-start">
        <span style="font-size: 0.8rem; ">entre 08 à 12 anos</span>
      </div>
    </li>
    <li class="list-group-item pdlistbt pdlistdesccrianca" *ngIf="criancas != 0">
      <div class="row">
        <div class="col align-self-start">
          Crianças
        </div>
        <div class="col align-self-end">
          <p class="text-end mb-0"><strong>{{ criancas }}</strong></p>
        </div>
      </div>
    </li>
    <li class="list-group-item pdlistdesccrianca pdlisttp" *ngIf="criancas != 0">
      <div class="col align-self-start">
        <span style="font-size: 0.8rem; ">abaixo de 08 anos</span>
      </div>
    </li>
  </ul>
  <div class="card-body">
    <div class="btn-group" role="group" aria-label="Basic mixed styles example">
      <button type="button" class="btn btn-secondary" *ngIf="!isCupom" (click)="obterCupom()">Cupom de desconto</button>
      <button type="button" class="btn btn-warning" *ngIf="isCupom" (click)="zeraCupom()">Remover cupom aplicado</button>
      <button type="button" class="btn btn-success" (click)="reservar()">Reservar</button>
    </div>
  </div>
</div>
