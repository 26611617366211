import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-instalacoes-apartamento',
  templateUrl: './instalacoes-apartamento.component.html',
  styleUrls: ['./instalacoes-apartamento.component.scss'],
})
export class InstalacoesApartamentoComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  pageTitleArea: pageTitle[] = [
    {
      title: 'Instalações',
      subTitle: 'Hotel CTC',
    },
  ];
  caseStudiesDetailsDesc: DetailsDesc[] = [
    {
      title: 'Apartamentos',
      paragraph1: '144 Confortáveis suítes distribuídas por três andares.',
      paragraph2: '',
      img: 'assets/img/metropolitan/instalacoes-apartamento-01.png',
    },
    {
      title: '',
      paragraph1:
        'Todas as suítes oferecem frigobar, telefone, TV a cabo, ar condicionado, secador de cabelo e cofre.',
      paragraph2: '',
      img: 'assets/img/metropolitan/instalacoes-apartamento-02.png',
    },
    {
      title: '',
      paragraph1: '100% anti alérgicas (piso frio).',
      paragraph2: 'Dispomos de berço para bebês (mediante disponibilidade)',
      img: '',
    },
  ];
  caseStudiesDetailsDescImportantFacts: ImportantFacts[] = [
    {
      title: 'Piscinas',
    },
    {
      title: 'Saúna',
    },
    {
      title: 'Auditório',
    },
    {
      title: 'Ofurôs',
    },
    {
      title: 'Toboáguas',
    },
    {
      title: 'Home Office',
    },
  ];
  caseStudiesDetailsInfo: Info[] = [
    {
      icon: 'bx bx-user-pin',
      title: 'Reservas',
      subTitle: 'form reservas online',
    },
  ];
}
class pageTitle {
  title: string;
  subTitle: string;
}
class DetailsDesc {
  title: string;
  paragraph1: string;
  img: string;
  paragraph2: string;
}
class ImportantFacts {
  title: string;
}
class Info {
  icon: string;
  title: string;
  subTitle: string;
}
