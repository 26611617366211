<app-form-disabled *ngIf="formDisabled"></app-form-disabled>
<div class="container">
  <div class="row justify-content-center">
    <div class="row my-2">
      <div class="col-9">

        <!-- Dados do responsável pelo pagamento -->

        <ng-container *ngIf="dadosCustomers">
          <app-dados-responsavel-pagamento-online [isCustomer]="isCustomer"
            (eventDadosCustomers)="returnDadosResponsavelPagamento($event)"></app-dados-responsavel-pagamento-online>
        </ng-container>

        <!-- Dados do cartão para pagamento -->

        <ng-container *ngIf="isDadosCustomers">
          <app-dados-pagamento-online (eventVoltar)="returnVoltarResponsavelPagamento()"
            (eventConcluir)="concluiPayment($event)"></app-dados-pagamento-online>
        </ng-container>

      </div>

      <div class="col-3" *ngIf="!isConcluir">
        <app-reserva-escolhida-payment></app-reserva-escolhida-payment>
      </div>

      <!-- Conslusão do pagamento -->
      <ng-container *ngIf="isConcluir">
        <div class="col-12">
          <div class="row mb-3" *ngIf="resultadoPayment.status == 'CONFIRMED'">
            <app-reserva-confirmada [dadosReserva]="dadosReserva" [idReserva]="idReserva"></app-reserva-confirmada>
          </div>
          <div class="row mb-3"
            *ngIf="resultadoPayment.status == 'PENDING' || resultadoPayment.status == 'AWAITING_RISK_ANALYSIS' ">
            <div class="col-lg-12 text-center">
              <img class="shapetop mb-3" src="../../../assets/imgs/aguardando.png" height="60px" alt="">
              <h5 class="mb-5">Seu pagamento esta em analise e confirmação.</h5>
            </div>
            <div class="fs-4 mb-0 pb-0 text-center">
              Para qualquer mudança neste status será enviado um e-mail contendo a<br />
              confirmação ou negação do pagamento por parte da sua operadora de cartões,<br />
              detalhes da reserva, localização e outras informações serão enviadas<br />
              assim que for confirmado o pagamento. Obrigado!
            </div>
          </div>
          <div class="row mb-3"
            *ngIf="resultadoPayment.status != 'PENDING' && resultadoPayment.status != 'AWAITING_RISK_ANALYSIS' && resultadoPayment.status != 'CONFIRMED'">
            <div class="col-lg-12 text-center">
              <img class="shapetop mb-3" src="../../../assets/imgs/aguardando.png" height="60px" alt="">
              <h5 class="mb-5">Estamos processando seu pagamento.</h5>
            </div>
            <div class="fs-4 mb-0 pb-0 text-center">
              Estamos aguardando a confirmação ou negação do pagamento por parte<br />
              da sua operadora de cartão, detalhes da reserva, localização e outras informações<br />
              serão enviadas assim que for confirmado o pagamento. Obrigado!
            </div>
          </div>
        </div>
      </ng-container>

      <div class="col-12">
        <app-footer-empresa-gateway></app-footer-empresa-gateway>
      </div>
    </div>
  </div>
</div>
