import { Component, OnInit } from '@angular/core';
import { DisponibilidadeMapadiarioV1 } from './models/disponibilidade-mapadiario-v1.model';

@Component({
  selector: 'app-reserva-online',
  templateUrl: './reserva-online.component.html',
  styleUrls: ['./reserva-online.component.scss']
})
export class ReservaOnlineComponent implements OnInit {

  indisponiveis!: boolean;
  disponivel!: boolean;
  organizaQuartos!: boolean;
  inDisponibilidade: DisponibilidadeMapadiarioV1[] = [];

  constructor(
  ) { }

  ngOnInit(): void {
  }

  returnEventDisponibilidade(eventDisponivel: any){
    this.indisponiveis = eventDisponivel.indisponivel;
    this.disponivel = eventDisponivel.disponivel;
  }

  returnEventOrganizaQuartos(eventOrganizaQuartos: any) {
    this.organizaQuartos = eventOrganizaQuartos.organizaQuartos;
  }

  returnEventIndisponibilidade(eventIndisponibilidade: any){
    this.indisponiveis = eventIndisponibilidade.indisponivel;
    this.disponivel = eventIndisponibilidade.disponivel;
    this.inDisponibilidade = eventIndisponibilidade.disponibilidade;
  }
}
