import { CupomV1Model } from "./cupom-v1.model";
import { OrgabinzaQuartosModel } from "./organiza-quartos.model";

export class DadosReservaV2Model {
  constructor(
    public _id: string,
    public titleSelecionado: string,
    public quantasDiarias: number,
    public valorDiaria: number,
    public totalReserva: number,
    public isCupom: boolean,
    public valorDiariaCupom: number,
    public totalReservaCupom: number,
    public totalHospedes: number,
    public quantQuartos: number,
    public totalAdultos: number,
    public totalJovens: number,
    public totalCriancas: number,
    public cafeDaManha: boolean,
    public meiaPensao: boolean,
    public pensaoCompleta: boolean,
    public quantParcelas: number,
    public dtInicio: string,
    public dtFinal: string,
    public cupom: CupomV1Model | null,
    public organizaQuartos?: OrgabinzaQuartosModel
  ) { }
}
