import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-instalacoes-alimentacao',
  templateUrl: './instalacoes-alimentacao.component.html',
  styleUrls: ['./instalacoes-alimentacao.component.scss'],
})
export class InstalacoesAlimentacaoComponent implements OnInit {
  /**
   * Variáveis de ambiente
   */
  // templateWeb: MenuPortalV1Model;

  constructor() {} // private readonly webSiteV1Service: WebSiteV1Service,

  ngOnInit(): void {
    // this.getContextoWebsite();
  }

  pageTitleArea: pageTitle[] = [
    {
      title: 'Instalações',
      subTitle: 'Alimentação',
    },
  ];
  caseStudiesDetailsDesc: DetailsDesc[] = [
    {
      title: 'Lanchonete',
      paragraph:
        'Localizado nas piscinas do hotel, funciona das 08h às 00h servindo bebidas, petiscos, salgados, lanches, pizzas e sorvetes.',
      img: 'assets/img/metropolitan/lanchonete-hotel.png',
    },
  ];
  caseStudiesDetailsDescImportantFacts: ImportantFacts[] = [
    {
      title: 'Piscinas',
    },
    {
      title: 'Saúna',
    },
    {
      title: 'Auditório',
    },
    {
      title: 'Ofurôs',
    },
    {
      title: 'Toboáguas',
    },
    {
      title: 'Home Office',
    },
  ];
  caseStudiesDetailsInfo: Info[] = [
    {
      icon: 'bx bx-user-pin',
      title: 'Reservas',
      subTitle: 'form reservas online',
    },
  ];

  // /**
  //  * Consumindo o serviço e pgando os dados no banco
  //  * de dados para preencher no template.
  //  */
  // async getContextoWebsite(){

  // await this.webSiteV1Service.getContextoWebsite()
  //     .subscribe(
  //       (resultado: ResultV1Model) => {
  //         console.log(resultado)
  //       }
  //     )
  // }
}

class pageTitle {
  title: string;
  subTitle: string;
}
class DetailsDesc {
  title: string;
  paragraph: string;
  img: string;
}
class ImportantFacts {
  title: string;
}
class Info {
  icon: string;
  title: string;
  subTitle: string;
}
