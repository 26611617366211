import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cepBr'
})
export class CepPipe implements PipeTransform {
  transform(value: string): string {
    if (!value && value.length < 10) {
      return value;
    }
    if(value.length < 9){
      return `${value.substr(0,5)}-${value.substr(-3)}`
    }else{
      return  value
    }
  }
}
