import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DadosOrganizaQuartosService {

  /** Variavel de ambiente */
  public reload: boolean = false;

  /** Ações de emitir o eventos */
  public isquartos = new EventEmitter<number>();
  public organizaSimples = new EventEmitter<any>();
  public organizaAvancado = new EventEmitter<any>();

  /** Funções pública de acionamento do eventos */
  public isQuartos(isquartos: number) {
    this.setIsQuartos(isquartos);
  }
  public isQuartoSimples(quartoSimples: any) {
    this.setIsQuartos(quartoSimples.quartos);
    this.setQuartoSimples(quartoSimples);
  }

  public isQuartosAvancado(quartosAvancado: any) {
    this.setQuartosAvancado(quartosAvancado);
  }

  /** Setando o evento contendo dados do quarto simples EventEmitter() */
  setIsQuartos(isquartos: number){
    this.isquartos.emit(isquartos);
  }
  setQuartoSimples(quartoSimples: any) {
    this.organizaSimples.emit(quartoSimples);
  }

  setQuartosAvancado(quartosAvancado: any) {
    this.organizaAvancado.emit(quartosAvancado);
  }

}
