import { Component, OnInit } from '@angular/core';
import { AsaasUnicoExtRefV2Model } from 'src/app/pagamentos/model/asaas-unico-ext-ref-v2.model';
import { PaymentAsaasUnicaV2Model } from 'src/app/pagamentos/model/payment-asaas-unica-v2.model';
import { DadosReservaV2Model } from '../reservas/models/dados-reserva-v2.model';
import { UtilsService } from '../share/utils.service';
import { Router } from '@angular/router';
declare let $: any;

@Component({
  selector: 'app-pagamento-online',
  templateUrl: './pagamento-online.component.html'
})
export class PagamentoOnlineComponent implements OnInit {

  /** variavei de ambiente */
  preloader!: boolean;
  idReserva: number = 0
  asaasUnico!: AsaasUnicoExtRefV2Model;
  dadodaReserva: DadosReservaV2Model;
  dadosCustomers: boolean = true;
  isDadosCustomers: boolean = false;
  isCustomer: boolean = false;
  isConcluir: boolean = false;
  resultadoPayment!: PaymentAsaasUnicaV2Model;
  dadosReserva!: DadosReservaV2Model;
  formDisabled: boolean = true;

  constructor(
    private route: Router,
    private readonly utilService: UtilsService
  ) { }

  ngOnInit(): void {
    if (this.utilService.isDadosReserva()) {
      this.dadodaReserva = this.utilService.getDadosReserva();
    } else {
      this.route.navigate([`/`]);
    }
  }

  returnDadosResponsavelPagamento(event: any) {
    this.dadosCustomers = !event.dadosCustomers;
    this.isDadosCustomers = event.dadosCustomers
  }

  returnVoltarResponsavelPagamento() {
    this.isCustomer = true;
    this.dadosCustomers = true;
    this.isDadosCustomers = false;
  }

  concluiPayment(event: any) {
    this.isConcluir = true;
    this.dadosCustomers = false;
    this.isDadosCustomers = false;
    this.formDisabled = false;
    this.dadosReserva = event.dadosReserva;
    this.idReserva = event.resultado.idReserva;
    this.resultadoPayment = event.resultado.pagamento;
    this.utilService.clear();
    this.isPreloader(false);
  }

  isPreloader(status: boolean) {
    this.preloader = status;
    status
      ? /**
         * start preloader
         */
      $('.preloader').fadeIn('slow')
      : /**
         * stop preloader
         */
      ($.getScript('../assets/js/main.js'), $('.preloader').fadeOut('slow'));
  }

}
