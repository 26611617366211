import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { EPIGRAFO_API } from "src/app/share/api";
import { ResultV1Model } from "src/app/share/models/result-v1.model";
import { ReservaV1Model } from "../models/reserva-v1.model";
import { GatewayPortalV1Model } from "../models/gateway-portal-v1.model";
import { UtilsService } from "src/app/share/utils.service";

@Injectable({
  providedIn: 'root'
})
export class ReservaV1Service {

  constructor(
    private http: HttpClient,
    private readonly utilsService: UtilsService
  ) { }

  getReservas(
    reservas: ReservaV1Model
  ) {
    return this.http.patch<ResultV1Model>(
      `${EPIGRAFO_API}/v2/tema/tarifas/periodo`,
      reservas,
      { headers: this.utilsService.composeHeader() }
    );
  }

  getCupom(
    cupom: string
  ) {
    return this.http.get<ReservaV1Model>(
      `${EPIGRAFO_API}/v1/cupom/codigo/${cupom}`,
      { headers: this.utilsService.composeHeader() }
    )
  }

  postGatewayReserva(
    dadosreserva: GatewayPortalV1Model
  ) {
    return this.http.post<ResultV1Model>(
      `${EPIGRAFO_API}/v1/checkout`,
      dadosreserva,
      { headers: this.utilsService.composeHeader() }
    )
  }

  getOrderPagarme(
    orderid: string
  ) {
    return this.http.get<ResultV1Model>(
      `${EPIGRAFO_API}/v1/order/${orderid}`,
      { headers: this.utilsService.composeHeader() }
    );
  }

  getReservaApiWeb(
    id: string
  ) {
    return this.http.get<ResultV1Model>(
      `${EPIGRAFO_API}/v1/reserva/${id}`,
      { headers: this.utilsService.composeHeader() }
    );
  }
}
