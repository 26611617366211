<section class="about-area pb-100" id="muita-diversao">
  <div class="container-fluid">
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-12">
        <div class="about-image" *ngFor="let Image of aboutImage;">
          <img [src]="Image.img" alt="image">
        </div>
      </div>

      <div class="col-lg-6 col-md-12">
        <div class="about-content">
          <div class="content" *ngFor="let Content of aboutContent;">
            <span class="sub-title"><img src="assets/img/star-icon.png" alt="image"> {{Content.subTitle}}</span>
            <h2>{{Content.title}}</h2>
            <p>{{Content.paragraphText1}}</p>
            <ul class="features-list">
              <li *ngFor="let List of featuresList;" style="cursor: pointer;" routerLink="/{{List.link}}">
                <span>
                  <div class="icon">
                    <img [src]="List.icon" alt="image">
                  </div>
                  <h5>{{List.title}}</h5>
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="circle-shape1"><img src="assets/img/shape/circle-shape1.png" alt="image"></div>
</section>
