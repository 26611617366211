<section class="page-title-area">
  <div class="container">
    <div class="page-title-content" *ngFor="let pageTitle of pageTitleArea;">
      <h2>{{pageTitle.title}}</h2>
      <ul>
        <li><a routerLink="/">Home</a></li>
        <li>{{pageTitle.title}}</li>
        <li>{{pageTitle.subTitle}}</li>
      </ul>
    </div>
  </div>

  <div class="shape-img1"><img src="assets/img/shape/shape1.svg" alt="image"></div>
  <div class="shape-img2"><img src="assets/img/shape/shape2.png" alt="image"></div>
  <div class="shape-img3"><img src="assets/img/shape/shape3.png" alt="image"></div>
</section>

<section class="case-studies-details-area">
  <div class="container">
    <div class="row">
      <div class="col-lg-8 col-md-12">
        <app-gallery-page></app-gallery-page>
        <div class="case-studies-details-desc" *ngFor="let DetailsDesc of caseStudiesDetailsDesc;">

          <h3>{{DetailsDesc.title}}</h3>
          <p style="text-align: justify;">{{DetailsDesc.paragraph01}}</p>

        </div>
      </div>

    </div>
  </div>
</section>
