import { HospedeV2Model } from "./hospede-v2.model";
import { ReservaV2Model } from "./reserva-v2.model";

export class Etapa01NewPaymentOnlineTemaAsaasV2Model {
  constructor(
    public hospede: HospedeV2Model,
    public reserva: ReservaV2Model,
  ) { }
}

