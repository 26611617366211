<section class="margin-top-area">
  <div class="container">
    <div *ngIf="isCarrega">
      <div class="row">

        <!-- Dados da reserva a ser paga -->
        <ng-container *ngIf="isReserva">
          <div class="col-lg-4">
            <app-dados-reserva [temaReserva]="temaReserva" [isReserva]="isNotReserva"
              [externalReference]="externalReference"></app-dados-reserva>
          </div>
        </ng-container>

        <!-- Dados do responsável pelo pagamento -->
        <ng-container *ngIf="dadosCustomers">
          <div class="col-lg-8 mb-2">
            <app-dados-responsavel-pagamento [externalReference]="externalReference" [isCustomer]="isCustomer"
              (eventDadosCustomers)="returnDadosResponsavelPagamento($event)"></app-dados-responsavel-pagamento>
          </div>
        </ng-container>

        <!-- Dados do cartão para pagamento -->
        <ng-container *ngIf="isDadosCustomers">
          <div class="col-lg-8 mb-2">
            <app-dados-pagamento [externalReference]="externalReference" [asaasUnico]="asaasUnico"
              (eventVoltar)="returnVoltarResponsavelPagamento()"
              (eventConcluir)="concluiPayment($event)"></app-dados-pagamento>
          </div>
        </ng-container>

        <!-- Em caso de não encontrar o codigo informada na url -->
        <ng-container *ngIf="isMsn">
          <div class="col-lg-12 mt-5 mb-5 text-center">

            <!-- Título em caso de não entrontar o código -->
            <h5 *ngIf="isCodigo" class="mb-5">Este ambiente so pode ser acessado se contiver o código da reserva<br />
              Desculpa! Você será redirecionado para o portal de reservas.</h5>

            <!-- Título em caso de não entrontar a reserva informada-->
            <h5 *ngIf="isNotReserva" class="mb-5">Não foram localizados nenhuma reserva com este código, desculpa,<br />
              não conseguimos seguir adiante com sua reserva sem estes dados.</h5>

            <!-- Título em caso de não entrontar a reserva informada-->
            <div *ngIf="isConfirmado" class="row mb-3">
              <div class="col-lg-12">
                <img class="shapetop mb-3" src="../../../assets/imgs/pagamento-ok.png" height="100" alt="">
                <h5 class="mb-5">Esta reserva ja esta com o pagamento confirmado.</h5>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-lg-12">
                <div class="fs-4 mb-0 pb-0 text-center">Em caso de maiores
                  esclarecimentos,<br />
                  entre em contato com o setor de reserva do<br />
                  <strong>Hotel CTC</strong> pelo número: <strong>(64) 3453-1515 <span
                      class="text-success">(whatsapp)</span></strong>
                </div>
              </div>
            </div>
          </div>
        </ng-container>

        <!-- Conslusão do pagamento -->
        <ng-container *ngIf="isConcluir">
          <div class="col-lg-8 mb-2">
            <div class="row mb-3" *ngIf="resultadoPayment.status == 'CONFIRMED'">
              <div class="col-lg-12 text-center">
                <img class="shapetop mb-3" src="../../../assets/imgs/pagamento-ok.png" height="60" alt="">
                <h5 class="mb-5">Seu pagamento esta confirmado.</h5>
              </div>
              <div class="fs-4 mb-0 pb-0 text-center">
                Será enviado um e-mail contendo a confirmação, detalhes da reserva,<br />
                localização e outras informações, você pode imprimir esta tela ou anotar o<br />
                número de reserva "{{ temaReserva.idreserva }}", com este número você<br />
                terá todas informações necessárias sobre sua reserva. Obrigado!
              </div>
            </div>
            <div class="row mb-3"
              *ngIf="resultadoPayment.status == 'PENDING' || resultadoPayment.status == 'AWAITING_RISK_ANALYSIS' ">
              <div class="col-lg-12 text-center">
                <img class="shapetop mb-3" src="../../../assets/imgs/aguardando.png" height="60" alt="">
                <h5 class="mb-5">Seu pagamento esta em analise e confirmação.</h5>
              </div>
              <div class="fs-4 mb-0 pb-0 text-center">
                Para qualquer mudança neste status será enviado um e-mail contendo a<br />
                confirmação ou negação do pagamento por parte da sua operadora de cartões,<br />
                detalhes da reserva, localização e outras informações serão enviadas<br />
                assim que for confirmado o pagamento. Obrigado!
              </div>
            </div>
            <div class="row mb-3"
              *ngIf="resultadoPayment.status != 'PENDING' && resultadoPayment.status != 'AWAITING_RISK_ANALYSIS' && resultadoPayment.status != 'CONFIRMED'">
              <div class="col-lg-12 text-center">
                <img class="shapetop mb-3" src="../../../assets/imgs/aguardando.png" height="60" alt="">
                <h5 class="mb-5">Estamos processando seu pagamento.</h5>
              </div>
              <div class="fs-4 mb-0 pb-0 text-center">
                Estamos aguardando a confirmação ou negação do pagamento por parte<br />
                da sua operadora de cartão, detalhes da reserva, localização e outras informações<br />
                serão enviadas assim que for confirmado o pagamento. Obrigado!
              </div>
            </div>
          </div>
        </ng-container>

      </div>

      <app-footer-empresa-gateway></app-footer-empresa-gateway>
    </div>
  </div>
</section>
