import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
declare let $: any;
import Swal from 'sweetalert2';
import { CalculaReservaV1Service } from '../events/calcula-reserva-v1.service';
import { DadosReservaEscolhidaService } from '../events/dados-reserva-escolhida.service';
import { EPIGRAFO_API, account_token } from 'src/app/share/api';
import { CupomV1Model } from '../models/cupom-v1.model';
import { DadosReservaV2Model } from '../models/dados-reserva-v2.model';
import { UtilsService } from 'src/app/share/utils.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-reserva-escolhida',
  templateUrl: './reserva-escolhida.component.html',
  styleUrls: ['../disponibilidade/disponibilidade.component.scss']
})
export class ReservaEscolhidaComponent implements OnInit {

  /** variavel de trafego de dados */
  dadosReserva: DadosReservaV2Model;

  /** calcula parcelamento */
  quantParcela: number = 0;

  /** títulos */
  titleSelecionado: string = 'Café da manhã incluso';

  /** Variaveis de cupom */
  isCupom: boolean = false;
  cupomDesconto!: CupomV1Model;
  porcentagemCupom!: number;
  valorDiariaCupom!: number;
  totalReservaCupom!: number;
  dadosReservaCupom!: DadosReservaV2Model;

  /**
   * Variaveis de ambiente
   */
  preloader!: boolean;
  quantasDiarias: number = 0;
  valorDiaria: number = 0;
  totalReserva: number = 0;
  opcaoEscolhida: number = 0;
  adultos: number = 0;
  jovens: number = 0;
  criancas: number = 0;
  dataEntrada?: string;
  dataSaida?: string;
  quartos: number = 0;
  mediaDiariaCM: number = 0;
  mediaDiariaMP: number = 0;
  mediaDiariaPC: number = 0;

  /** Numero Valor Diarias */
  nvld!: number;
  /** Numero Valor Total com Cupom */
  nvtc!: number;

  /**
   * variaveis somente cafe da manha
   */
  diariaQuarto01_CM: number = 0;
  diariaQuarto02_CM: number = 0;
  diariaQuarto03_CM: number = 0;

  /**
   * variveis Meia Pensão
   */
  diariaQuarto01_MP: number = 0;
  diariaQuarto02_MP: number = 0;
  diariaQuarto03_MP: number = 0;

  /**
   * Variaveis Pensão Completa
   */
  diariaQuarto01_PC: number = 0;
  diariaQuarto02_PC: number = 0;
  diariaQuarto03_PC: number = 0;

  constructor(
    private route: Router,
    private readonly utilsService: UtilsService,
    private readonly calculaReservaV1Service: CalculaReservaV1Service,
    private readonly dadosReservaEscolhidaService: DadosReservaEscolhidaService
  ) { }

  ngOnInit(): void {
    this.dadosReservaEscolhidaService.detalhesReservaEscolhida
      .subscribe(opcaoescolhida => {
        this.opSelecionada(opcaoescolhida);
      });
    this.calculaReservaV1Service.calculaReservadisponivel
      .subscribe(novareserva => {
        this.dataEntrada = novareserva.dataentrada;
        this.dataSaida = novareserva.datasaida;
        this.quartos = novareserva.quartos;
        this.adultos = novareserva.adultos;
        this.jovens = novareserva.jovens;
        this.criancas = novareserva.criancas;
        this.quantasDiarias = novareserva.valordiarias.quantasdiarias;
        this.mediaDiariaCM = novareserva.mediaDiariaCM;
        this.mediaDiariaMP = novareserva.mediaDiariaMP;
        this.mediaDiariaPC = novareserva.mediaDiariaPC;
        this.diariaQuarto01_CM = novareserva.valordiarias.diariaquarto01_CM;
        this.diariaQuarto02_CM = novareserva.valordiarias.diariaquarto02_CM;
        this.diariaQuarto03_CM = novareserva.valordiarias.diariaquarto03_CM;
        this.diariaQuarto01_MP = novareserva.valordiarias.diariaquarto01_MP;
        this.diariaQuarto02_MP = novareserva.valordiarias.diariaquarto02_MP;
        this.diariaQuarto03_MP = novareserva.valordiarias.diariaquarto03_MP;
        this.diariaQuarto01_PC = novareserva.valordiarias.diariaquarto01_PC;
        this.diariaQuarto02_PC = novareserva.valordiarias.diariaquarto02_PC;
        this.diariaQuarto03_PC = novareserva.valordiarias.diariaquarto03_PC;
        this.valorDiaria = 0;
        this.totalReserva = 0;
        this.opSelecionada(3);
      });
  }

  opSelecionada(opcao: number) {
    this.opcaoEscolhida = opcao;
    opcao == 1 ? this.pacoteCafedaManha() : false;
    opcao == 2 ? this.pacoteMeiaPensao() : false;
    opcao == 3 ? this.pacotePensaoCompleta() : false;
  }

  pacoteCafedaManha() {
    this.titleSelecionado = 'Diária com café!';
    // this.valorDiaria = this.valor(this.diariaQuarto01_CM + this.diariaQuarto02_CM + this.diariaQuarto03_CM);
    this.valorDiaria = this.valor(this.mediaDiariaCM);
    this.totalReserva = (this.valor(this.diariaQuarto01_CM + this.diariaQuarto02_CM + this.diariaQuarto03_CM)) * this.quantasDiarias;
    this.preencheDadosReserva(
      this.titleSelecionado,
      this.valorDiaria,
      this.totalReserva,
      1
    );
  }

  pacoteMeiaPensao() {
    this.titleSelecionado = 'Meia pensão!';
    // this.valorDiaria = this.valor(this.diariaQuarto01_MP + this.diariaQuarto02_MP + this.diariaQuarto03_MP);
    this.valorDiaria = this.valor(this.mediaDiariaMP);
    this.totalReserva = (this.valor(this.diariaQuarto01_MP + this.diariaQuarto02_MP + this.diariaQuarto03_MP)) * this.quantasDiarias;
    this.preencheDadosReserva(
      this.titleSelecionado,
      this.valorDiaria,
      this.totalReserva,
      2
    );
  }

  pacotePensaoCompleta() {
    this.titleSelecionado = 'Pensão completa!';
    // this.valorDiaria = this.valor(this.diariaQuarto01_PC + this.diariaQuarto02_PC + this.diariaQuarto03_PC);
    this.valorDiaria = this.valor(this.mediaDiariaPC);
    this.totalReserva = (this.valor(this.diariaQuarto01_PC + this.diariaQuarto02_PC + this.diariaQuarto03_PC)) * this.quantasDiarias;
    this.preencheDadosReserva(
      this.titleSelecionado,
      this.valorDiaria,
      this.totalReserva,
      3
    );
  }

  valor(valor: number): number {
    let numero!: number;
    let texto01: string = valor.toFixed(2).toString().replace(/[^\d]+/g, '');
    numero = +parseFloat(texto01.substring(0, texto01.length + -2) + "." + texto01.substring(texto01.length + -2, texto01.length));
    return numero;
  }

  formatter(value: number): string {
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value);
  }

  preencheDadosReserva(
    titleSelecionado: string,
    valorDiaria: number,
    totalReserva: number,
    opcao: number
  ) {
    let dadosReserva = new DadosReservaV2Model(
      undefined,
      titleSelecionado,
      this.quantasDiarias,
      valorDiaria,
      totalReserva,
      false,
      0,
      0,
      this.adultos + this.jovens + this.criancas,
      this.quartos,
      this.adultos,
      this.jovens,
      this.criancas,
      opcao == 1 ? true : false,
      opcao == 2 ? true : false,
      opcao == 3 ? true : false,
      0,
      this.dataEntrada,
      this.dataSaida,
      null,
      this.utilsService.getQuartos()
    )
    this.dadosReserva = dadosReserva;
    this.utilsService.setDadosReserva(dadosReserva);
    if (this.isCupom) {
      this.recalculaValorComCupom(this.cupomDesconto);
    }
  }


  obterCupom() {
    Swal.fire({
      title: 'Digite seu cupom!',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Validar cupom',
      showLoaderOnConfirm: true,
      confirmButtonColor: '#ff5d22',
      backdrop: `rgba(255,93,34,0.10)`,
      preConfirm: (cupom) => {
        const url = `${EPIGRAFO_API}/v1/cupom/codigo/${cupom}`;
        const headers = {
          "accountaccess": `${account_token}`,
          "Accept": "application/json",
          "Content-Type": "application/json"
        };
        return fetch(url, {
          method: "GET",
          headers
        }).then(response => {
          if (!response.ok) {
            throw new Error(response.statusText)
          }
          return response.json()
        })
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (!result.isDismissed) {
        if (!result.value.success) {
          Swal.fire({
            title: `${result.value.titulo}`,
            text: `${result.value.message}`,
            confirmButtonText: 'Tentar outro cupom?',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonColor: '#ff5d22',
            backdrop: `rgba(255,93,34,0.10)`,
            preConfirm: () => {
              this.obterCupom();
            }
          })
        } else {
          this.recalculaValorComCupom(result.value.data);
        }
      }
    });
  }


  recalculaValorComCupom(
    cupom: CupomV1Model
  ) {
    if (
      new Date(moment(this.dataEntrada, 'DD/MM/YYYY').add(1, 'days').format("YYYY-MM-DD")) > new Date(cupom.dtinicio) &&
      new Date(moment(this.dataSaida, 'DD/MM/YYYY').add(1, 'days').format("YYYY-MM-DD")) < new Date(cupom.dtfim)
    ) {

      this.cupomDesconto = undefined;
      this.totalReservaCupom = 0;
      this.valorDiariaCupom = 0;
      this.isCupom = true;
      this.cupomDesconto = cupom;

      cupom.tpcupom ? (
        this.totalReservaCupom = this.totalReserva - (this.totalReserva * (cupom.porcent / 100)),
        this.valorDiariaCupom = this.totalReservaCupom / this.quantasDiarias,
        this.porcentagemCupom = +cupom.porcent
      ) : (
        this.totalReservaCupom = this.totalReserva - cupom.valor,
        this.valorDiariaCupom = this.totalReservaCupom / this.quantasDiarias
      );

      this.valorDiaria = this.valorDiariaCupom;
      this.totalReserva = this.totalReservaCupom;

      let numer01: string = (this.valorDiaria).toLocaleString('en-US');
      let numer02: string = (this.totalReservaCupom).toLocaleString('en-US');
      let n1 = +numer01;
      let n2 = +numer02;
      // numero valor diaria
      this.nvld = +n1.toFixed(2);
      // numero valor total com cupom
      this.nvtc = +n2.toFixed(2);

      this.calculaParcela(cupom.tpcupom ? this.nvtc : this.totalReservaCupom);

      this.dadosReservaCupom = new DadosReservaV2Model(
        undefined,
        this.titleSelecionado,
        this.quantasDiarias,
        this.dadosReserva.valorDiaria,
        this.dadosReserva.totalReserva,
        true,
        this.valorDiariaCupom,
        this.totalReservaCupom,
        this.adultos + this.jovens + this.criancas,
        this.quartos,
        this.adultos,
        this.jovens,
        this.criancas,
        this.opcaoEscolhida == 1 ? true : false,
        this.opcaoEscolhida == 2 ? true : false,
        this.opcaoEscolhida == 3 ? true : false,
        0,
        this.dataEntrada,
        this.dataSaida,
        cupom,
        this.utilsService.getQuartos()
      )
      this.utilsService.setDadosReserva(this.dadosReservaCupom);

    } else {
      if (moment(Date()).format("YYYY-MM-DD") > moment(cupom.dtfim).add(1, 'days').format("YYYY-MM-DD")) {
        this.sweetModel(
          `Cupom vencido!`,
          `Desculpa, seu cupom encerrou a data de uso no dia ${moment(cupom.dtfim).add(1, 'days').format("DD/MM/YYYY")}`
        );
      } else {
        this.sweetModel(
          `Cupom não pode ser aplicado!`,
          `Desculpa, seu cupom só poderá ser utilizado entre o período de ${moment(cupom.dtinicio).format("DD/MM/YYYY")} à ${moment(cupom.dtfim).format("DD/MM/YYYY")}`
        );
      }
    }
  }

  zeraCupom() {
    this.valorDiaria = this.dadosReserva.valorDiaria;
    this.totalReserva = this.dadosReserva.totalReserva;
    this.cupomDesconto = undefined;
    this.totalReservaCupom = 0;
    this.valorDiariaCupom = 0;
    this.isCupom = false;
  }

  // modal de possíveis erros
  sweetModel(title: string, text: string) {
    Swal.fire({
      title: title,
      text: text,
      confirmButtonText: 'Tentar outro cupom?',
      showCancelButton: true,
      cancelButtonText: 'fechar',
      confirmButtonColor: '#ff5d22',
      backdrop: `rgba(255,93,34,0.10)`,
      preConfirm: () => {
        this.obterCupom();
      }
    })
  }

  /**
   * Referente ao percelamento do valor total da tarifa
   * em ate 6x dependendo do valor
   */
  calculaParcela(valor: number) {
    /** avaliando o valor e definindo o parcelamento possivel */
    /** maior ou igual a 1200 */
    valor >= 1200 ? this.quantParcela = 6 : null;

    /** maior ou igual a 1000 ou menor ou igual a 1199 */
    valor >= 1000 && valor <= 1199 ? this.quantParcela = 5 : null;

    /** maior ou igual a 800 ou menor ou igual a 999 */
    valor >= 800 && valor <= 999 ? this.quantParcela = 4 : null;

    /** maior ou igual a 600 ou menor ou igual a 799 */
    valor >= 600 && valor <= 799 ? this.quantParcela = 3 : null;

    /** menor ou igual a 400 ou menor ou igual a 599 */
    valor >= 400 && valor <= 599 ? this.quantParcela = 2 : null;

    /** menor ou igual a 399 */
    valor <= 399 ? this.quantParcela = 1 : null;
  }

  isPreloader(status: boolean) {
    this.preloader = status;
    status
      ? /**
         * start preloader
         */
      $('.preloader').fadeIn('slow')
      : /**
         * stop preloader
         */
      ($.getScript('../assets/js/main.js'), $('.preloader').fadeOut('slow'));
  }

  reservar() {
    this.isPreloader(true);
    this.isCupom ?
      this.utilsService.setDadosReserva(this.dadosReservaCupom) :
      this.utilsService.setDadosReserva(this.dadosReserva);
    setTimeout(() => {
      this.route.navigate([`/pagamentos`]);
      this.isPreloader(false);
    }, 2000);
  }

}
