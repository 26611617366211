<div class="navbar-area">
  <div class="wilo-responsive-nav">
    <div class="container">
      <div class="wilo-responsive-menu">
        <div class="logo">
          <a routerLink="/">
            <img src="assets/img/logo.png" alt="logo">
          </a>
        </div>
      </div>
    </div>
  </div>

  <div class="wilo-nav">
    <div class="container">
      <nav class="navbar navbar-expand-md navbar-light">
        <a class="navbar-brand" routerLink="/">
          <img src="assets/img/logo.png" alt="logo">
        </a>

        <div class="collapse navbar-collapse mean-menu">
          <ul class="navbar-nav">
            <li class="nav-item"><a href="/" class="nav-link">Hotel CTC <i class='bx bx-chevron-down'></i></a>
              <ul class="dropdown-menu">

                <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Instalações <i
                      class='bx bx-chevron-right'></i></a>

                  <ul class="dropdown-menu" style="width: 150px;">

                    <li class="nav-item"><a routerLink="/instalacoes-alimentacao" class="nav-link"
                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Alimentação</a></li>

                    <li class="nav-item"><a routerLink="/instalacoes-apartamentos" class="nav-link"
                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Apartamentos</a></li>

                    <li class="nav-item"><a routerLink="/instalacoes-lazer" class="nav-link" routerLinkActive="active"
                        [routerLinkActiveOptions]="{exact: true}">Lazer</a></li>

                    <li class="nav-item"><a routerLink="/instalacoes-servicos" class="nav-link"
                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Serviços</a></li>

                  </ul>
                </li>

                <li class="nav-item"><a routerLink="/sobre-hotel-ctc" class="nav-link" routerLinkActive="active"
                    [routerLinkActiveOptions]="{exact: true}">Sobre o Hotel CTC</a></li>

                <li class="nav-item"><a routerLink="/sobre-aguas-quentes-de-caldas-novas" class="nav-link"
                    routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Sobre as Águas Quentes de Caldas
                    Novas</a></li>

                <li class="nav-item"><a routerLink="/politicas-de-hospedagens" class="nav-link"
                    routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Políticas de Hospedagens</a>
                </li>

                <li class="nav-item"><a routerLink="/contato" class="nav-link" routerLinkActive="active"
                    [routerLinkActiveOptions]="{exact: true}">Entre em Contato!</a></li>
              </ul>
            </li>

            <!-- <li class="nav-item"><a routerLink="/reservas" class="nav-link" routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}">Reservas Online</a></li> -->

            <li class="nav-item"><a href="https://metropolitanpark.com.br" class="nav-link" target="_blank">Metropolitan
                Park <i class='bx bx-chevron-down'></i></a>
              <ul class="dropdown-menu" style="width: 150px;">
                <li class="nav-item"><a routerLink="/metropolitan-atracoes" class="nav-link" routerLinkActive="active"
                    [routerLinkActiveOptions]="{exact: true}">Atrações</a></li>
                <li class="nav-item"><a routerLink="/metropolitan-alimentacao" class="nav-link"
                    routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Alimentação</a></li>
                <li class="nav-item"><a routerLink="/metropolitan-servicos" class="nav-link" routerLinkActive="active"
                    [routerLinkActiveOptions]="{exact: true}">Serviços</a></li>
              </ul>
            </li>

          </ul>

          <!-- <div class="others-option d-flex align-items-center">
            <div class="option-item">
              <a href="#" class="default-btn"><i class="flaticon-user"></i>Hospedes<span></span></a>
            </div>
          </div> -->

        </div>
      </nav>
    </div>
  </div>

  <!-- <div class="others-option-for-responsive">
    <div class="container">
      <div class="dot-menu">
        <div class="inner">
          <div class="circle circle-one"></div>
          <div class="circle circle-two"></div>
          <div class="circle circle-three"></div>
        </div>
      </div>

      <div class="container">
        <div class="option-inner">
          <div class="others-option">
            <div class="option-item">
              <a routerLink="" class="default-btn"><i class="flaticon-right"></i>Hóspedes<span></span></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->
