import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import Swal from 'sweetalert2';
import * as moment from 'moment';
declare let $: any;
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PostPaymentV1Service } from '../service/post-payment-v1.service';
import { DadosFormPaymentV1Model } from '../model/dados-form-payment-v1.model';
import { UtilsService } from 'src/app/share/utils.service';
import { NewPaymentAsaasV2Model } from '../model/new-payment-asaas-v2.model';
import { ResultV1Model } from 'src/app/share/models/result-v1.model';
import { TemaParcelasModel } from '../model/tema-parcelas.model';
import { AsaasUnicoExtRefV2Model } from '../model/asaas-unico-ext-ref-v2.model';
import { ExternalReferenceV2Model } from '../model/external-referece-v2.model';
import { GetIpAddressService } from 'src/app/share/utils/get-ip-address.service';

@Component({
  selector: 'app-dados-pagamento',
  templateUrl: './dados-pagamento.component.html',
  styleUrls: ['./dados-pagamento.component.scss']
})
export class DadosPagamentoComponent implements OnInit {

  /** Variaveis de entrada */
  customer!: DadosFormPaymentV1Model;
  @Input() externalReference!: ExternalReferenceV2Model;
  @Input() asaasUnico!: AsaasUnicoExtRefV2Model;
  @Output() eventVoltar = new EventEmitter();
  @Output() eventConcluir = new EventEmitter();

  /** variaveis de ambiente */
  preloader!: boolean;
  payment!: string;
  valor!: any;
  valorParcela!: any;
  parcelas!: number;
  parcelasDefinidas!: TemaParcelasModel[];
  installmentCount: number = 1;
  installmentValue: number = 0;
  ipAddress: string;

  /**
   * form pagamento
   */
  formPagamento!: FormGroup;
  submitted = false;
  formErrors: any;

  constructor(
    private fb: FormBuilder,
    private readonly utilsService: UtilsService,
    private readonly postPaymentV1Service: PostPaymentV1Service,
    private readonly getIpAddress: GetIpAddressService
  ) {
    /** Form PAGAMENTO */
    this.formPagamento = this.fb.group(
      this.getFormPagamento()
    );
  }

  // Irrelevante para a documentação
  get fpg() {
    return this.formPagamento.controls
  }

  getFormPagamento() {
    return {
      number: ['',
        Validators.compose([
          Validators.minLength(16),
          Validators.maxLength(16),
          Validators.required
        ])
      ],
      vencemes: ['',
        Validators.compose([
          Validators.minLength(2),
          Validators.maxLength(2),
          Validators.required
        ])],
      venceano: ['',
        Validators.compose([
          Validators.minLength(4),
          Validators.maxLength(4),
          Validators.required
        ])],
      ccv: ['',
        Validators.compose([
          Validators.minLength(3),
          Validators.maxLength(3),
          Validators.required
        ])]
    }
  }

  ngOnInit(): void {
    this.prencheForm(this.utilsService.getDadosPayment());
    this.processAsassUnico();
    this.parcelas = 1;
    this.getIp();
  }

  async getIp() {
    await this.getIpAddress.getIPAddress()
      .subscribe(
        (resultado: any) => {
          this.ipAddress = resultado.ip;
        }
      );
  }

  processAsassUnico() {
    this.valor = this.formatValue(this.asaasUnico.valor);
    this.valorParcela = `1x de ${this.formatValue(this.asaasUnico.valor)}`;
    this.processaCartao(this.asaasUnico.valor);
  }

  processaCartao(
    valor: number
  ) {
    this.parcelasDefinidas = [];

    /**
     * processando parcelas
     */
    let loop: number = this.calculaParcela(valor);;
    for (let index: number = 1; index <= loop; index++) {
      this.parcelasDefinidas.push({
        number: index,
        total: valor / index
      })
    }
  }

  prencheForm(
    customer: DadosFormPaymentV1Model
  ) {
    this.customer = {
      nome: customer.nome,
      cpf: customer.cpf,
      logradouro: customer.logradouro,
      email: customer.email,
      complemento: customer.complemento,
      numero: customer.numero,
      bairro: customer.bairro,
      cep: customer.cep,
      cidade: customer.cidade,
      estado: customer.estado,
      fonefixo: customer.fonefixo,
      fonemovel: customer.fonemovel,
    };
  }

  voltaDadosPagador() {
    this.eventVoltar.emit(false);
  }

  async onSubmitPagamento() {

    this.submitted = true;

    if (this.formPagamento.invalid) {
      return;
    }

    this.isPreloader(true);

    let newPayment = new NewPaymentAsaasV2Model(
      this.asaasUnico,
      this.externalReference,
      {
        name: this.customer.nome,
        cpfCnpj: this.customer.cpf,
        email: this.customer.email,
        phone: this.customer.fonefixo ? this.customer.fonefixo : this.customer.fonemovel,
        mobilePhone: this.customer.fonemovel,
        addressNumber: this.customer.numero,
        postalCode: this.customer.cep,
        notificationDisabled: true,
        groupName: 'HotelCTC',
        externalReference: this.externalReference._id,
        complement: this.customer.complemento ? this.customer.complemento : '',
      },
      {
        installmentCount: this.installmentCount,
        // installmentValue: this.asaasUnico.valor / this.installmentCount,
        totalValue: this.asaasUnico.valor
      },
      {
        holderName: this.customer.nome,
        number: this.formPagamento.controls['number'].value,
        expiryMonth: this.formPagamento.controls['vencemes'].value,
        expiryYear: this.formPagamento.controls['venceano'].value,
        ccv: this.formPagamento.controls['ccv'].value
      },
      await this.ipAddress
    )

    await this.postPaymentV1Service
      .postPayment(newPayment)
      .subscribe(
        (resultado: ResultV1Model) => {
          if (resultado.success) {
            let retornoEvent: any = resultado.data;
            this.eventConcluir.emit(retornoEvent);
          } else {
            this.sweetModel(resultado);
            this.isPreloader(false);
          }
        }
      );
  }

  // modal de possíveis erros
  sweetModel(err: ResultV1Model) {
    Swal.fire({
      title: `${err.titulo}`,
      text: `${err.message}`,
      icon: 'warning',
      backdrop: `rgba(73,80,87,0.50)`
    });
  }

  telefone(rawNum: string) {
    rawNum = rawNum.replace(/[^a-zA-Z0-9]/g, '');
    if (rawNum.length == 10) {
      const areaCode = rawNum.slice(0, 2);
      const oneTre = rawNum.slice(2, 6);
      const toTre = rawNum.slice(6, 10);
      return `(${areaCode}) ${oneTre}-${toTre}`;
    } else {
      const areaCode = rawNum.slice(0, 2);
      const oneTre = rawNum.slice(2, 5);
      const toTre = rawNum.slice(5, 8);
      const treTre = rawNum.slice(8, 11);
      return `(${areaCode}) ${oneTre}-${toTre}-${treTre}`;
    }
  }

  telefoneCelular(rawNum: string, cel: boolean) {
    rawNum = rawNum.replace(/[^a-zA-Z0-9]/g, '');
    if (rawNum.length == 10) {
      if (cel) {
        const areaCode = rawNum.slice(0, 2);
        const oneTre = rawNum.slice(2, 6);
        const toTre = rawNum.slice(6, 10);
        return `${areaCode}9${oneTre}${toTre}`;
      } else {
        const areaCode = rawNum.slice(0, 2);
        const oneTre = rawNum.slice(2, 6);
        const toTre = rawNum.slice(6, 10);
        return `${areaCode}${oneTre}${toTre}`;
      }
    } else {
      const areaCode = rawNum.slice(0, 2);
      const oneTre = rawNum.slice(2, 5);
      const toTre = rawNum.slice(5, 8);
      const treTre = rawNum.slice(8, 11);
      return `${areaCode}${oneTre}${toTre}${treTre}`;
    }
  }

  onCountryChanged(event: any) {
    this.installmentCount = event.number;
    this.valorParcela = `${event.number}x de ${this.formatValue(event.total)}`;
  }

  /**
   * Referente ao percelamento do valor total da tarifa
   * em ate 6x dependendo do valor
   */
  calculaParcela(valor: number) {
    let quantParcela: number = 0;

    /** avaliando o valor e definindo o parcelamento possivel */
    /** maior ou igual a 1200 */
    valor >= 1200 ? quantParcela = 6 : null;

    /** maior ou igual a 1000 ou menor ou igual a 1199 */
    valor >= 1000 && valor <= 1199 ? quantParcela = 5 : null;

    /** maior ou igual a 800 ou menor ou igual a 999 */
    valor >= 800 && valor <= 999 ? quantParcela = 4 : null;

    /** maior ou igual a 600 ou menor ou igual a 799 */
    valor >= 600 && valor <= 799 ? quantParcela = 3 : null;

    /** menor ou igual a 400 ou menor ou igual a 599 */
    valor >= 400 && valor <= 599 ? quantParcela = 2 : null;

    /** menor ou igual a 399 */
    valor <= 399 ? quantParcela = 1 : null;

    return quantParcela;
  }

  formatValue(price: number) {
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(price);
  }

  isPreloader(status: boolean) {
    this.preloader = status;
    status
      ? /**
         * start preloader
         */
      $('.preloader').fadeIn('slow')
      : /**
         * stop preloader
         */
      ($.getScript('../assets/js/main.js'), $('.preloader').fadeOut('slow'));
  }
}
