import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-instalacoes-servicos',
  templateUrl: './instalacoes-servicos.component.html',
  styleUrls: ['./instalacoes-servicos.component.scss'],
})
export class InstalacoesServicosComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  pageTitleArea: pageTitle[] = [
    {
      title: 'Instalações',
      subTitle: 'Serviços',
    },
  ];
  caseStudiesDetailsDesc: DetailsDesc[] = [
    {
      title: 'R1 Jóias',
      paragraph:
        'Localizado na recepção do hotel, oferece semi jóias, bijuterias, relojoaria e presentes. Informações no local.',
      img: '',
    },
    // {
    //   title: 'Salão de Beleza',
    //   paragraph: 'Localizado na recepção do hotel, oferece diversos tratamentos além de escovas e unhas. Informações no local.',
    //   img: ''
    // },
    {
      title: 'Massagem',
      paragraph:
        'Localizado na recepção do hotel, oferece massagens em diversos aparelhos para seu conforto bem como a venda de equipamentos de saúde. Informações no local.',
      img: '',
    },
    {
      title: 'Doces',
      paragraph:
        'Localizado no parque aquático, oferece pipocas, foundie de chocolate com frutas e outras doces delícias. Informações no local.',
      img: '',
    },
    {
      title: 'Click Fotos',
      paragraph:
        'Localizado no parque aquático, fotógrafos profissionais prontos para registrar seus melhores momentos. Informações no local.',
      img: '',
    },
  ];
  caseStudiesDetailsDescImportantFacts: ImportantFacts[] = [
    {
      title: 'Piscinas',
    },
    {
      title: 'Saúna',
    },
    {
      title: 'Auditório',
    },
    {
      title: 'Ofurôs',
    },
    {
      title: 'Toboáguas',
    },
    {
      title: 'Home Office',
    },
  ];
  caseStudiesDetailsInfo: Info[] = [
    {
      icon: 'bx bx-user-pin',
      title: 'Reservas',
      subTitle: 'form reservas online',
    },
  ];
}
class pageTitle {
  title: string;
  subTitle: string;
}
class DetailsDesc {
  title: string;
  paragraph: string;
  img: string;
}
class ImportantFacts {
  title: string;
}
class Info {
  icon: string;
  title: string;
  subTitle: string;
}
