<section class="margin-top-area">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-2" *ngFor="let data of inDisponibilidade;">
        <div class="single-solutions-box dispTrue" [ngClass]="{'dispFalse': !data?.disponivel}">
          <h3 *ngIf="!data?.disponivel">{{ data?.data }}</h3>
          <h3 *ngIf="data?.disponivel">{{ data?.data }}</h3>
          <p *ngIf="!data?.disponivel">Disponível para reserva</p>
          <p *ngIf="data?.disponivel">Indisponível para reserva</p>
        </div>
      </div>
    </div>
  </div>
</section>
