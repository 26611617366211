import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-seguimentos',
  templateUrl: './seguimentos.component.html',
  styleUrls: ['./seguimentos.component.scss'],
})
export class SeguimentosComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  singleBoxesItem: BoxItem[] = [
    {
      icon: 'assets/img/seguimentos/metropolitan.png',
      paragraphText:
        'Conhecido como “Clube CTC”, faz parte do anexo do hotel e atende clientes que queiram passar o dia com qualidade e segurança. Para os hóspedes do Hotel CTC, o acesso ao Metropolitan Park é gratuito.',
      btnIcon: 'flaticon-right',
      btnText: 'Mais Detalhes',
      link: 'https://metropolitanpark.com.br',
      img: 'assets/img/seguimentos/shape-metropolitan.png',
    },
    {
      icon: 'assets/img/seguimentos/lg-ctctravel.png',
      paragraphText:
        'Feito para os fãs do CTC, nosso Clube de Férias conta com condições imperdíveis. Conheça agora o CTC Travel!',
      btnIcon: 'flaticon-right',
      btnText: 'Mais Detalhes',
      link: 'http://ctctravel.com.br',
      img: 'assets/img/seguimentos/shape-travel.png',
    },
    {
      icon: 'assets/img/seguimentos/lg-adtur.png',
      paragraphText:
        'Flats confortáveis para você que prefere algo mais reservado, a Adtur oferece flats no entorno do hotel, incluso serviço completo de hotelaria, acesso, acomodações confortáveis e acesso grátis ao Metropolitan Park.',
      btnIcon: 'flaticon-right',
      btnText: 'Mais Detalhes',
      link: '',
      img: 'assets/img/seguimentos/shape-adtur.png',
    },
  ];
}
class BoxItem {
  icon: string;
  paragraphText: string;
  btnIcon: string;
  btnText: string;
  link?: string;
  img: string;
}
