import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { GalleryPageComponent } from './components/pages/gallery-page/gallery-page.component';
import { EventsPageComponent } from './components/pages/events-page/events-page.component';
import { ErrorPageComponent } from './components/pages/error-page/error-page.component';
import { CaseStudiesThreeColumnsPageComponent } from './components/pages/case-studies-three-columns-page/case-studies-three-columns-page.component';
import { HomePageComponent } from './components/routes/home-page/home-page.component';
import { HomepageTwoComponent } from './components/pages/homepage-two/homepage-two.component';
import { HomepageThreeComponent } from './components/pages/homepage-three/homepage-three.component';
import { HomepageFourComponent } from './components/pages/homepage-four/homepage-four.component';
import { TeamPageComponent } from './components/pages/team-page/team-page.component';
import { ServicesPageComponent } from './components/pages/services-page/services-page.component';
import { ServicesDetailsPageComponent } from './components/pages/services-details-page/services-details-page.component';
import { ContactPageComponent } from './components/pages/contact-page/contact-page.component';
import { BlogPageComponent } from './components/pages/blog-page/blog-page.component';
import { BlogDetailsPageComponent } from './components/pages/blog-details-page/blog-details-page.component';
import { CaseStudiesDetailsPageComponent } from './components/pages/case-studies-details-page/case-studies-details-page.component';
import { FaqPageComponent } from './components/pages/faq-page/faq-page.component';
import { EventsDetailsPageComponent } from './components/pages/events-details-page/events-details-page.component';
import { CoursesPageComponent } from './components/pages/courses-page/courses-page.component';
import { CoursesDetailsPageComponent } from './components/pages/courses-details-page/courses-details-page.component';
import { ReservaOnlineComponent } from './reservas/reserva-online.component';
import { PagamentosComponent } from './pagamentos/pagamentos.component';
import { SobreHotelCtcComponent } from './components/routes/hotel-ctc/sobre-hotel-ctc/sobre-hotel-ctc.component';
import { InstalacoesAlimentacaoComponent } from './components/routes/instalacoes/instalacoes-alimentacao/instalacoes-alimentacao.component';
import { InstalacoesApartamentoComponent } from './components/routes/instalacoes/instalacoes-apartamento/instalacoes-apartamento.component';
import { InstalacoesLazerComponent } from './components/routes/instalacoes/instalacoes-lazer/instalacoes-lazer.component';
import { InstalacoesServicosComponent } from './components/routes/instalacoes/instalacoes-servicos/instalacoes-servicos.component';
import { SobreAguasQuentesDeCaldasNovasComponent } from './components/routes/hotel-ctc/sobre-aguas-quentes-de-caldas-novas/sobre-aguas-quentes-de-caldas-novas.component';
import { PoliticasDeHospedagemComponent } from './components/routes/hotel-ctc/politicas-de-hospedagem/politicas-de-hospedagem.component';
import { ContatoPageComponent } from './components/routes/hotel-ctc/contato-page/contato-page.component';
import { MetropolitanAtracoesComponent } from './components/routes/hotel-ctc/metropolitan-park/metropolitan-atracoes/metropolitan-atracoes.component';
import { MetropolitanAlimentacaoComponent } from './components/routes/hotel-ctc/metropolitan-park/metropolitan-alimentacao/metropolitan-alimentacao.component';
import { MetropolitanServicosComponent } from './components/routes/hotel-ctc/metropolitan-park/metropolitan-servicos/metropolitan-servicos.component';
import { PagamentoOnlineComponent } from './pagamento-online/pagamento-online.component';
import { ReservaConfirmadaComponent } from './pagamento-online/reserva-confirmada/reserva-confirmada.component';

const routes: Routes = [
  { path: '', component: HomePageComponent, pathMatch: 'full' },
  { path: 'reservas', component: ReservaOnlineComponent },
  { path: 'pagamentos', component: PagamentoOnlineComponent },
  { path: 'pagamento/:codigo', component: PagamentosComponent },
  { path: 'pagamento/:codigo/:grupo', component: PagamentosComponent },
  { path: 'pagamento', component: PagamentosComponent },

  { path: 'confirmado', component: ReservaConfirmadaComponent },

  { path: 'home-two', component: HomepageTwoComponent },
  { path: 'home-three', component: HomepageThreeComponent },
  { path: 'home-four', component: HomepageFourComponent },
  { path: 'team', component: TeamPageComponent },
  { path: 'services', component: ServicesPageComponent },
  { path: 'services-details', component: ServicesDetailsPageComponent },
  { path: 'gallery', component: GalleryPageComponent },
  { path: 'courses', component: CoursesPageComponent },
  { path: 'courses-details', component: CoursesDetailsPageComponent },
  { path: 'events', component: EventsPageComponent },
  { path: 'events-details', component: EventsDetailsPageComponent },

  //inicio::Hotel CTC
  { path: 'sobre-hotel-ctc', component: SobreHotelCtcComponent },
  {
    path: 'instalacoes-alimentacao',
    component: InstalacoesAlimentacaoComponent,
  },
  {
    path: 'instalacoes-apartamentos',
    component: InstalacoesApartamentoComponent,
  },
  { path: 'instalacoes-lazer', component: InstalacoesLazerComponent },
  { path: 'instalacoes-servicos', component: InstalacoesServicosComponent },
  {
    path: 'sobre-aguas-quentes-de-caldas-novas',
    component: SobreAguasQuentesDeCaldasNovasComponent,
  },
  {
    path: 'politicas-de-hospedagens',
    component: PoliticasDeHospedagemComponent,
  },
  { path: 'contato', component: ContatoPageComponent },
  { path: 'metropolitan-atracoes', component: MetropolitanAtracoesComponent },
  {
    path: 'metropolitan-alimentacao',
    component: MetropolitanAlimentacaoComponent,
  },
  {
    path: 'metropolitan-servicos',
    component: MetropolitanServicosComponent,
  },

  //fim::Hotel CTC
  {
    path: 'case-studies-3-columns',
    component: CaseStudiesThreeColumnsPageComponent,
  },
  { path: 'case-studies-details', component: CaseStudiesDetailsPageComponent },
  { path: 'error', component: ErrorPageComponent },
  { path: 'faq', component: FaqPageComponent },
  { path: 'blog', component: BlogPageComponent },
  { path: 'blog-details', component: BlogDetailsPageComponent },
  { path: 'contact', component: ContactPageComponent },

  // Here add new component

  { path: '**', component: ErrorPageComponent }, // This line will remain down from the whole component list
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
