import { Component, EventEmitter, Input, OnChanges, OnInit, Output, Renderer2 } from '@angular/core';
import { empty } from 'rxjs';
declare let $: any;
import { distinctUntilChanged, switchMap } from 'rxjs/operators';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ConsultaCepService } from '../service/consulta-cep.service';
import { DadosFormPaymentV1Model } from '../model/dados-form-payment-v1.model';
import { UtilsService } from 'src/app/share/utils.service';
import { ExternalReferenceV2Model } from '../model/external-referece-v2.model';

@Component({
  selector: 'app-dados-responsavel-pagamento',
  templateUrl: './dados-responsavel-pagamento.component.html',
  styleUrls: ['./dados-responsavel-pagamento.component.scss']
})
export class DadosResponsavelPagamentoComponent implements OnInit, OnChanges {

  /** Variaveis de saida */
  @Input() externalReference!: ExternalReferenceV2Model;
  @Input() isCustomer!: Boolean;
  @Output() eventDadosCustomers = new EventEmitter();

  /** variaveis de ambiente */
  preloader!: boolean;
  declaro: boolean = false;
  complemento!: string;
  isCep: boolean = false;

  /**
   * form pagamento
   */
  formPagador!: FormGroup;
  submitted = false;
  formErrors: any;
  ncidade!: number;
  alertDeclaro: boolean = true;
  dadosForm: any;

  constructor(
    private fb: FormBuilder,
    private renderer: Renderer2,
    private cepService: ConsultaCepService,
    private readonly utilsService: UtilsService,
  ) {
    /** Form PAGADOR */
    this.formPagador = this.fb.group(
      this.getFormPagador()
    );
  }

  // Irrelevante para a documentação
  get fpd() {
    return this.formPagador.controls
  }

  getFormPagador() {
    return {
      nome: ['',
        Validators.compose([
          Validators.required
        ])
      ],
      cpf: ['',
        Validators.compose([
          Validators.minLength(11),
          Validators.maxLength(11),
          Validators.required
        ])
      ],
      logradouro: ['',
        Validators.compose([
          Validators.required
        ])
      ],
      email: ['',
        Validators.compose([
          Validators.required,
          Validators.email
        ])
      ],
      complemento: [''],
      numero: [''],
      bairro: ['',
        Validators.compose([
          Validators.required
        ])
      ],
      cep: ['',
        Validators.compose([
          Validators.minLength(8),
          Validators.maxLength(8),
          Validators.required
        ])
      ],
      cidade: ['',
        Validators.compose([
          Validators.required
        ])
      ],
      estado: ['',
        Validators.compose([
          Validators.required
        ])
      ],
      fonefixo: ['',
        Validators.compose([
          Validators.minLength(10),
          Validators.maxLength(10),
        ])
      ],
      fonemovel: ['',
        Validators.compose([
          Validators.required,
          Validators.minLength(11),
          Validators.maxLength(11),
        ])
      ]
    }
  }

  ngOnInit(): void {
    this.formPagador.get('cep')?.statusChanges
      .pipe(
        distinctUntilChanged(),
        switchMap(status => status === 'VALID' ? (
          this.isPreloader(true),
          this.cepService.consultaCEP(
            this.formPagador.get('cep')?.value
          )
        ) : empty())
      )
      .subscribe((dados: any) => dados ?
        setTimeout(() => {
          if (dados.erro) {
            this.isCep = true;
            this.populaDadosNull();
          } else {
            this.isCep = false;
            this.populaDadosForm(dados);
          }
          this.isPreloader(false);
        }, dados) : {}
      );
  }

  ngOnChanges(): void {
    this.isCustomer ? this.prencheForm(this.utilsService.getDadosPayment()) : null;
  }

  prencheForm(customer: DadosFormPaymentV1Model) {
    this.isDeclaro();
    this.formPagador.patchValue({
      nome: customer.nome,
      cpf: customer.cpf,
      logradouro: customer.logradouro,
      email: customer.email,
      complemento: customer.complemento,
      numero: customer.numero,
      bairro: customer.bairro,
      cep: customer.cep,
      cidade: customer.cidade,
      estado: customer.estado,
      fonefixo: customer.fonefixo,
      fonemovel: customer.fonemovel,
    });

    this.formPagador.controls['logradouro'].disable();
    this.formPagador.controls['bairro'].disable();
    this.formPagador.controls['cidade'].disable();
    this.formPagador.controls['estado'].disable();
  }

  populaDadosNull(){
    this.formPagador.patchValue({
      logradouro: '',
      bairro: '',
      cidade: '',
      estado: ''
    });
    this.dadosForm = {
      logradouro: '',
      bairro: '',
      cidade: '',
      estado: ''
    }
    this.formPagador.controls['logradouro'].enable();
    this.formPagador.controls['bairro'].enable();
    this.formPagador.controls['cidade'].enable();
    this.formPagador.controls['estado'].enable();

    this.ncidade = 0;

    this.renderer.selectRootElement('#logradouro').focus();
  }

  populaDadosForm(dados: any) {
    this.formPagador.patchValue({
      logradouro: dados.logradouro,
      bairro: dados.bairro,
      cidade: dados.localidade,
      estado: dados.uf
    });
    this.dadosForm = {
      logradouro: dados.logradouro,
      bairro: dados.bairro,
      cidade: dados.localidade,
      estado: dados.uf
    }
    this.formPagador.controls['logradouro'].disable();
    this.formPagador.controls['bairro'].disable();
    this.formPagador.controls['cidade'].disable();
    this.formPagador.controls['estado'].disable();

    this.ncidade = dados.ibge;

    this.renderer.selectRootElement('#numero').focus();
  }

  isDeclaro() {
    this.declaro = true;
  }

  submitNotIdemPagador() {

    this.submitted = true;

    if (!this.isCustomer) {
      if (this.formPagador.invalid) {
        return;
      }
    }

    if (!this.declaro) {
      this.alertDeclaro = false;
      return;
    }

    let customer: DadosFormPaymentV1Model = {
      nome: this.formPagador.controls['nome'].value,
      cpf: this.formPagador.controls['cpf'].value,
      logradouro: this.formPagador.controls['logradouro'].value,
      email: this.formPagador.controls['email'].value,
      complemento: this.formPagador.controls['complemento'].value ? this.formPagador.controls['complemento'].value : '',
      numero: this.formPagador.controls['numero'].value ? this.formPagador.controls['numero'].value : 's/n',
      bairro: this.formPagador.controls['bairro'].value,
      cep: this.formPagador.controls['cep'].value,
      cidade: this.formPagador.controls['cidade'].value,
      estado: this.formPagador.controls['estado'].value,
      fonefixo: this.formPagador.controls['fonefixo'].value,
      fonemovel: this.formPagador.controls['fonemovel'].value,
      ncidade: this.ncidade
    }

    this.utilsService.setPayment(customer);

    let retornoEvent: any = {
      dadosCustomers: true
    }
    this.eventDadosCustomers.emit(retornoEvent);

  }

  telefoneCelular(rawNum: string, cel: boolean) {
    rawNum = rawNum.replace(/[^a-zA-Z0-9]/g, '');
    if (rawNum.length == 10) {
      if (cel) {
        const areaCode = rawNum.slice(0, 2);
        const oneTre = rawNum.slice(2, 6);
        const toTre = rawNum.slice(6, 10);
        return `${areaCode}9${oneTre}${toTre}`;
      } else {
        const areaCode = rawNum.slice(0, 2);
        const oneTre = rawNum.slice(2, 6);
        const toTre = rawNum.slice(6, 10);
        return `${areaCode}${oneTre}${toTre}`;
      }
    } else {
      const areaCode = rawNum.slice(0, 2);
      const oneTre = rawNum.slice(2, 5);
      const toTre = rawNum.slice(5, 8);
      const treTre = rawNum.slice(8, 11);
      return `${areaCode}${oneTre}${toTre}${treTre}`;
    }
  }

  clearCpfCnpj(doc: string) {
    return doc.replace(/[^a-zA-Z0-9]/g, '');
  }

  isPreloader(status: boolean) {
    this.preloader = status;
    status
      ? /**
         * start preloader
         */
      $('.preloader').fadeIn('slow')
      : /**
         * stop preloader
         */
      ($.getScript('../assets/js/main.js'), $('.preloader').fadeOut('slow'));
  }

}
