import { Component, OnInit } from '@angular/core';
import { DadosReservaV2Model } from 'src/app/reservas/models/dados-reserva-v2.model';
import { UtilsService } from 'src/app/share/utils.service';

@Component({
  selector: 'app-reserva-escolhida-payment',
  templateUrl: './reserva-escolhida-payment.component.html',
  styleUrls: ['../../reservas/disponibilidade/disponibilidade.component.scss']
})
export class ReservaEscolhidaPaymentComponent implements OnInit {

  /** variaveis de ambiente */
  reservaEscolhida: DadosReservaV2Model

  constructor(
    private readonly utilsService: UtilsService,
  ) { }

  ngOnInit(): void {
    this.reservaEscolhida = this.utilsService.getDadosReserva();
  }

  formatter(value: number): string {
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value);
  }
}
