<section class="gallery-area pt-20 pb-10">
  <div class="container">
    <div class="row">
      <ng-container *ngIf="!isTabGalery; else isTabGaleryComponent">
        <div class="col-lg-3 col-md-6 col-sm-6" *ngFor="let Image of galleryImages;">
          <div class="single-gallery-item">
            <a data-fancybox="gallery" href="{{ Image.img }}">
              <img [src]="Image.img" alt="image">
            </a>
          </div>
        </div>
      </ng-container>
      <ng-template #isTabGaleryComponent>
        <div class="col-lg-3 col-md-6 col-sm-6" *ngFor="let Image of tabGalleryImages;">
          <div class="single-gallery-item">
            <a data-fancybox="gallery" href="{{ Image.img }}">
              <img [src]="Image.img" alt="image">
            </a>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</section>
