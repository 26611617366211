<div class="main-banner">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-5 col-md-12">
        <div class="main-banner-content" *ngFor="let Content of mainBannerContent;">
          <h1 class="wow fadeInLeft" data-wow-delay="00ms" data-wow-duration="1000ms">{{Content.title}}</h1>
          <p class="wow fadeInLeft" data-wow-delay="100ms" data-wow-duration="1000ms">{{Content.paragraphText}}</p>
          <div class="btn-box">
            <!-- <a href="{{Content.defaultBtnLink}}" class="default-btn wow fadeInRight" data-wow-delay="200ms"
              data-wow-duration="1000ms"><i
                class="{{Content.defaultBtnIcon}}"></i>{{Content.defaultBtnText}}<span></span></a> -->
            <a href="{{Content.videoBtnLink}}" class="video-btn wow fadeInLeft popup-youtube" target="_blank"
              data-wow-delay="300ms" data-wow-duration="1000ms"><i class="{{Content.videoBtnIcon}}"></i>
              {{Content.videoBtnText}}</a>
          </div>
        </div>
      </div>

      <div class="col-lg-7 col-md-12">
        <div class="main-banner-animation-image">
          <img src="assets/img/home-banner/banner-one/img3.png" class="wow fadeInUp" data-wow-delay="200ms"
            data-wow-duration="1000ms" alt="image" data-speed="0.06" data-revert="true">
          <img src="assets/img/home-banner/banner-one/img8.png" class="wow fadeInDown" data-wow-delay="300ms"
            data-wow-duration="1000ms" alt="image" data-speed="0.06" data-revert="true">
          <img src="assets/img/home-banner/banner-one/img5.png" class="wow fadeInUp" data-wow-delay="400ms"
            data-wow-duration="1000ms" alt="image" data-speed="0.06" data-revert="true">
          <img src="assets/img/home-banner/banner-one/img7.png" class="wow fadeInDown" data-wow-delay="500ms"
            data-wow-duration="1000ms" alt="image" data-speed="0.06" data-revert="true">
          <img src="assets/img/home-banner/banner-one/img11.png" class="wow fadeInUp" data-wow-delay="600ms"
            data-wow-duration="1000ms" alt="image" data-speed="0.06" data-revert="true">
          <img src="assets/img/home-banner/banner-one/main-pic.png" alt="image">
        </div>
      </div>
    </div>
  </div>
</div>
