import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-mais-diversao',
  templateUrl: './home-mais-diversao.component.html',
  styleUrls: ['./home-mais-diversao.component.scss']
})
export class HomeMaisDiversaoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  // ourMissionImage: Image[] = [
  //     {
  //       img: 'assets/img/muita-diversao/toboagua.png'
  //     }
  // ]
  ourMissionContent: Content[] = [
    {
      subTitle: 'Metropolitan Park',
      title: 'Calor, áreas verdes e belas piscina para divertir-se.',
      paragraphText1: 'Sol, belas piscinas e áreas verdes: para você se deliciar no verão! As piscinas são higienizadas diariamente*. As águas são termais minerais, sem aquecimento artificial, oferecendo a melhor qualidade de balneabilidade da cidade. Da natureza direto pra você!',
      paragraphText2: '*Conforme programação.',
    }
  ]
  ourMissionList: List[] = [
    {
      icon: 'assets/img/mais-diversao/atracoes.png',
      title: 'Atrações',
      link: 'metropolitan-atracoes'
    },
    {
      icon: 'assets/img/mais-diversao/alimentacao.png',
      title: 'Alimentação',
      link: 'metropolitan-alimentacao'
    },
    {
      icon: 'assets/img/mais-diversao/servicos.png',
      title: 'Serviços',
      link: 'metropolitan-servicos'
    }
  ]

}
class Image {
  img: string;
}
class Content {
  subTitle: string;
  title: string;
  paragraphText1: string;
  paragraphText2: string;
}
class List {
  icon: string;
  title: string;
  link: string;
}
